import React, {
  // useRef, 
  useEffect, useState
} from 'react';
import Frame from "./frame";
import Navigation from './navigationNew';
import DrawerItem from './drawer';
import DrawerMenu from './assets/drawerMenu.svg';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core';
import Config from "../../config"
const {
  // innerWidth: WinWidth, 
  innerHeight: WinHeight } = window;

let LinearNavigator = (props) => {
  const { classes } = props;
  let { units, moduleData, slideWidth, slideHeight, currentScene,
    currentUnit, roadmaps, currentRoadmap, updatecurrentUnit, setcurrentScene, user_uid, user_token,
    drawerVariant, margin, typPC, availableModules,
    // firstTime,
    setCurrentRoadmap } = props;
  let [isLoaded, updateLoadStatus] = useState(false);
  let [isDrawerCalled, updateDrawerCalled] = useState(false);
  let [isFullScreen, toggleFullScreen] = useState(false);
  let slides = units && units[currentUnit] && units[currentUnit].activeContent
    ? units[currentUnit].activeContent
    : {};
  let percomp = "0";
  let [percomps, updatepercomps] = useState(0);
  useEffect(() => { updateLoadStatus(true) }, [isLoaded]);

  function layout({ w, h }) {
    return <React.Fragment>
      {slides.length
        ? <Frame {...props} slides={slides} currentScene={currentScene} currentUnit={currentUnit}
          slideWidth={w}
          slideHeight={h} isFullScreen={isFullScreen} typPC={typPC} />
        : <div style={{ width: '100%', height: '100%', backgroundColor: '#eaeaea' }} />}


      {typPC === "Prepare"
        ? availableModules.forEach(module => {
          if (moduleData._id === module.moduleId) {
            percomp = module.percentageCompleted;
          }
        })
        : availableModules.forEach(module => {
          if (moduleData._id === module.moduleId) {
            percomp = module.percentageCompletedConduct;
            //updatepercomps(percomp)
          }
        })
      }
      {typPC === 'Prepare' ?
        percomps > 0
          ? <LinearProgress variant="determinate" value={percomps}
            classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
          />
          : <LinearProgress variant="determinate" value={parseInt(percomp)}
            classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
          />
        : null}

      <Navigation setcurrentScene={setcurrentScene}
        currentScene={currentScene}
        lastScene={slides.length - 1}
        currentUnit={currentUnit}
        updatecurrentUnit={updatecurrentUnit}
        units={units}
        currentRoadmap={currentRoadmap}
        uid={user_uid}
        token={user_token}
        roadmaps={roadmaps}
        typPC={typPC} data={moduleData}
        percomps={percomps} updatepercomps={updatepercomps}
        toggleFullScreen={toggleFullScreen}
        isFullScreen={isFullScreen}
        setCurrentRoadmap={setCurrentRoadmap}
      />
    </React.Fragment>
  }

  return (
    <div id="dvouter" style={{ display: 'flex' }}>
      <DrawerItem isLoaded={isLoaded} data={moduleData}
        units={units}
        isDrawerCalled={isDrawerCalled}
        updateDrawerCalled={updateDrawerCalled}
        width={slideWidth}
        roadmaps={roadmaps}
        currentUnit={currentUnit}
        currentRoadmap={currentRoadmap}
        updatecurrentUnit={updatecurrentUnit}
        setcurrentScene={setcurrentScene}
        uid={user_uid}
        token={user_token}
        drawerVariant={drawerVariant}
        typPC={typPC}
        availableModules={availableModules}
        percomps={percomps}
        setCurrentRoadmap={setCurrentRoadmap}
      />
      <div id='linear-navigator' style={Config.ACP_SHOW && window.location.host === Config.ACP_HOST ? { width: '100%' } : {
        width: slideWidth, height: '100%', position: 'relative',
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: margin
        // , Config.ACP_SHOW && window.location.host == Config.ACP_HOST ? { width: '100%' } : {}
      }}>
        {drawerVariant === "temporary" ?
          <div
            onClick={() => { updateDrawerCalled(!isDrawerCalled) }}
            style={{
              position: 'absolute', width: 30, height: 30, left: -40, top: 10, backgroundColor: '#8c8c8c',
              display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 4
            }}>
            <div style={{ position: 'absolute', top: '-20px', fontSize: '13px' }}>Menu</div>
            <img src={DrawerMenu} />
          </div> : null
        }

        {layout({ w: slideWidth, h: slideHeight })}

      </div>
      <Modal
        classes={{ root: 'fullscreen' }}
        open={isFullScreen}
        onClose={() => { toggleFullScreen(false) }}
        allowFullScreen
        aria-labelledby="fullscreen-title"
        aria-describedby="fullscreen-description"
      ><div>
          {Config.ACP_SHOW && window.location.host === Config.ACP_HOST ?
            layout({ h: WinHeight - 50, w: (WinHeight - 50) * 4 / 3 }) :
            layout({ h: WinHeight - 60, w: (WinHeight - 30) * 16 / 9 })}
          {/* layout({ h: WinHeight - 60, w: (WinHeight - 30) * 16 / 6.85 })} */}
          {/* <Frame {...props} slides={slides} currentScene={currentScene} slideHeight={WinHeight} slideWidth={WinHeight * 16 / 8.4} /> */}
        </div>
      </Modal>
    </div >
  )
}
const styles = props => ({
  colorPrimary: {
    backgroundColor: '#CCCC00',
  },
  barColorPrimary: {
    backgroundColor: '#c6922a',
  }
});
export default withStyles(styles)(LinearNavigator);