export const modules = {
    "348f219d-65b4-43f7-8c44-4247e3b5ba5c": {
        "5": {
            moduleId: "60c2ece6e25d4da322897631",
            progress: "50"
        },
        "6": {
            moduleId: "60c2ece6e25d4da322897631",
            progress: "50"
        },
        "7": {
            moduleId: "60e56efdf356ae3c070582e2",
            progress: "50"
        },
        "8": {
            moduleId: "60e56efdf356ae3c070582e2",
            progress: "50"
        },
        "9": {
            moduleId: "60e56fe8f356ae3c070582ea",
            progress: "50"
        },
        "10": {
            moduleId: "60e56fe8f356ae3c070582ea",
            progress: "50"
        },
        "11": {
            moduleId: "60e57019f356ae3c070582f0",
            progress: "50"
        },
        "12": {
            moduleId: "60e57019f356ae3c070582f0",
            progress: "50"
        },
        "13": {
            moduleId: "60e57057f356ae3c070582f5",
            progress: "50"
        },
        "14": {
            moduleId: "60e57057f356ae3c070582f5",
            progress: "50"
        },
        "15": {
            moduleId: "60e57082f356ae3c070582fb",
            progress: "50"
        },
        "16": {
            moduleId: "60e57082f356ae3c070582fb",
            progress: "50"
        },
        "17": {
            moduleId: "60e570b3f356ae3c07058301",
            progress: "50"
        },
        "18": {
            moduleId: "60e570b3f356ae3c07058301",
            progress: "50"
        },
        "19": {
            moduleId: "60e570edf356ae3c07058306",
            progress: "50"
        },
        "20": {
            moduleId: "60e570edf356ae3c07058306",
            progress: "50"
        },
        "22": {
            moduleId: "60e5711bf356ae3c0705830c",
            progress: "100"
        },
        "23": {
            moduleId: "60e5715ff356ae3c07058315",
            progress: "50"
        },
        "24": {
            moduleId: "60e5715ff356ae3c07058315",
            progress: "50"
        },
        "25": {
            moduleId: "60e571aaf356ae3c0705831c",
            progress: "50"
        },
        "26": {
            moduleId: "60e571aaf356ae3c0705831c",
            progress: "50"
        },
        "27": {
            moduleId: "60e571f2f356ae3c07058324",
            progress: "50"
        },
        "28": {
            moduleId: "60e571f2f356ae3c07058324",
            progress: "50"
        },
        "29": {
            moduleId: "60e57253f356ae3c0705832d",
            progress: "50"
        },
        "30": {
            moduleId: "60e57253f356ae3c0705832d",
            progress: "50"
        },
        "31": {
            moduleId: "60e57297f356ae3c07058333",
            progress: "50"
        },
        "32": {
            moduleId: "60e57297f356ae3c07058333",
            progress: "50"
        },
        "33": {
            moduleId: "60e572daf356ae3c0705833a",
            progress: "50"
        },
        "34": {
            moduleId: "60e572daf356ae3c0705833a",
            progress: "50"
        },
        "35": {
            moduleId: "60e5731ef356ae3c07058346",
            progress: "50"
        },
        "36": {
            moduleId: "60e5731ef356ae3c07058346",
            progress: "50"
        },
    },
    "8e5c26a8-ed5e-438d-8257-c5214af15b98": {
        "5": {
            moduleId: "60c2ece6e25d4da322897631",
            progress: "50"
        },
        "6": {
            moduleId: "60c2ece6e25d4da322897631",
            progress: "50"
        },
        "7": {
            moduleId: "60e56efdf356ae3c070582e2",
            progress: "50"
        },
        "8": {
            moduleId: "60e56efdf356ae3c070582e2",
            progress: "50"
        },
        "9": {
            moduleId: "60e56fe8f356ae3c070582ea",
            progress: "50"
        },
        "10": {
            moduleId: "60e56fe8f356ae3c070582ea",
            progress: "50"
        },
        "11": {
            moduleId: "60e57019f356ae3c070582f0",
            progress: "50"
        },
        "12": {
            moduleId: "60e57019f356ae3c070582f0",
            progress: "50"
        },
        "13": {
            moduleId: "60e57057f356ae3c070582f5",
            progress: "50"
        },
        "14": {
            moduleId: "60e57057f356ae3c070582f5",
            progress: "50"
        },
        "15": {
            moduleId: "60e57082f356ae3c070582fb",
            progress: "50"
        },
        "16": {
            moduleId: "60e57082f356ae3c070582fb",
            progress: "50"
        },
        "17": {
            moduleId: "60e570b3f356ae3c07058301",
            progress: "50"
        },
        "18": {
            moduleId: "60e570b3f356ae3c07058301",
            progress: "50"
        },
        "19": {
            moduleId: "60e570edf356ae3c07058306",
            progress: "50"
        },
        "20": {
            moduleId: "60e570edf356ae3c07058306",
            progress: "50"
        },
        "22": {
            moduleId: "60e5711bf356ae3c0705830c",
            progress: "100"
        },
        "23": {
            moduleId: "60e5715ff356ae3c07058315",
            progress: "50"
        },
        "24": {
            moduleId: "60e5715ff356ae3c07058315",
            progress: "50"
        },
        "25": {
            moduleId: "60e571aaf356ae3c0705831c",
            progress: "50"
        },
        "26": {
            moduleId: "60e571aaf356ae3c0705831c",
            progress: "50"
        },
        "27": {
            moduleId: "60e571f2f356ae3c07058324",
            progress: "50"
        },
        "28": {
            moduleId: "60e571f2f356ae3c07058324",
            progress: "50"
        },
        "29": {
            moduleId: "60e57253f356ae3c0705832d",
            progress: "50"
        },
        "30": {
            moduleId: "60e57253f356ae3c0705832d",
            progress: "50"
        },
        "31": {
            moduleId: "60e57297f356ae3c07058333",
            progress: "50"
        },
        "32": {
            moduleId: "60e57297f356ae3c07058333",
            progress: "50"
        },
        "33": {
            moduleId: "60e572daf356ae3c0705833a",
            progress: "50"
        },
        "34": {
            moduleId: "60e572daf356ae3c0705833a",
            progress: "50"
        },
        "35": {
            moduleId: "60e5731ef356ae3c07058346",
            progress: "50"
        },
        "36": {
            moduleId: "60e5731ef356ae3c07058346",
            progress: "50"
        },
    },
    "7e3fa472-64a7-4e76-9b5e-14b5203591f0": {
        "5": {
            moduleId: "60c2ed00e25d4da3228976a2",
            progress: "50"
        },
        "6": {
            moduleId: "60c2ed00e25d4da3228976a2",
            progress: "50"
        },
        "7": {
            moduleId: "60e57395f356ae3c07058354",
            progress: "50"
        },
        "8": {
            moduleId: "60e57395f356ae3c07058354",
            progress: "50"
        },
        "9": {
            moduleId: "60e573c0f356ae3c07058359",
            progress: "50"
        },
        "10": {
            moduleId: "60e573c0f356ae3c07058359",
            progress: "50"
        },
        "11": {
            moduleId: "60e573dff356ae3c0705835e",
            progress: "50"
        },
        "12": {
            moduleId: "60e573dff356ae3c0705835e",
            progress: "50"
        },
        "13": {
            moduleId: "60e5741ef356ae3c07058363",
            progress: "50"
        },
        "14": {
            moduleId: "60e5741ef356ae3c07058363",
            progress: "50"
        },
        "15": {
            moduleId: "60e5744ef356ae3c0705836c",
            progress: "50"
        },
        "16": {
            moduleId: "60e5744ef356ae3c0705836c",
            progress: "50"
        },
        "17": {
            moduleId: "60e576c5f356ae3c07058371",
            progress: "50"
        },
        "18": {
            moduleId: "60e576c5f356ae3c07058371",
            progress: "50"
        },
        "19": {
            moduleId: "60e5770bf356ae3c07058376",
            progress: "50"
        },
        "20": {
            moduleId: "60e5770bf356ae3c07058376",
            progress: "50"
        },
        "21": {
            moduleId: "60e57750f356ae3c0705837c",
            progress: "50"
        },
        "22": {
            moduleId: "60e57750f356ae3c0705837c",
            progress: "100"
        },
        "23": {
            moduleId: "60e57797f356ae3c07058381",
            progress: "50"
        },
        "24": {
            moduleId: "60e57797f356ae3c07058381",
            progress: "50"
        },
        "25": {
            moduleId: "60e577c8f356ae3c07058386",
            progress: "50"
        },
        "26": {
            moduleId: "60e577c8f356ae3c07058386",
            progress: "50"
        },
        "27": {
            moduleId: "60e577e7f356ae3c0705838c",
            progress: "50"
        },
        "28": {
            moduleId: "60e577e7f356ae3c0705838c",
            progress: "50"
        },
        "29": {
            moduleId: "60e57806f356ae3c07058392",
            progress: "50"
        },
        "30": {
            moduleId: "60e57806f356ae3c07058392",
            progress: "50"
        },
        "31": {
            moduleId: "60e57824f356ae3c0705839b",
            progress: "50"
        },
        "32": {
            moduleId: "60e57824f356ae3c0705839b",
            progress: "50"
        },
        "33": {
            moduleId: "60e57846f356ae3c070583a0",
            progress: "50"
        },
        "34": {
            moduleId: "60e57846f356ae3c070583a0",
            progress: "50"
        },
        "35": {
            moduleId: "60e57871f356ae3c070583a9",
            progress: "50"
        },
        "36": {
            moduleId: "60e57871f356ae3c070583a9",
            progress: "50"
        },
    },
    "58cf0a35-e80d-43d6-801d-1485b9d40199": {
        "5": {
            moduleId: "60c2ed00e25d4da3228976a2",
            progress: "50"
        },
        "6": {
            moduleId: "60c2ed00e25d4da3228976a2",
            progress: "50"
        },
        "7": {
            moduleId: "60e57395f356ae3c07058354",
            progress: "50"
        },
        "8": {
            moduleId: "60e57395f356ae3c07058354",
            progress: "50"
        },
        "9": {
            moduleId: "60e573c0f356ae3c07058359",
            progress: "50"
        },
        "10": {
            moduleId: "60e573c0f356ae3c07058359",
            progress: "50"
        },
        "11": {
            moduleId: "60e573dff356ae3c0705835e",
            progress: "50"
        },
        "12": {
            moduleId: "60e573dff356ae3c0705835e",
            progress: "50"
        },
        "13": {
            moduleId: "60e5741ef356ae3c07058363",
            progress: "50"
        },
        "14": {
            moduleId: "60e5741ef356ae3c07058363",
            progress: "50"
        },
        "15": {
            moduleId: "60e5744ef356ae3c0705836c",
            progress: "50"
        },
        "16": {
            moduleId: "60e5744ef356ae3c0705836c",
            progress: "50"
        },
        "17": {
            moduleId: "60e576c5f356ae3c07058371",
            progress: "50"
        },
        "18": {
            moduleId: "60e576c5f356ae3c07058371",
            progress: "50"
        },
        "19": {
            moduleId: "60e5770bf356ae3c07058376",
            progress: "50"
        },
        "20": {
            moduleId: "60e5770bf356ae3c07058376",
            progress: "50"
        },
        "21": {
            moduleId: "60e57750f356ae3c0705837c",
            progress: "50"
        },
        "22": {
            moduleId: "60e57750f356ae3c0705837c",
            progress: "100"
        },
        "23": {
            moduleId: "60e57797f356ae3c07058381",
            progress: "50"
        },
        "24": {
            moduleId: "60e57797f356ae3c07058381",
            progress: "50"
        },
        "25": {
            moduleId: "60e577c8f356ae3c07058386",
            progress: "50"
        },
        "26": {
            moduleId: "60e577c8f356ae3c07058386",
            progress: "50"
        },
        "27": {
            moduleId: "60e577e7f356ae3c0705838c",
            progress: "50"
        },
        "28": {
            moduleId: "60e577e7f356ae3c0705838c",
            progress: "50"
        },
        "29": {
            moduleId: "60e57806f356ae3c07058392",
            progress: "50"
        },
        "30": {
            moduleId: "60e57806f356ae3c07058392",
            progress: "50"
        },
        "31": {
            moduleId: "60e57824f356ae3c0705839b",
            progress: "50"
        },
        "32": {
            moduleId: "60e57824f356ae3c0705839b",
            progress: "50"
        },
        "33": {
            moduleId: "60e57846f356ae3c070583a0",
            progress: "50"
        },
        "34": {
            moduleId: "60e57846f356ae3c070583a0",
            progress: "50"
        },
        "35": {
            moduleId: "60e57871f356ae3c070583a9",
            progress: "50"
        },
        "36": {
            moduleId: "60e57871f356ae3c070583a9",
            progress: "50"
        },
    },
    "6fc6ef5c-2baf-4cbf-b870-0f80c99e56a4": {
        "5": {
            moduleId: "60c2ed18e25d4da322897705",
            progress: "50"
        },
        "6": {
            moduleId: "60c2ed18e25d4da322897705",
            progress: "50"
        },
        "7": {
            moduleId: "60e578a6f356ae3c070583af",
            progress: "50"
        },
        "8": {
            moduleId: "60e578a6f356ae3c070583af",
            progress: "50"
        },
        "9": {
            moduleId: "60e578cef356ae3c070583b6",
            progress: "50"
        },
        "10": {
            moduleId: "60e578cef356ae3c070583b6",
            progress: "50"
        },
        "11": {
            moduleId: "60e578e9f356ae3c070583bd",
            progress: "50"
        },
        "12": {
            moduleId: "60e578e9f356ae3c070583bd",
            progress: "50"
        },
        "13": {
            moduleId: "60e57905f356ae3c070583c4",
            progress: "50"
        },
        "14": {
            moduleId: "60e57905f356ae3c070583c4",
            progress: "50"
        },
        "15": {
            moduleId: "60e5791ef356ae3c070583ca",
            progress: "50"
        },
        "16": {
            moduleId: "60e5791ef356ae3c070583ca",
            progress: "50"
        },
        "17": {
            moduleId: "60e57935f356ae3c070583d1",
            progress: "50"
        },
        "18": {
            moduleId: "60e57935f356ae3c070583d1",
            progress: "50"
        },
        "19": {
            moduleId: "60e57ed0f356ae3c070583d8",
            progress: "50"
        },
        "20": {
            moduleId: "60e57ed0f356ae3c070583d8",
            progress: "50"
        },
        "22": {
            moduleId: "60e57f30f356ae3c070583e1",
            progress: "100"
        },
        "23": {
            moduleId: "60e57f46f356ae3c070583e4",
            progress: "50"
        },
        "24": {
            moduleId: "60e57f46f356ae3c070583e4",
            progress: "50"
        },
        "25": {
            moduleId: "60e57f62f356ae3c070583ea",
            progress: "50"
        },
        "26": {
            moduleId: "60e57f62f356ae3c070583ea",
            progress: "50"
        },
        "27": {
            moduleId: "60e57f7af356ae3c070583f2",
            progress: "50"
        },
        "28": {
            moduleId: "60e57f7af356ae3c070583f2",
            progress: "50"
        },
        "29": {
            moduleId: "60e57f92f356ae3c070583fa",
            progress: "50"
        },
        "30": {
            moduleId: "60e57f92f356ae3c070583fa",
            progress: "50"
        },
        "31": {
            moduleId: "60e57facf356ae3c070583ff",
            progress: "50"
        },
        "32": {
            moduleId: "60e57facf356ae3c070583ff",
            progress: "50"
        },
        "33": {
            moduleId: "60e57fcbf356ae3c07058408",
            progress: "50"
        },
        "34": {
            moduleId: "60e57fcbf356ae3c07058408",
            progress: "50"
        },
        "35": {
            moduleId: "60e57fe8f356ae3c0705840e",
            progress: "50"
        },
        "36": {
            moduleId: "60e57fe8f356ae3c0705840e",
            progress: "50"
        },
    },
    "5bd1028b-ffdc-4478-94c7-8970bf7d6dce": {
        "5": {
            moduleId: "60c2ed18e25d4da322897705",
            progress: "50"
        },
        "6": {
            moduleId: "60c2ed18e25d4da322897705",
            progress: "50"
        },
        "7": {
            moduleId: "60e578a6f356ae3c070583af",
            progress: "50"
        },
        "8": {
            moduleId: "60e578a6f356ae3c070583af",
            progress: "50"
        },
        "9": {
            moduleId: "60e578cef356ae3c070583b6",
            progress: "50"
        },
        "10": {
            moduleId: "60e578cef356ae3c070583b6",
            progress: "50"
        },
        "11": {
            moduleId: "60e578e9f356ae3c070583bd",
            progress: "50"
        },
        "12": {
            moduleId: "60e578e9f356ae3c070583bd",
            progress: "50"
        },
        "13": {
            moduleId: "60e57905f356ae3c070583c4",
            progress: "50"
        },
        "14": {
            moduleId: "60e57905f356ae3c070583c4",
            progress: "50"
        },
        "15": {
            moduleId: "60e5791ef356ae3c070583ca",
            progress: "50"
        },
        "16": {
            moduleId: "60e5791ef356ae3c070583ca",
            progress: "50"
        },
        "17": {
            moduleId: "60e57935f356ae3c070583d1",
            progress: "50"
        },
        "18": {
            moduleId: "60e57935f356ae3c070583d1",
            progress: "50"
        },
        "19": {
            moduleId: "60e57ed0f356ae3c070583d8",
            progress: "50"
        },
        "20": {
            moduleId: "60e57ed0f356ae3c070583d8",
            progress: "50"
        },
        "22": {
            moduleId: "60e57f30f356ae3c070583e1",
            progress: "100"
        },
        "23": {
            moduleId: "60e57f46f356ae3c070583e4",
            progress: "50"
        },
        "24": {
            moduleId: "60e57f46f356ae3c070583e4",
            progress: "50"
        },
        "25": {
            moduleId: "60e57f62f356ae3c070583ea",
            progress: "50"
        },
        "26": {
            moduleId: "60e57f62f356ae3c070583ea",
            progress: "50"
        },
        "27": {
            moduleId: "60e57f7af356ae3c070583f2",
            progress: "50"
        },
        "28": {
            moduleId: "60e57f7af356ae3c070583f2",
            progress: "50"
        },
        "29": {
            moduleId: "60e57f92f356ae3c070583fa",
            progress: "50"
        },
        "30": {
            moduleId: "60e57f92f356ae3c070583fa",
            progress: "50"
        },
        "31": {
            moduleId: "60e57facf356ae3c070583ff",
            progress: "50"
        },
        "32": {
            moduleId: "60e57facf356ae3c070583ff",
            progress: "50"
        },
        "33": {
            moduleId: "60e57fcbf356ae3c07058408",
            progress: "50"
        },
        "34": {
            moduleId: "60e57fcbf356ae3c07058408",
            progress: "50"
        },
        "35": {
            moduleId: "60e57fe8f356ae3c0705840e",
            progress: "50"
        },
        "36": {
            moduleId: "60e57fe8f356ae3c0705840e",
            progress: "50"
        },
    },
}