import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./channelDashboard";
import Prepare from "./channelPrepare";
import Conduct from "./conduct";
import Explore from "./channelExplore";
import ConductHome from "./conductDashboard";
import ExploreHome from "./exploreDashboard";
import SelfCertify from "./selfCertify";
import Report from "./reportDashboard";
import AddModule from "./addModule";
import Projects from "./projectDashBoard";
import UploadProject from "../../components/UploadProject";
import MultiPagePDFReader from "../../components/MultiPagePDFReader";
import OtpScreen from '../../components/App_ConductDashboard/OtpScreen';
import CoachingAnalyticsDashboard from './CoachingAnalyticsDashboard';

let MainRoute = class MainRoute extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/application/Home" component={Home} />
                <Route exact path="/application/prepare/:moduleId" component={Prepare} />
                <Route exact path="/application/conduct/:moduleId" component={Conduct} />
                <Route exact path="/application/conduct" component={ConductHome} />
                <Route exact path="/application/explore/:moduleId" component={Explore} />
                <Route exact path="/application/explore" component={ExploreHome} />
                <Route exact path="/application/selfcertify/:moduleId" component={SelfCertify} />
                <Route exact path="/application/prepare/" component={Home} />
                <Route exact path="/application/Report" component={Report} />
                <Route exact path="/application/OtpScreen" component={OtpScreen} />
                {/* <Route exact path="/application/Dashboard" component={AnalyticsDashboard} /> */}
                <Route exact path="/application/AddModule" component={AddModule} />
                <Route exact path="/application/Projects" component={Projects} />
                <Route exact path="/application/UploadProject" component={UploadProject} />
                <Route exact path="/application/ViewProject" component={MultiPagePDFReader} />
                <Route exact path="/application/coaching-analytics" component={CoachingAnalyticsDashboard} />
                <Route path="/application/" component={ExploreHome} />
            </Switch>
        );
    }
};

export default MainRoute;