import React from "react";
import { Switch, Route } from "react-router-dom";

//ACP Links
// import APHome from "./ModuleDashboard"
// import ACPStoreDashboard from "./store";
// import ACPProduct from "./productPage";
// import ACPPaymentResponse from "./payments";
// import ChannelDashboard from "./channelDashboard";
// import Unit from "./unit";
// import ReportPDF from '../../Live_Session_Tools/PDF';
// import Conduct from "../application/conduct"
// import Prepare from "../application/channelPrepare";
// import "./acp.css"
// import ACPHome from '../acp/applicationDashboard'
import Logout from "../auth/Logout";
import Register from "./Register";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
// import ResetPassword from "../auth/ResetPassword";
import Login from './Login'
import { useSelector } from "react-redux";
export default ({ }) => {
    const app = useSelector(state => state.app)
    const auth = useSelector(state => state.auth)
    return <Switch>
        <Route path="/auth/login" render={() => (
            <Login app={app} auth={auth} />
        )} />
        <Route path="/auth/logout" component={Logout} />
        <Route path="/auth/register" render={() => (
            <Register app={app} auth={auth} />
        )} />
        <Route path="/auth/forgotpassword" component={ForgotPassword} />
        <Route path="/auth/resetpassword" component={ResetPassword} />
    </Switch>
}