import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import {
  // Input,
  // InputLabel,
  // FormControl,
  // Button,
  // Typography,
  // CircularProgress,TextField
// } from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import { Redirect } from "react-router";

import ResetPasswordForm from "../../components/ResetPassword";

import { resetPassword, resetForgotPasswordPage,resetPasswordUpdate } from "./redux_actions";
// import logo from "./assets/logo.svg";
// import backArrow from "./assets/BackArrow.svg";
// import succes from "./assets/Success.svg";

import "./assets/ForgotPassword.css";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  margin: {
    margin: theme.spacing.unit
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3
  },
  button: {
    margin: theme.spacing.unit
  },
  textField: {},
  // button: {
  //   margin: theme.spacing.unit
  // },
  input: {
    display: "none"
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

let ResetPassword = class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm:"",
      firstPaint: true,
      label: "",
    };

    this.toLogin = props => <Link to="../auth/login" {...props} />;

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };
  }

    // handleValidation(eml){
    //   this.setState({label: ""});
    //   this.setState({email: eml});
    //   let errors = {};
    //   let formIsValid = true;

    //   //Email
    //   if(!eml){
    //     formIsValid = false;
    //     errors["user-email"] = "Email cannot be empty";
    //     this.setState({label: "Email can not be empty"});
    // }
  
    //   if(typeof eml !== "undefined"){
    //     let lastAtPos = eml.lastIndexOf('@');
    //     let lastDotPos = eml.lastIndexOf('.');
    
    //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && eml.indexOf('@@') == -1 && lastDotPos > 2 && (eml.length - lastDotPos) > 2)) {
    //         formIsValid = false;
    //         errors["user-email"] = "Email is not valid";
    //         this.setState({label: "Email can not be empty"})
    //       }
    // }  
    // this.setState({errors: errors});
    // return formIsValid;
    // }
  
  componentWillMount() {
    let { firstPaint } = this.state;
    if (firstPaint) {
      this.props.resetForgotPasswordPage();
      this.setState({ firstPaint: false });
    }
  }

  render() {
    let { classes } = this.props;
    // let { auth, classes } = this.props;
    // let { resetPasswordInProgress, passwordLinkSent } = auth;

      return (
        <div className={classes.root+" login-cntr h-100"}>
          <div className="login-artifact" />
          <ResetPasswordForm
            loginAttempt={this.props.loginAttempt}
            resetPasswordUpdate={this.props.resetPasswordUpdate}
            authMsg={this.props.auth.loginErrorMsg}
          />
        </div>
      );
  }
};

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  resetPassword: PropTypes.func,
  resetForgotPasswordPage: PropTypes.func,
  resetPasswordUpdate: PropTypes.func,
};

ResetPassword = withStyles(styles)(ResetPassword);

const mapStateToProps = state => ({
  auth: state.auth
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
      resetForgotPasswordPage,
      resetPasswordUpdate,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ResetPassword);
