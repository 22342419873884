import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Close_Button from "../../components/images/Tools-img/Close_Button.png";
import VideoSlide from "../../components/VideoSlide";

import './style.css'
export default function TransitionsModal({ icon, src, poster, engagementData, manageToolData, textboxId, width, height, type, Component, data, isFullScreen, modalClassName }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div style={{ width: '100%', height: '100%' }}>

            <img src={icon} className='button-click-effect' style={{ width: '100%', height: '100%' }} onClick={() => handleOpen()} alt='icon' />

            <Modal
                className='modal'
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div style={{ border: 'none', outline: 'none', position: 'relative' }}>
                        {
                            type === 'video' && <VideoSlide vidUrl={src} imgUrl={poster || 'https://cache.redgiant.com/wp-assets/2019/03/play-icon1.png'} dimensions={{ slideHeight: '100%', slideWidth: '100%' }} popupWidth={width} popupHeight={height} />
                        }
                        {
                            type === 'img' && <img src={src} style={{ width, height }} alt='popUp' />
                        }
                        {
                            (type === 'ppt' || type === 'pdf') && <iframe src={src} style={{ width, height }} />
                        }
                        {
                            type === 'textbox' &&
                            <div style={{ width, height, background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <textarea id={textboxId} value={engagementData[textboxId]}
                                    className={`ISE_Growing_Deep_textBox roboto tct-scrollable`}
                                    style={{ width: '200px', height: "200px", border: '2px solid black' }}
                                    onChange={(event) => manageToolData({ toolId: textboxId, content: event.target.value })}
                                    placeholder='Fill here...' />
                            </div>
                        }
                        {
                            type === 'other' && <div style={{ width: width ? width : '100%', height: height ? height : '100%' }}>{src}</div>
                        }
                        {
                            type === 'tool-component' && Component
                        }
                        {
                            type === 'text-ul' && <div className={modalClassName} style={{ width: width ? width : '100%', height: height ? height : '100%' }}>
                                {src.map(text => <div key={text}> &#9672; {text} </div>)}
                            </div>
                        }
                        <img src={Close_Button} className="close-button-2" onClick={handleClose} />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}