import React from 'react';
import { useTransition, animated } from 'react-spring';
import ImageSlide from '../ImageSlide';
import OtpScreen from '../App_ConductDashboard/OtpScreen';
import VideoSlide from '../VideoSlide';

import LearningTool from "../../LearningTools/index";
import { Tools } from "../../LearningTools/index";

import LiveSession from "../../Live_Session/index";
import { LiveSessions } from "../../Live_Session/index";

import { connect } from "react-redux";
import { manageToolData } from "../../containers/application/redux_actions";
// import Config from '../../config';

let LinearNavigatorFrame = ({ slides, slideWidth, slideHeight, currentScene, currentUnit, typPC, isFullScreen, app, auth, manageToolData }) => {
  const transitions = useTransition(slides[currentScene], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });

  let populateItem = (item) => {
    if (item.type === "otp" && typPC !== 'Prepare')
      return <OtpScreen dimensions={{ slideHeight, slideWidth }} title={item.title} description={item.description} testType={item.testType} oneTime={true} />
    // if(item.type === "otp" && typPC === 'Prepare') continue;
    if (item.type === "img") {
      return <ImageSlide imgUrl={item.url} dimensions={{ slideHeight, slideWidth }} />
    }
    else if (item.type === "ppt") {
      return <ImageSlide imgUrl={app.isHindi ? item.url.HINDI : item.url.ENGLISH} dimensions={{ slideHeight, slideWidth }} />
    }
    else if (item.type === "video")
      return <VideoSlide vidUrl={item.url} dimensions={{ slideHeight, slideWidth }} imgUrl={item.imgUrl} />
    else if (item.type === "tool") {
      if (Tools[item.toolName])
        return <LearningTool width={slideWidth} height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          isFullScreen={isFullScreen}
          currentUnit={currentUnit}
          app={app}
          auth={auth}
        />
      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Tool is under work &#128736;</h1>
      </div>
    }
    else if (item.type === "live-session") {
      if (LiveSessions[item.sessionType])
        return <LiveSession width={slideWidth} height={slideHeight}
          engagementData={app.engagementData}
          manageToolData={manageToolData}
          data={item}
          currentUnit={currentUnit}
          isFullScreen={isFullScreen}
        />
      return <div style={{ width: slideWidth, height: slideHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Tool is under work &#128736;</h1>
      </div>
    }
    // else if (item.type === "interactive-image-questions")
    //   return <LearningToolTextbox
    //     url={item.url}
    //     width={slideWidth} height={slideHeight} />
    // else if (item.type === "interactive-case-study")
    //   return <LearningToolCaseStudy width={slideWidth} height={slideHeight}
    //     title={item.title}
    //     case_title={item.case_title}
    //     case_description={item.case_description}
    //     questions={item.questions}
    //   />
    // else if (item.type === "interactive-click-ins")
    //   return <LearningToolClickable width={slideWidth} height={slideHeight}
    //     background_left={item.background_left}
    //     icon_right_url={item.icon_right_url}
    //     header={item.header}
    //     question={item.question}
    //     instruction={item.instruction}
    //     clickables={item.clickables}
    //     isFullScreen={isFullScreen}
    //   />
    // else if (item.type === "interactive-right-filling")
    //   return <LearningToolRightSideFilling width={slideWidth} height={slideHeight}
    //     engagementData={app.engagementData}
    //     manageToolData={manageToolData}
    //     textBoxId='rightFilling'
    //     title={item.title}
    //     description={item.description}
    //     rightSidePoints={item.rightSidePoints}
    //     rightInfo={item.rightInfo}
    //     textBoxQuestion={item.textBoxQuestion}
    //     isFullScreen={isFullScreen}
    //   />
    // else if (item.type === 'Think_Sol_Eng_Case_Study')
    // return <ThinkSol_Case_Study width={slideWidth} height={slideHeight}
    //   engagementData={app.engagementData}
    //   manageToolData={manageToolData}
    //   data={item}
    //   isFullScreen={isFullScreen}
    // />
    // else if (item.type === 'Think_Sol_Eng_1F')
    //   return <ThinkSol_1 width={slideWidth} height={slideHeight}
    //     engagementData={app.engagementData}
    //     manageToolData={manageToolData}
    //     data={item}
    //     isFullScreen={isFullScreen}
    //   />
    // else if (item.type === "interactive-color-change-click")
    //   return <LearningToolColorChangingClick width={slideWidth} height={slideHeight}
    //     title={item.title}
    //     info={item.info}
    //     boxQuestion={item.boxQuestion}
    //     isFullScreen={isFullScreen}
    //   />
  }

  return <div className='linear-navigator'
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.06)',
      backgroundColor: '#ffffff', position: 'relative',
      display: 'flex', justifyContent: 'center'
      // }, Config.ACP_SHOW && window.location.host == Config.ACP_HOST ? { width: '100%' } : ""}>
    }}>
    <div className='slide-cntr' style={{
      width: slideWidth, height: slideHeight,
      display: 'flex', justifyContent: 'center'
    }}>
      {
        transitions.map(({ item, props, key }) => {
          return <animated.div
            key={key}
            className="slide"
            style={{ ...props, position: 'absolute' }}
          >
            {populateItem(item)}
          </animated.div>
        })}
    </div>
  </div>
}


const mapStateToProps = state => ({
  app: state.app,
});


const mapDispatchToProps = dispatch => {
  return {
    manageToolData: data => dispatch(manageToolData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinearNavigatorFrame);