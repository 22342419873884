import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch
  // , useSelector
 } from "react-redux";
import TopNavigation from "./../../../components/TopNavigation";
import LinearNavigator from "./../../../components/LinearNavigator";
import {
  getFormValues,
  getModuleDetails,
  getRoadmapDetails,
  getLearningToolDetails,
  updatePercentage,
  toggleLanguage
} from "./../redux_actions";
import "./conduct.css";
// import Interaction from "../Interaction"
// import ImageSlide from "./../../../components/ImageSlide";
// import store from "../../../Redux_Store"
import Config from "../../../config"
const { 
  // innerWidth: WinWidth, 
  innerHeight: WinHeight } = window;

let Conduct = (props) => {
  let module = {}
  // , moduleData = {};
  let moduleId = props.match.params.moduleId;
  let { user_token } = props.auth;
  let { user_uid } = props.auth;
  let { availableModule } = props.app;
  const dispatch = useDispatch();
  // const [close, setClose] = useState(false)

  module = { ...props.app.moduleDetails[moduleId] };
  let roadMaps =
    props.app.moduleDetails[moduleId] &&
    props.app.moduleDetails[moduleId].organiser &&
    props.app.moduleDetails[moduleId].organiser.roadmaps &&
    Object.keys(props.app.moduleDetails[moduleId].organiser.roadmaps).sort(
      (a, b) => {
        return (
          props.app.moduleDetails[moduleId].organiser.roadmaps[a].order -
          props.app.moduleDetails[moduleId].organiser.roadmaps[b].order
        );
      }
    );

  let firstRoadMapId = roadMaps && roadMaps[0];
  // let firstRoadmap = props.app.roadmaps[roadMaps[0]];
  let firstRoadmap = props.app.roadmaps[firstRoadMapId];
  let sortedUnitsOfFirstRoadmap = firstRoadmap
    ? Object.keys(firstRoadmap.LearningTools).sort((a, b) => {
      return firstRoadmap.LearningTools[a] - firstRoadmap.LearningTools[b];
    })
    : null;
  let firstTool = sortedUnitsOfFirstRoadmap
    ? sortedUnitsOfFirstRoadmap[0]
    : null;
  let [isComponentLoaded, updateComponentLoadStatus] = useState(false);
  // console.log(isComponentLoaded);
  // useEffect(() => {
  //   props.getModuleDetails({ id: moduleId, token: user_token })
  //     .then(res => {
  //       let roadMaps = Object.keys(res.payload.data.organiser.roadmaps).sort((a, b) => {
  //         return res.payload.data.organiser.roadmaps[a].order - res.payload.data.organiser.roadmaps[b].order
  //       });
  //       roadMaps.forEach(rdId => {
  //         props.getRoadmapDetails({ id: rdId, token: user_token })
  //           .then(res1 => {
  //             Object.keys(res1.payload.data.LearningTools).forEach(uId => {
  //               props.getLearningToolDetails({ id: uId, token: user_token })
  //             })
  //           })
  //       })
  //     });
  //   updateComponentLoadStatus(true)
  // }, [isComponentLoaded]);


  useEffect(() => {
    // load module details
    props.getModuleDetails({ id: moduleId, token: user_token }).then((res) => {
      if (res.payload) {
        let roadMaps =
          (res.payload.data.organiser.roadmaps &&
            Object.keys(res.payload.data.organiser.roadmaps).sort((a, b) => {
              return (
                res.payload.data.organiser.roadmaps[a].order -
                res.payload.data.organiser.roadmaps[b].order
              );
            })) ||
          [];

        roadMaps.forEach((rdId) => {
          props
            .getRoadmapDetails({ id: rdId, token: user_token })
            .then((ress) => {
              if (ress) {
                Object.keys(ress.payload.data.LearningTools).forEach((uId) => {
                  props.getLearningToolDetails({ id: uId, token: user_token });
                });
              }
            });
        });
      }
    });
    updateComponentLoadStatus(true);

    const { activeConductSessioId } = props.app;
    if (activeConductSessioId && props.app.continueConducts) {
      const session = props.app.continueConducts.filter(
        (c) => c.id === activeConductSessioId
      );

      props.getFormValues({
        sessionId: activeConductSessioId,
        token: user_token,
      });

      if (session) {
        dispatch({ type: "CREATESESSION_SUCCEEDED", payload: session[0] });
      }
    }

    // Before Unload Event
    // window.onbeforeunload = (event) => {
    //   event = event | window.event
    //   let  message = "Are you Sure?"

    //   if(event)
    //     return event.returnValue = message

    //   return message
    // }
      
  }, [isComponentLoaded]);

  let [currentUnit, updatecurrentUnit] = useState(props.app.activeContinueConductDetails.learningToolsId ? props.app.activeContinueConductDetails.learningToolsId : null);
  let [currentScene, setcurrentScene] = useState(props.app.activeContinueConductDetails.currentSlideIndex ? props.app.activeContinueConductDetails.currentSlideIndex : 0);
  let [currentRoadmap, setCurrentRoadmap] = useState(props.app.activeContinueConductDetails.roadmapId ? props.app.activeContinueConductDetails.roadmapId : null);
  let availableModules = availableModule;

  let slideHeight, slideWidth;
  slideHeight = WinHeight - 125 - 37.5;
  if (Config.ACP_SHOW && window.location.host === Config.ACP_HOST)
    slideWidth = (slideHeight * 4) / 3;
  else
    slideWidth = (slideHeight * 16) / 8.5;

  // const activeContinueConductDetails = useSelector(state => state.app.activeContinueConductDetails)

  return (
    <div className={"conduct-container"}>
      {/* {close ? <div>Hello Brother</div> : ""} */}
      <TopNavigation
        data={module}
        isHindi={props.app.isHindi}
        toggleLanguage={toggleLanguage}
        currentUnit={currentUnit || firstTool}
        units={props.app.learningTools}
        currentScene={currentScene} />

      <LinearNavigator
        moduleData={module}
        slideWidth={slideWidth}
        slideHeight={slideHeight}
        currentUnit={currentUnit || firstTool} //ToDo: null should be replaced with usermodule variable
        currentRoadmap={currentRoadmap || firstRoadMapId}
        units={props.app.learningTools}
        roadmaps={props.app.roadmaps}
        updatecurrentUnit={updatecurrentUnit}
        setcurrentScene={setcurrentScene}
        currentScene={currentScene}
        user_uid={user_uid}
        user_token={user_token}
        availableModules={availableModules}
        drawerVariant="permanent"
        margin="2.5rem"
        typPC="Conduct"
        setCurrentRoadmap={setCurrentRoadmap}
        app={props.app}
        auth={props.auth}
      />
    </div>
  );
};
// drawerVariant="temporary"
const mapStateToProps = (state) => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRoadmapDetails,
      getLearningToolDetails,
      getModuleDetails,
      updatePercentage,
      getFormValues,
      toggleLanguage
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Conduct);
