import React, { useEffect, useState } from 'react';
import { Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EdiText from 'react-editext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './style.css'
import Horizontal_Tab from '../../../Tools/Horizontal_Tab';
import TakeAway_Button from '../../../Tools/TakeAway_Button';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {

    const deckName = {
        dropDeck_1: 'Financial & Transactional value',
        dropDeck_2: 'Utility value',
        dropDeck_3: 'Experiential value',
        dropDeck_4: 'Human & Social value'
    }
    useEffect(() => {

        if (engagementData[data.prevToolName]) {
            if (data.prevToolName && (!engagementData[data.toolName] || Object.keys(engagementData[data.toolName]).length === 0))
                manageToolData({
                    toolId: data.toolName, content: {
                        ...engagementData[data.toolName],
                        'dragDeck': Object.keys(engagementData[data.prevToolName]).map(item => engagementData[data.prevToolName][item]),
                        ...data.drag_drop_deckId,
                        ...data.suggestedResponse
                    }
                })
            else {
                let removedDuplicateKeys = Object.keys(engagementData[data.prevToolName]).filter(item => {
                    let flag = false;
                    Object.keys(engagementData[data.toolName]).some((deck) => {
                        if (engagementData[data.toolName][deck]) {
                            flag = engagementData[data.toolName][deck].includes(engagementData[data.prevToolName][item])
                            console.log(flag, deck)
                        }
                        if (flag)
                            return true
                        console.log('heelo')
                        // console.log(deck)
                        // console.log(flag)
                    })
                    console.log('world')
                    if (!flag) {
                        console.log(item)
                    }
                    return !flag
                })
                manageToolData({
                    toolId: data.toolName, content: {
                        ...engagementData[data.toolName],
                        'dragDeck': Array.from(new Set([...engagementData[data.toolName]['dragDeck'], ...removedDuplicateKeys.map(item => engagementData[data.prevToolName][item])])),
                    }
                })

                console.log(Array.from(new Set([...engagementData[data.toolName]['dragDeck'], ...removedDuplicateKeys.map(item => engagementData[data.prevToolName][item])])))

            }
        }
        else if (!engagementData[data.toolName]) {
            console.log('here')
            manageToolData({
                toolId: data.toolName, content: {
                    ...engagementData[data.toolName],
                    'dragDeck': [],
                    ...data.drag_drop_deckId,
                    ...data.suggestedResponse
                }
            })
        }
    }, [])



    const right = ['67.5%', '35.5%', '2.5%'];
    const top = ['20%', '56%'];
    console.log(engagementData)
    const handleDragEnd = ({ destination, source }) => {

        console.log(destination, source)

        if (!destination) {
            return
        }
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return
        }

        const itemCopy = engagementData[data.toolName][source.droppableId][source.index]
        let temp = engagementData[data.toolName]
        console.log(temp, itemCopy)
        temp[source.droppableId].splice(source.index, 1)
        temp[destination.droppableId].splice(destination.index, 0, itemCopy)
        console.log(temp)
        manageToolData({ toolId: data.toolName, content: temp })

    }
    const [text, setText] = useState('')
    const handleChange = (e) => {
        setText(e.target.value)
    }
    const addItem = (e) => {
        if (text === "") {
            alert("Cannot add empty box")
            return
        }
        let temp = engagementData[data.toolName]['dragDeck'].push(text)
        manageToolData({
            toolId: data.toolName, content: {
                ...engagementData[data.toolName],
                dragDeck: temp
            }
        })
        setText('')
    }

    const handleSave = (item, key, newValue) => {
        let index = engagementData[data.toolName][key].findIndex((i) => i === item)
        console.log(index)
        if (index >= 0) {
            manageToolData({
                toolId: data.toolName,
                content: {
                    ...engagementData[data.toolName],
                    [key]: [
                        ...engagementData[data.toolName][key].slice(0, index),
                        newValue,
                        ...engagementData[data.toolName][key].slice(index + 1)
                    ]
                }
            })
        }
    }


    const handleRemove = (item, key) => {
        let items = engagementData[data.toolName][key].filter((i) => i !== item)
        manageToolData({
            toolId: data.toolName,
            content: {
                ...engagementData[data.toolName],
                [key]: items
            }
        })
    };


    return (
        <div style={{ width, height }}>
            {data.bgImg ? <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <div key={textBoxId} style={{ position: 'absolute', width: '30%', height: '29%', fontSize: isFullScreen ? '14px' : '12px', right: right[Math.floor(i % (data.textBoxId.length / 2))], top: top[Math.floor(i / (data.textBoxId.length / 2))] }}>
                        <Input id={data.inputId}
                            placeholder="Fill Here."
                            className='w-100'
                            style={{ fontSize: isFullScreen ? '16px' : '12px', height: '18%' }}
                            value={engagementData[data.prevTextBoxId[i]] || ''}
                            readOnly
                        />
                        <textarea id={textBoxId} placeholder='Fill Here…'
                            className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll w-100'}
                            value={engagementData[textBoxId] || ''}
                            style={{ fontSize: isFullScreen ? '16px' : '12px', height: '80%' }}
                            onChange={(event) => manageToolData({ toolId: textBoxId, content: event.target.value })}
                        />
                    </div>)
                }
            </div> : <div style={{ width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div className='w-35 h-100' style={{ border: '1px solid', position: 'absolute' }}>
                        <div className='multi-value-map-suggested-tab'>
                            <Horizontal_Tab tabData={data.suggestedResponse} engagementData={engagementData} manageToolData={manageToolData} data={data} />
                        </div>
                        <Droppable droppableId={'dragDeck'}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    className='tct-scrollable-darkgray multi-value-map-drop-deck-source'
                                >
                                    {engagementData[data.toolName] && engagementData[data.toolName]['dragDeck'] && engagementData[data.toolName]['dragDeck'].map((item, index) => (
                                        <Draggable
                                            key={item}
                                            draggableId={item}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className='multi-value-map-text-container'
                                                >
                                                    {item}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <div className='multi-value-map-send-div'>
                            <TextField
                                style={{ width: '100%', marginLeft: '2%' }}
                                type="text"
                                placeholder="add..."
                                variant="outlined"
                                size="small"
                                value={text}
                                onChange={handleChange} />
                            <Button color="secondary" onClick={addItem} >
                                <SendIcon fontSize='small' style={{ marginTop: "2%" }} />
                            </Button>
                        </div>
                    </div>
                    {Object.keys(data.drag_drop_deckId).map((dropDeck, index) => <Droppable key={dropDeck} droppableId={dropDeck}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                className={`tct-scrollable-darkgray multi-value-map-drop-deck multi-value-map-drop-deck-${dropDeck}`}
                            >
                                <div>{deckName[dropDeck]}</div>
                                {engagementData[data.toolName] && engagementData[data.toolName][dropDeck] && engagementData[data.toolName][dropDeck].map((item, index) => (
                                    <Draggable
                                        key={item}
                                        draggableId={item}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='multi-value-map-edit-text'
                                            >
                                                <EdiText
                                                    viewContainerClassName='my-custom-view-wrapper0'
                                                    type='textarea'
                                                    inputProps={{
                                                        className: 'textbox-scrollable-darkgray h-100',
                                                        style: {
                                                            minWidth: '100%',
                                                            resize: 'none',
                                                            height: '100%'
                                                        }
                                                    }}
                                                    hideIcons
                                                    cancelOnEscape
                                                    submitOnUnfocus
                                                    editOnViewClick
                                                    value={item}
                                                    onSave={(newValue) => handleSave(item, dropDeck, newValue)} >
                                                </EdiText>
                                                <DeleteForeverIcon
                                                    fontSize="small"
                                                    onClick={() => handleRemove(item, dropDeck)}></DeleteForeverIcon>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>)}
                </DragDropContext>
            </div>}
            {/* <div className='multi-value-map-takeaway-button'>
                <TakeAway_Button currentUnit={currentUnit} data={data} engagementData={engagementData} />
            </div> */}
        </div >
    );
}