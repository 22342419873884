import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import VideoPopup from "../Video_Popup";
import IntroScreen from "./introScreen";

import exploreWalkThrough from './assets/01-explore-walkthrough.svg'
import prepareWalkThrough from './assets/02-prepare-walkthrough.svg'
import conductWalkThrough from './assets/03-conduct-walkthrough.svg'

// import { updateFlag } from "../../containers/application/redux_actions";

let NextFlag = 0;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {

  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {/* {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const StyledDialog = withStyles({
  // '@global': {
  //   '.MuiPaper-rounded': {
  //     borderRadius: '10px',
  //     padding: '10px'
  //   },
  //   '.MuiOutlinedInput-root': {
  //     borderRadius: '15px',
  //   },
  //   '.MuiTypography-body1': {
  //     lineHeight: '1.5'
  //   },
  //   '.MuiDialog-paper': {
  //     width: '300px',
  //     position: 'absolute !important',
  //     left: '60px',
  //     boxShadow: 'none !important',
  //     overflow: 'visible !important',
  //     background: '#fff9c2 !important',
  //   },
  // },
  paper: {
    width: '300px',
    position: 'absolute !important',
    left: '40px',
    overflow: 'visible !important',
    background: '#fff9c2 !important',
    padding: '10px',
    borderRadius: '10px',
    lineHeight: '1.5'
  }
})(Dialog);


const DialogContent = withStyles((theme) => ({
  root: {
    padding: '0 !important',
    overflowY: 'visible !important'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '0 !important',
  },
}))(MuiDialogActions);


// const customButton_1_small = {
//   backgroundColor: '#ffbc36',
//   cursor: 'pointer',
//   fontSize: '12px'
// }

const customButton_1 = {
  backgroundColor: '#ffbc36',
  cursor: 'pointer',
}

// const customButton_2 = {
//   backgroundColor: '#8D8D8D',
//   cursor: 'pointer'
// }

// const customLink = {
//   color: '#8D8D8D',
//   textDecoration: 'none',
//   margin: '0px 15px'
// }
//let ModuleCardExplore = ({ title, description, moduleId, mainFilePath , uniqueAboutIt }) => {

let TooltipWindow = (userId, user_token, props) => {
  const [opens, setOpens] = React.useState(true);
  const [title, setTitle] = React.useState("Welcome to PedGog!");
  const [desc, setDesc] = React.useState(<div>This platform is organized into 3 zones: Explore, Prepare and Conduct. You can switch between these zones from the menu.</div>);
  const [topPos, setTopPos] = React.useState('-420px');
  const [displayTooltip, setDisplay] = React.useState('block');

  // const handleClose = () => {
  //   let type = "conduct";
  //   let user_Id = userId.userId;
  //   let user_token = userId.user_token
  //   updateFlag({ user_Id, user_token });
  //   setOpens(false);
  // };

  const clickEvent = () => {
    if (NextFlag === 0) {
      setTitle("Explore Zone");
      setDesc(<img src={exploreWalkThrough} style={{ width: '100%' }} alt='walkthrough' />);
      setTopPos('-125px');
      NextFlag = 1;
    }
    else if (NextFlag === 1) {
      setTitle("Prepare Zone");
      setDesc(<img src={prepareWalkThrough} style={{ width: '100%' }} alt='walkthrough' />);
      setTopPos('0px');
      NextFlag = 2;
    }
    else if (NextFlag === 2) {
      setTitle("Conduct Zone");
      setDesc(<img src={conductWalkThrough} style={{ width: '100%' }} alt='walkthrough' />);
      setTopPos('135px');
      NextFlag = 3;
    }
    else if (NextFlag === 3) {
      setTitle("Help");
      setDesc("Get a detailed walkthrough of how to use this platform");
      setTopPos('390px');
      NextFlag = 4;
    }
    // else if (NextFlag === 4) {
    //   handleClose();
    // }
  }

  const justClose = () => {
    setOpens(false);
  }


  return (
    <div style={{ top: topPos }}>
      {/* <div className='button-text' onClick={handleClickOpen}>
        <div className='button-label'>
          Tooltip
          </div>
      </div> */}
      <StyledDialog onClose={justClose} aria-labelledby="customized-dialog-title" open={opens} style={{ top: topPos, display: displayTooltip }}>
        {title === 'Welcome to PedGog!' ?
          <DialogTitle id="customized-dialog-title" onClose={justClose}>
            {title}
          </DialogTitle> : null}
        <DialogContent>
          <div style={{ width: '92%', margin: 'auto' }}>
            {/* <div style={{ width: '50%', paddingRight: '10px' }}>
              <Typography>
                {title}
              </Typography>
            </div> */}

            {/* <Typography> */}
            {desc}
            {/* </Typography> */}
          </div>
        </DialogContent>
        <DialogActions>
          {title === "Help" ?
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '12px' }}>
                <VideoPopup toolTip={true} url='https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Training+videos/Pedogog+Tutorial.mp4' poster='https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Training+videos/Platform+WalkthroughVideo.png' />
              </div>
              <div style={{ padding: '12px' }}>
                <IntroScreen parentClose={() => setOpens(false)} parentDisplay={() => setDisplay('none')} />
              </div>
            </div>
            :
            <Button style={customButton_1} onClick={clickEvent}>
              Next
          </Button>
          }
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
export default TooltipWindow;
