import React from 'react';
// import './style.css'
import TextEditor from '../../../../Tools/TextEditor/TextEditor';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {

    const textBoxWidth = ['18%', '20%', '23%'];
    const textBoxHeight = ['67.5%', '60.5%', '64.5%'];
    const top = ['24%', '29%', '23.5%']
    const right = ['48%', '28%', '4.5%'];

    let descriptionSize = '12px';
    // let subTitleSize = '12px';
    if (isFullScreen) {
        // subTitleSize = '16px'
        descriptionSize = '16px';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <div key={textBoxId} className='pos-absolute d-flex roboto' style={{ fontSize: descriptionSize, lineHeight: descriptionSize, width: textBoxWidth[i], height: textBoxHeight[i], top: top[i], right: right[i] }}>
                        <TextEditor id={data.id} initialData={engagementData[data.id] ? engagementData[data.id][data.textBoxId[i]] : null}
                            manageToolData={manageToolData}
                            scrollable='tct-scrollable-darkgray'
                            preFilledTextBoxData={['Fill in...']}
                            // textEditorClass='UPTC_CS_Square-textEditorClass'
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            toolBarClass='CD-CNP-textbox-toolbar'
                            hideToolbar={true}
                            oneHierarchy={true}
                            textBoxId={textBoxId}
                            engagementData={engagementData}
                        />
                    </div>
                    )}
            </div>
        </div >
    );
}