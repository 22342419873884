import React from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import './style.css'

const useStyles = makeStyles({
    root: {
        // '&:hover': {
        //     // backgroundColor: 'transparent',
        // },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',

        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },

        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: 'white',
        backgroundImage: 'linear-gradient(0deg,hsl(0deg 0% 0% / 12%),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#f00,#c40000 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            // backgroundColor: '#c40000',
        },
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            // disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {
    let textSize = '8px'
    if (isFullScreen)
        textSize = '16px'

    const handleRadioChange = (event, id) => {
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [id]: event.target.value
            }
        });
    }

    const conditionalRenderingHelperFn = (item, value) => {
        return engagementData?.[data.id]?.[engagementData[data.prevToolId][item]] === value
    }

    const conditionalRendering = (item, value, type) => {
        if (type === 'bgColor')
            return conditionalRenderingHelperFn(item, value) ? data.color[value] : 'transparent'
        else if (type === 'textColor')
            return conditionalRenderingHelperFn(item, value) ? 'white' : 'black'
        else if (type === 'borderRadius')
            return conditionalRenderingHelperFn(item, value) ? '10px 10px 10px 0px' : '10px 10px 0px 10px'
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className='sma-best-strategy-review-box-container tct-scrollable-darkgray'>
                    {
                        engagementData && engagementData?.[data.prevToolId] && Object.keys(engagementData[data.prevToolId]).map((item, index) => {
                            if (engagementData[data.prevToolId][item])
                                return <div key={item} className='sma-best-strategy-review-box'>
                                    <div style={{ borderRadius: conditionalRendering(item, 'mustDo', 'borderRadius'), backgroundColor: conditionalRendering(item, engagementData?.[data.id]?.[engagementData[data.prevToolId][item]], 'bgColor'), transition:'all .3s ease-in-out' }}>
                                        <div className='bierstadt-regular sma-best-strategy-review-text-box '>{engagementData[data.prevToolId][item]}</div>
                                    </div>
                                    <RadioGroup className='sma-best-strategy-review-radio-container' value={engagementData?.[data.id]?.[engagementData[data.prevToolId][item]]} onChange={(event) => handleRadioChange(event, engagementData[data.prevToolId][item])}>
                                        <FormControlLabel className='bierstadt-regular radio' style={{ borderBottomLeftRadius: '10px', backgroundColor: conditionalRendering(item, 'mustDo', 'bgColor'), color: conditionalRendering(item, 'mustDo', 'textColor') }} value="mustDo" control={<StyledRadio />} label="MUST do" />
                                        <FormControlLabel className='bierstadt-regular radio' style={{ borderBottomRightRadius: '10px', backgroundColor: conditionalRendering(item, 'goodDo', 'bgColor'), color: conditionalRendering(item, 'goodDo', 'textColor') }} value="goodDo" control={<StyledRadio />} label="Good to do" />
                                    </RadioGroup>
                                </div>
                            return
                        })
                    }
                </div>
            </div>
        </div >
    );
}