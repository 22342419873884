import React
// , { Component } 
from "react";
import { Link,
    //  Redirect 
    } from "react-router-dom";
import { Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Footer from "../../components/acp/Footer"
let LoginLink = props => <Link to="./auth/login" {...props} />
let RegisterLink = props => <Link to="./auth/register" {...props} />

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    input: {
        display: 'none'
    }
});

let acpHomePage = () => {
    return <div style={{ overflow: 'auto' }}>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 72 }}>
            <div style={{ width: 116 }}>
                <div style={{ marginTop: -16 }}><img alt="logo-home" src="./assets/logo-home.svg" width={100} /></div>
                {/* <div style={{fontSize: 20, fontWeight: 700}}>Illumine Digital</div> */}
                <div style={{ color: "#727272", fontSize: 14, textAlign: "right", paddingRight: 29 }}>A Digital Learning Platform by Illumine</div>
            </div>
            <div style={{ boxShadow: "0px 3px 6px #00000029", border: "1px solid #DDDDDD", display: "flex", justifyContent: "center", alignItems: "stretch" }}>
                <div style={{ height: 479, width: 404.89 }}><img alt="hero-img-pgm" src="./assets/hero-img-pgm-home.svg" height={479} width="auto" /></div>
                <div style={{ height: 479, width: 550, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div>
                        <div style={{ fontSize: 30, fontWeight: 500, padding: "40px 40px 20px" }}>Awakened Citizen Program - Digital Version</div>
                        <div style={{}}><a style={{ color: "#919191", padding: "0px 40px", display: "flex", alignItems: "center" }} href="http://theawakenedcitizen.org/"><img alt="web-icon" src="./assets/web-icon.svg" height={21.28} width="auto" /><span style={{ color: "#919191", fontSize: 14, paddingLeft: 6 }}>theawakenedcitizen.org</span></a></div>
                        <div style={{ margin: "20px 0px", padding: "0px 40px", fontSize: 14, lineHeight: "22px" }}>Considering the lockdown situation due to the COVID-19 pandemic, the program material is being made available online exclusively for trained teachers of the ACP. Teachers can now run sessions with their students digitally, using video conferencing tools.</div>
                    </div>
                    <div style={{ padding: "0px 40px" }}>
                        <div className="ch-sub-title" style={{ paddingBottom: 8, fontSize: 14 }}>Hosts:</div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: "flex" }}>
                                <div><img alt="rkm" src="/assets/RKM.png" height={63} style={{ maxWidth: "100%" }} /></div>
                                <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Delivered by Ramkrishna Mission,Delhi</div>
                            </div>
                            <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", paddingLeft: 16 }}>
                                <div><img alt="illumine-logo" src="https://s3.ap-south-1.amazonaws.com/illumine-logos/illuminelogo.svg" height={63} /></div>
                                <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Knowledge Partner: ILLUMINE</div>
                            </div>
                        </div>
                    </div>
                    <div className="action-btn-cntr" style={{ width: "100%", textAlign: "center" }}>
                        <Button classes={{ root: "btn-root-light", label: "btn-text" }} component={LoginLink} style={{ width: "50%" }}>Login</Button>
                        <Button classes={{ root: "btn-root", label: "btn-text" }} component={RegisterLink} style={{ width: "50%" }}>Register</Button>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default withStyles(styles)(acpHomePage)