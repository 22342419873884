import React from "react";
import { Switch, Route } from "react-router-dom";

import Prepare from "../application/channelPrepare";
import Conduct from "../application/conduct";
import App_ExploreDashboard from "../../components/App_ExploreDashboard";
import BatchDashboard from "./BatchDashboard";
// import OTP from "./OTP";

export default ({ app, auth }) => {
    return <Switch>
        {/* <Route exact path="/application/otp/:moduleId" component={OTP} /> */}
        <Route exact path="/application/prepare/:moduleId" component={Prepare} />
        <Route exact path="/application/conduct/:moduleId" component={Conduct} />

        {/* <Route exact path="/application/batch" render={() => (
            <BatchDashboard app={app} auth={auth} />
        )} /> */}
        <Route path="/application/" render={() => (
            <App_ExploreDashboard app={app} auth={auth} />
        )} />
    </Switch>
}