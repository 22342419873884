import firebase from "firebase/app";
import 'firebase/database'
import config from "../../config"
import _ from "lodash";
import { now } from "moment";

// var firebaseConfig = {
//     apiKey: "AIzaSyCEt3wyrOuWlNmbg9C3O40DzhgOT3MWvFk",
//     authDomain: "illumine-socket-test.firebaseapp.com",
//     databaseURL: "https://illumine-socket-test-default-rtdb.firebaseio.com",
//     projectId: "illumine-socket-test",
//     storageBucket: "illumine-socket-test.appspot.com",
//     messagingSenderId: "509881491143",
//     appId: "1:509881491143:web:272957979cd7cb8d7868e2"
// };

const firebaseConfig = {
    apiKey: "AIzaSyAbRUjiODv61sHpQXXSng2AOINvEc30X_U",
    authDomain: "pedgog-data-exchange.firebaseapp.com",
    databaseURL: "https://pedgog-data-exchange-default-rtdb.firebaseio.com",
    projectId: "pedgog-data-exchange",
    storageBucket: "pedgog-data-exchange.appspot.com",
    messagingSenderId: "803694997881",
    appId: "1:803694997881:web:e23a89e05289e9476d3fa8"
};

var stop = true;
var eventQueue = [];
let eventList = [];
var eventTypeAndHandler, senderType, senderId, receiveId, sessionid, _receiveType, _engagementId
var enableEngagementRef, engagementRef, participantRef
firebase.initializeApp(firebaseConfig);
const database = firebase.database()
let dataRef = database.ref()

async function initalize(engagementId, etah, userType, userId, receiveType, otp) {
    // Stoing Variables    
    eventTypeAndHandler = etah
    senderType = userType
    senderId = userId
    _receiveType = receiveType
    _engagementId = engagementId

    const session = await getSessionDetail(otp)
    if (session) {
        sessionid = session._id
        if (receiveType === "Student")
            receiveId = sessionid
        else if (receiveType === "Coach")
            receiveId = session.coachId

        enableEngagementRef = dataRef.child("live_session").child(sessionid).child("enableEngagement")
        engagementRef = dataRef.child("live_session").child(sessionid).child(_engagementId)
    }
}

async function startSession(sessionid, etah) {
    eventTypeAndHandler = etah
    stop = false
    participantRef = dataRef.child(`live_session/${sessionid}/Participants`)
    participantRef.on("value", (snapshot) => {
        let participants = snapshot.val()
        for (let participant in participants) {
            for (let event in participants[participant])
                eventQueue.push(participants[participant][event])
        }
    })
    loop()
}

async function startEngagement() {
    receiveEvent()
    loop()
}

async function getSessionDetail(otp) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        otp
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let session

    // await fetch("http://localhost:5001/v1/session/getSessionByOTP", requestOptions)
    await fetch(config.API_URL + "session/getSessionByOTP", requestOptions)
        .then(response => response.json())
        .then(result => { if (!result.code) return session = result })
        .catch(error => console.log('error', error));

    return session;
}

function receiveEvent() {
    stop = false
    engagementRef.on("child_added", (snapshot) => {
        let payload = snapshot.val()
        let payload_key = snapshot.key
        if (_.filter(eventList, _.matches(payload_key)).length === 0) {
            eventQueue.push(payload)
            eventList.push(payload_key)
        }
    })
}

async function loop() {
    while (!stop || eventQueue.length > 0) {
        let first = eventQueue.shift();
        if (first) {
            eventTypeAndHandler(first);
        }
        else
            await sleep(1)
    }
}

function sendEvent(eventName, eventData) {
    let payload = {
        senderType, senderId, eventName, eventData, receiveType: _receiveType, receiveId, startedAt: now()
    }
    switch (eventName) {
        case "START":
        case "STOP":
            enableEngagementRef.child(eventData).set(eventData)
            enableEngagementRef.child(eventData).set(payload)
            break;
        default:
            // let now = new Date();
            engagementRef.push(payload)
            break
    }
}


function stopEngagement() {
    stop = true;
    engagementRef.off()
}

function sleep(second) {
    return new Promise(resolve => setTimeout(resolve, second * 1000));
}

export default {
    initalize,
    sendEvent,
    stopEngagement,
    startEngagement,
    startSession
}