import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  // Input,
  // InputLabel,
  FormControl,
  Button,
  Typography,
  // CircularProgress,
  TextField
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
//import ForgotPasswordForm from "../../components/ForgotPassword";

import { resetPassword, resetForgotPasswordPage } from "./redux_actions";
import logo from "./assets/logo.svg";
import backArrow from "./assets/BackArrow.svg";
import succes from "./assets/Success.svg";

import "./assets/ForgotPassword.css";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

let ForgotPassword = class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstPaint: true,
      label: "",
    };

    this.toLogin = props => <Link to="../auth/login" {...props} />;

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };
  }

  handleValidation(eml) {
    this.setState({ label: "" });
    this.setState({ email: eml });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }

    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf('@');
      let lastDotPos = eml.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && eml.indexOf('@@') === -1 && lastDotPos > 2 && (eml.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email can not be empty" })
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  componentWillMount() {
    let { firstPaint } = this.state;
    if (firstPaint) {
      this.props.resetForgotPasswordPage();
      this.setState({ firstPaint: false });
    }
  }

  render() {
    let { auth } = this.props;
    // let { auth, classes } = this.props;
    let { passwordLinkSent } = auth;

    return (
      <div className={"auth-form-cntr"}>
        <div className="flexBox fbox">
          <div className="leftBoxLogin">
            <img src={logo} alt="Illumine" />
            <div className="d-block my-3">
              <p className="lfFont" >Your pathway to delivering <strong>transformative </strong> learning & coaching experiences! </p>
            </div>
          </div>
          <div className="rightBoxLogin">
            <form>
              <div className="errorMsg" style={{ display: 'flex' }}>
                <Link to='/auth/login' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }} ><img src={backArrow} alt="Illumine" /> <label>Back</label></Link>
              </div>
              <div className="errorMsg">
                <label style={{ fontSize: '12px' }}> {this.state.label} </label>
              </div>

              {passwordLinkSent
                ? <div className={"loginform-cntr"}>
                  <div className="errorMsg text-left flexBox align-items-center">
                    <img src={succes} alt="Illumine" /> <label className="text-success ml-3">Success</label>
                  </div>
                  <small className="text-left mb-3 d-block">An email has been sent to you with steps on how to reset your password. Wait for a couple of minutes to receive the email.
                    Do check spam. ​</small>

                  <p className="text-left mb-3">Been a while, and not received the email?</p>
                  <FormControl fullWidth className={"input-cntr"}>
                    <TextField className="mb-3" id="user-email" label="Your Registered Email ID" variant="outlined" value={this.state.email} onChange={this.handleChange("email")} />
                  </FormControl>

                  <Typography className="errorMsg" variant="caption" gutterBottom align="center" color="secondary">
                    {this.props.authMsg}
                  </Typography>

                  <FormControl fullWidth className={"input-cntr"}>
                    <Button variant="raised" color="primary" className="loginBtn"
                      onClick={() => {
                        let { email } = this.state;
                        let chkVal = this.handleValidation(this.state.email);
                        if (chkVal) {
                          this.props.resetPassword({
                            email,
                            site_url: window.location.origin,
                            successCallBack: () => {
                              //alert('success');
                            },
                            failureCallback: () => {
                              //alert('failed');
                            }
                          });
                        }
                        else {
                          this.setState({ label: "Please Enter Registered Email." })
                        }
                      }
                      }
                    >
                      Resend Email to Reset Password
                    </Button>
                  </FormControl>
                </div>
                : <div className={"loginform-cntr"}>
                  <h4 className="text-left mb-3">To reset your password, enter your registered Email ID: ​</h4>
                  <FormControl fullWidth className={"input-cntr"}>
                    <TextField className="mb-3" id="user-email" label="Your Registered Email ID" variant="outlined" value={this.state.email} onChange={this.handleChange("email")} />
                  </FormControl>

                  <FormControl fullWidth className={"input-cntr"}>
                    <Button variant="raised" color="primary" className="loginBtn"
                      onClick={() => {
                        let { email } = this.state;
                        let chkVal = this.handleValidation(this.state.email);

                        if (chkVal) {
                          this.props.resetPassword({
                            email,
                            site_url: window.location.origin,
                            successCallBack: () => {
                              //alert('success');
                            },
                            failureCallback: () => {
                              //alert('failed');
                            }
                          });
                        }
                        else {
                          this.setState({ label: "Please Enter Registered Email." })
                        }
                      }
                      }
                    >
                      Reset Password
                    </Button>
                  </FormControl>
                </div>
              }

            </form>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div className="forgot-pw-pg-cntr">
    //     <div className="forgot-pw-cntr">
    //       <div>
    //         <Typography align="center" variant="title" gutterBottom>
    //           {passwordLinkSent
    //             ? `We just sent an email to ${this.state.email}`
    //             : "Forgot your password?"}
    //         </Typography>
    //       </div>
    //       <div className="forgot-pw-text-msg">
    //         <Typography gutterBottom>
    //           {passwordLinkSent
    //             ? "Click the secure link we sent you to reset your password. If you didn't receive an email, check your Spam Folder or try again and make sure you enter the email address associated with channel illumine account."
    //             : "Enter your email address below and we'll send you a secure link to reset your password"}
    //         </Typography>
    //       </div>
    //     </div>
    //     {passwordLinkSent ? null : (
    //       <div>
    //         <FormControl fullWidth className="input-cntr">
    //           <InputLabel htmlFor="adornment-password">Email</InputLabel>
    //           <Input
    //             id="user-email"
    //             label="Email"
    //             type="text"
    //             autoComplete="on"
    //             value={this.state.email}
    //             onChange={this.handleChange("email")}
    //           />
    //         </FormControl>
    //       </div>
    //     )}
    //     <div>
    //       <Typography
    //         className="errorMsg"
    //         variant="caption"
    //         gutterBottom
    //         align="center"
    //         color="secondary"
    //       >
    //         {this.props.auth.forgotPasswordErrorMsg}
    //       </Typography>
    //     </div>
    //     <div>
    //       {resetPasswordInProgress ? (
    //         <CircularProgress size={32} className={classes.progress} />
    //       ) : (
    //         <FormControl fullWidth className="input-cntr">
    //           {passwordLinkSent ? this.toLoginButton() : this.RegisterButton()}
    //         </FormControl>
    //       )}
    //     </div>
    //     <div className="login-link-cntr">
    //       <Button
    //         component={ this.toLogin }
    //       >
    //         <Typography className="errorMsg" variant="caption" gutterBottom>
    //           Login
    //         </Typography>
    //       </Button>
    //       <Button
    //         component= { this.toRegister }
    //       >
    //         <Typography
    //           className="errorMsg"
    //           variant="caption"
    //           gutterBottom
    //           color="primary"
    //         >
    //           New User? Register
    //         </Typography>
    //       </Button>
    //     </div>
    //   </div>
    // );
  }
};

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  resetPassword: PropTypes.func,
  resetForgotPasswordPage: PropTypes.func
};

ForgotPassword = withStyles(styles)(ForgotPassword);

const mapStateToProps = state => ({
  auth: state.auth
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
      resetForgotPasswordPage
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ForgotPassword);
