import React, { useEffect, useState, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EdiText from 'react-editext';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import HoverFruits from './hoverFruits';

import { getFormValuesForBatchAndModule } from '../../containers/application/redux_actions';

import './style.css'


export default ({ width, height, engagementData, manageToolData, isFullScreen, data, app, auth }) => {

    const [searchText, setSearchText] = useState('');
    let allFruits = [];

    const outerFruitDiv = useRef(null)
    const joyFruitDiv = useRef(null)
    const innerFruitDiv = useRef(null)
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        if (outerFruitDiv.current) {
            setFlag(true)
        }
    }, [outerFruitDiv, joyFruitDiv, innerFruitDiv])

    useEffect(async () => {
        // let temp = data?.prevModuleId && await getFormValuesForBatchAndModule({ user_token: auth.user_token, batchId: app.selectedBatch?._id, moduleId: data.prevModuleId })
        let temp = false;
        if (!temp && (!engagementData || !engagementData[data.toolName] || !engagementData[data.toolName][data.id]))
            manageToolData({
                toolId: data.toolName, content: {
                    ...engagementData[data.toolName],
                    [data.id]: {
                        ...data.drag_drop_deckId,
                        ...data.suggestedFruits,
                        userAddedFruit: [],
                        selectedSuggestedFruit: '',
                    }
                }
            })

    }, [data.toolName, data.id])


    const handleSave = (item, key, newValue) => {
        let index = engagementData[data.toolName][data.id][key].findIndex((i) => i === item)
        console.log(index)
        if (index >= 0) {
            manageToolData({
                toolId: data.toolName,
                content: {
                    ...engagementData[data.toolName],
                    [data.id]: {
                        ...engagementData[data.toolName][data.id],
                        [key]: [
                            ...engagementData[data.toolName][data.id][key].slice(0, index),
                            newValue,
                            ...engagementData[data.toolName][data.id][key].slice(index + 1)
                        ]
                    }
                }
            })
        }
    }

    const handleRemove = (item, key) => {
        let items = engagementData[data.toolName][data.id][key].filter((i) => i !== item)
        manageToolData({
            toolId: data.toolName,
            content: {
                ...engagementData[data.toolName],
                [data.id]: {
                    ...engagementData[data.toolName][data.id],
                    [key]: items
                }
            }
        })
    }



    const handleDragEnd = ({ destination, source }) => {

        if (!destination || destination.droppableId === engagementData[data.toolName][data.id]['selectedSuggestedFruit']) {
            return
        }
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return
        }
        if (source.droppableId === 'userAddedFruit') {
            Object.keys(data.suggestedFruits).map(key => {
                let foundIndex = engagementData[data.toolName][data.id][key].findIndex(text => text === allFruits[source.index]);
                console.log(foundIndex)
                if (foundIndex !== -1) {
                    source.index = foundIndex;
                    source.droppableId = key;
                }
            })
        }

        console.log(destination, source)

        const itemCopy = engagementData[data.toolName][data.id][source.droppableId][source.index]
        let temp = engagementData[data.toolName][data.id]
        console.log(temp, itemCopy)
        temp[source.droppableId].splice(source.index, 1)
        temp[destination.droppableId].splice(destination.index, 0, itemCopy)
        manageToolData({
            toolId: data.toolName, content: {
                ...engagementData[data.toolName],
                [data.id]: temp
            }
        })
    }

    const handleSuggestedFruitChange = (fruitName) => {
        manageToolData({
            toolId: data.toolName, content: {
                ...engagementData[data.toolName],
                [data.id]: {
                    ...engagementData[data.toolName][data.id],
                    selectedSuggestedFruit: fruitName
                }
            }
        })
    }

    const userAddedFruitHelperFn = () => {
        let allFruitsTemp = [...engagementData[data.toolName][data.id]['userAddedFruit'], ...Object.keys(data.suggestedFruits).map(key => engagementData[data.toolName][data.id][key]).flat()];
        console.log(allFruits)
        // manageToolData({
        //     toolId: data.toolName, content: {
        //         ...engagementData[data.toolName],
        //         userAddedFruit: fruits
        //     }
        // })
        allFruits = allFruitsTemp.filter((fruit, index) => {
            if ((engagementData[data.toolName][data.id]['userAddedFruit'].length - 1) >= index || searchText !== '' && fruit.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
                return true
            return false
        })
        return allFruits;
    }

    const handleUserAddedFruit = () => {
        if (searchText === "") {
            alert("Cannot add empty fruit")
            return
        }
        manageToolData({
            toolId: data.toolName, content: {
                ...engagementData[data.toolName],
                [data.id]: {
                    ...engagementData[data.toolName][data.id],
                    userAddedFruit: [searchText, ...engagementData[data.toolName][data.id]['userAddedFruit']]
                }
            }
        })
        setSearchText('')
    }

    console.log(engagementData[data.toolName])

    return (
        <div style={{ width, height }}>
            {/* <div className='w-25 tofv2-case-study-container h-95 overFlowYAuto tct-scrollable'>

            </div> */}
            <div className='w-100 h-100 tofv2-right-container' >
                <div style={{ marginTop: '1%' }} className='w-100 d-flex  justify-content-space-between'>
                    <div className='tofv2-case-story-title'>
                        {data.caseStory.title}
                    </div>
                    <div>
                        <div className='tofv2-title-text'>
                            {data.title}
                        </div>
                        <div className='tofv2-sub-title-text ap-gray-color-1'>
                            {data.subTitle}
                        </div>

                    </div>
                    <img src={data.toolLogo} className='w-20 h-100' alt='tool-logo' />
                </div>
                <div className='w-100 h-80 d-flex'>
                    <div className='tofv2-case-story-img'>
                        <img src={data.caseStory.img} className='w-100 h-100' alt='case-img' />
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd} >
                        <div className='d-flex align-items-center' style={{ width: '23%', position: 'relative' }}>
                            <img src={data.treeImgFull} className='w-100 h-80' alt='tree' />
                            <div ref={outerFruitDiv} className='tofv2-outer-fruit-hover'>
                                {flag && <HoverFruits fruitDiv={outerFruitDiv} fruitType='Outer_fruit' engagementData={engagementData} toolName={data.toolName} toolId={data.id} />}
                            </div>
                            <div ref={joyFruitDiv} className='tofv2-joy-fruit-hover'>
                                {flag && <HoverFruits fruitDiv={joyFruitDiv} fruitType='Joy_of_contribution' engagementData={engagementData} toolName={data.toolName} toolId={data.id} />}
                            </div>
                            <div ref={innerFruitDiv} className='tofv2-inner-fruit-hover'>
                                {flag && <HoverFruits fruitDiv={innerFruitDiv} fruitType='Inner_fruit' engagementData={engagementData} toolName={data.toolName} toolId={data.id} />}
                            </div>
                        </div>
                        <div className='w-55' style={{ margin: '8px', marginTop: '0px' }}>
                            <div className='tofv2-fruit-box tofv2-outer-box-color' style={{ position: 'relative' }}>
                                <div className='tofv2-outer-text-color' style={{ marginBottom: '1%' }} >
                                    <div className='tofv2-card-fruit-text soure-serif font-weight-bold'>
                                        Outer Fruit
                                    </div>
                                    <div id='tofv2-discover-text-outer-fruit' className={`tofv2-card-discover-text tofv2-discover-text-postion ${engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['selectedSuggestedFruit'] === 'suggested_Outer_fruit' ? 'tofv2-color-suggested_Outer_fruit' : ''}`}
                                        style={{ transition: 'all .2s ease-out' }}
                                        onClick={() => handleSuggestedFruitChange('suggested_Outer_fruit')}>
                                        Did you consider these &gt;
                                    </div>
                                </div>
                                <div className='w-100 h-90'>
                                    <Droppable droppableId='Outer_fruit'>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className={`tct-scrollable w-100 h-100 tofv2-fruit-box-deck`}
                                            >

                                                {engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['Outer_fruit'] && engagementData[data.toolName][data.id]['Outer_fruit'].map((item, index) => (
                                                    <Draggable
                                                        key={item}
                                                        draggableId={item}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className='tofv2-edit-text tofv2-outer-text-box-color'
                                                            >
                                                                <EdiText
                                                                    viewContainerClassName='my-custom-view-wrapper0'
                                                                    type='textarea'
                                                                    inputProps={{
                                                                        className: 'edit-text-textbox-scrollable-darkgray h-100',
                                                                        style: {
                                                                            minWidth: '100%',
                                                                            resize: 'none',
                                                                            height: '100%'
                                                                        }
                                                                    }}
                                                                    hideIcons
                                                                    cancelOnEscape
                                                                    submitOnUnfocus
                                                                    editOnViewClick
                                                                    value={item}
                                                                    onSave={(newValue) => handleSave(item, 'Outer_fruit', newValue)} >
                                                                </EdiText>
                                                                <CloseIcon
                                                                    style={{ fontSize: '1rem', color: 'gray' }}
                                                                    onClick={() => handleRemove(item, 'Outer_fruit')}></CloseIcon>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                            <div className='tofv2-fruit-box tofv2-joy-box-color' style={{ position: 'relative' }}>
                                <div className='tofv2-joy-text-color' style={{ marginBottom: '3%' }}>
                                    <div className='tofv2-card-fruit-text soure-serif font-weight-bold'>
                                        Joys of Contribution
                                    </div>
                                    <div id='tofv2-discover-text-joy-fruit' className={`tofv2-card-discover-text tofv2-discover-text-postion ${engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['selectedSuggestedFruit'] === 'suggested_Joy_of_contribution' ? 'tofv2-color-suggested_Joy_of_contribution' : ''}`}
                                        style={{ transition: 'all .2s ease-out' }}
                                        onClick={() => handleSuggestedFruitChange('suggested_Joy_of_contribution')}>
                                        Did you consider these &gt;
                                    </div>
                                </div>
                                <div className='w-100 h-90'>
                                    <Droppable key={'Joy_of_contribution'} droppableId={'Joy_of_contribution'}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className={`tct-scrollable w-100 h-100 tofv2-fruit-box-deck`}
                                            >

                                                {engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['Joy_of_contribution'] && engagementData[data.toolName][data.id]['Joy_of_contribution'].map((item, index) => (
                                                    <Draggable
                                                        key={item}
                                                        draggableId={item}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className='tofv2-edit-text tofv2-joy-text-box-color'
                                                            >
                                                                <EdiText
                                                                    viewContainerClassName='my-custom-view-wrapper0'
                                                                    type='textarea'
                                                                    inputProps={{
                                                                        className: 'edit-text-textbox-scrollable-darkgray h-100',
                                                                        style: {
                                                                            minWidth: '100%',
                                                                            resize: 'none',
                                                                            height: '100%'
                                                                        }
                                                                    }}
                                                                    hideIcons
                                                                    cancelOnEscape
                                                                    submitOnUnfocus
                                                                    editOnViewClick
                                                                    value={item}
                                                                    onSave={(newValue) => handleSave(item, 'Joy_of_contribution', newValue)} >
                                                                </EdiText>
                                                                <CloseIcon
                                                                    style={{
                                                                        fontSize: '1rem',
                                                                        color: 'gray'
                                                                    }}
                                                                    onClick={() => handleRemove(item, 'Joy_of_contribution')}></CloseIcon>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                            <div className='tofv2-fruit-box tofv2-inner-box-color' style={{ position: 'relative' }}>
                                <div className='tofv2-inner-text-color' style={{ marginBottom: '3%' }}>
                                    <div className='tofv2-card-fruit-text soure-serif font-weight-bold'>
                                        Inner Fruits
                                    </div>
                                    <div id='tofv2-discover-text-inner-fruit' className={`tofv2-card-discover-text tofv2-discover-text-postion ${engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['selectedSuggestedFruit'] === 'suggested_Inner_fruit' ? 'tofv2-color-suggested_Inner_fruit' : ''}`}
                                        style={{ transition: 'all .2s ease-out' }}
                                        onClick={() => handleSuggestedFruitChange('suggested_Inner_fruit')}>
                                        Did you consider these &gt;
                                    </div>
                                </div>
                                <div className='w-100 h-90'>
                                    <Droppable droppableId='Inner_fruit'>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className={`tct-scrollable w-100 h-100 tofv2-fruit-box-deck`}
                                            >

                                                {engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['Inner_fruit'] && engagementData[data.toolName][data.id]['Inner_fruit'].map((item, index) => (
                                                    <Draggable
                                                        key={item}
                                                        draggableId={item}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className='tofv2-edit-text tofv2-inner-text-box-color'
                                                            >
                                                                <EdiText
                                                                    viewContainerClassName='my-custom-view-wrapper0'
                                                                    type='textarea'
                                                                    inputProps={{
                                                                        className: 'edit-text-textbox-scrollable-darkgray h-100',
                                                                        style: {
                                                                            minWidth: '100%',
                                                                            resize: 'none',
                                                                            height: '100%'
                                                                        }
                                                                    }}
                                                                    hideIcons
                                                                    cancelOnEscape
                                                                    submitOnUnfocus
                                                                    editOnViewClick
                                                                    value={item}
                                                                    onSave={(newValue) => handleSave(item, 'Inner_fruit', newValue)} >
                                                                </EdiText>
                                                                <CloseIcon
                                                                    style={{
                                                                        fontSize: '1rem',
                                                                        color: 'gray'
                                                                    }}

                                                                    onClick={() => handleRemove(item, 'Inner_fruit')}></CloseIcon>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </div>
                        <div className='w-25'>
                            <div className='d-flex align-items-end'>
                                <TextField label="Type to add fruit" value={searchText} onClick={() => handleSuggestedFruitChange('userAddedFruit')} onChange={(event) => setSearchText(event.target.value)} />
                                <AddIcon onClick={handleUserAddedFruit} />
                            </div>
                            <div className='tablet-gothic ap-gray-color-1 tofv2-sub-title-text'>
                                Choose the relevant fruits and drag to the respective section
                            </div>

                            {
                                engagementData[data.toolName] && engagementData[data.toolName][data.id] && engagementData[data.toolName][data.id]['selectedSuggestedFruit'] && <Droppable droppableId={engagementData[data.toolName][data.id]['selectedSuggestedFruit']}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className='h-75 overFlowYAuto tct-scrollable'
                                        >
                                            {
                                                engagementData[data.toolName] && engagementData[data.toolName][data.id] && (engagementData[data.toolName][data.id]['selectedSuggestedFruit'] === 'userAddedFruit' ? userAddedFruitHelperFn() : engagementData[data.toolName][data.id][engagementData[data.toolName][data.id]['selectedSuggestedFruit']]).map((item, index) => {
                                                    return <Draggable
                                                        key={item}
                                                        draggableId={item}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`tofv2-card-fruit-text tofv2-suggested-text-container box-shadow tofv2-color-${engagementData[data.toolName][data.id]['selectedSuggestedFruit']}`}
                                                            >
                                                                {item}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>}
                        </div>
                    </DragDropContext>

                </div>
                <div className='w-100 h-10'>
                    <img src={data.bottomBarImg} className='w-100 h-100' alt='bottom-img' />
                </div>
            </div>

        </div >
    )
}