import React from 'react';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

import { Input } from '@material-ui/core'

import './style.css';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    let descriptionSize = '13px';

    let textbox_1_right = '31%';
    let textbox_1_top = '56%';

    let textbox_2_right = '3%';
    let textbox_2_top = '56%';

    let input_right = '5%';
    let input_top = '44.4%';

    if (isFullScreen) {
        descriptionSize = '16px';
        // input_right = '10%'
        // textbox_1_right = '36%';
        // textbox_2_right = '10%';
    }

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <Input className='WIAC_any_role_input'
                    placeholder="Fill Here."
                    style={{ fontSize: descriptionSize, right: input_right, top: input_top }}
                    value={engagementData[data.textBoxId[0]] || ''}
                    onChange={(event) => {
                        manageToolData({ toolId: data.textBoxId[0], content: event.target.value })
                    }}
                />
                <div className={`WIAC_any_role_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_1_top, right: textbox_1_right }}>
                    <TextEditor id={data.textBoxId[1]} initialData={engagementData[data.textBoxId[1]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
                <div className={`WIAC_any_role_textBox work-sans`} style={{ fontSize: descriptionSize, top: textbox_2_top, right: textbox_2_right }}>
                    <TextEditor id={data.textBoxId[2]} initialData={engagementData[data.textBoxId[2]] || null}
                        manageToolData={manageToolData} placeholder='Fill here...' hideToolbar={true}
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                    />
                </div>
            </div>
        </div>
    );
}