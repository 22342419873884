import React from 'react';
// import Bar_Chart from '../../../Live_Session_Tools/Bar_Chart'
import Radar_Chart from '../../../Live_Session_Tools/Radar_Chart'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId, responses, currentEngagement, uniqueStudentsName }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: 'white', width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {/* <div style={{ position: 'absolute', top: '21%', right: '2%', width: '20%', height: '15%' }}>
                    {
                        // (sessionId) &&
                        <Start_N_Stop width={width} height={height}
                            engagementData={engagementData}
                            manageToolData={manageToolData}
                            data={data}
                            isFullScreen={isFullScreen}
                            engagementStatus={engagementStatus}
                            sessionId={sessionId}
                        />
                    }
                </div> */}
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    top: '10%',
                    height: '80%'
                }}>
                    {/* <Bar_Chart data={data} responses={responses}
                        currentEngagement={currentEngagement}
                        uniqueStudentsName={uniqueStudentsName} /> */}
                    <Radar_Chart data={data} responses={responses} width='100%' height='100%'
                        currentEngagement={currentEngagement}
                        uniqueStudentsName={uniqueStudentsName} />
                </div>
            </div>
        </div >
    );
}