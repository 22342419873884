import React, { Component } from "react";
import { 
  // Link, 
  Redirect } from "react-router-dom";
// import { Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
// import Footer from "../../components/acp/Footer"
import ACPHomePage from "./ACPHomePage";
import APLoginPage from "../asianPaints/Login";
import Config from "../../config"
// let LoginLink = props => <Link to="./auth/login" {...props} />
// let RegisterLink = props => <Link to="./auth/register" {...props} />

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: 'none'
  }
});

let Public = class Public extends Component {

  render() {
    console.log(this.props);
    // const { classes } = this.props;
    if (Config.ACP_SHOW && window.location.host === Config.ACP_HOST)
      return <ACPHomePage />
    if ((Config.AP_SHOW && window.location.host === Config.AP_HOST))
      return <APLoginPage />
    else {
      return <Redirect to={{
        pathname: "/auth/login",
        state: { from: window.location.href },
      }
      } />
    }
  }
}

export default withStyles(styles)(Public)