import React from 'react';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import './style.css';

let VideoSlide = ({ vidUrl, dimensions, popupWidth, popupHeight, imgUrl }) => {
    // let providedRatio = dimensions.slideWidth/dimensions.slideHeight;
    return (
        <div style={{ width: popupWidth || 'unset', height: popupHeight || 'unset' }}>
            {/* <video style={{ border: 'none', outline: 'none' }} controls width="100%">
                <source src={vidUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
            <Player
                playsInline
                src={vidUrl || 'https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Training+videos/Pedogog+Tutorial.mp4'}
                fluid={false}
                poster={imgUrl || "https://coaching-platform.s3.ap-south-1.amazonaws.com/gtu/Training+videos/Platform+WalkthroughVideo.png"}
                width={dimensions.slideWidth}
                height={dimensions.slideHeight}
                style={{ border: 'none', outline: 'none', position: 'relative' }}
            />
        </div>
    )
}

export default VideoSlide;