import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
// import InputLabel from '@material-ui/core/InputLabel';
import { 
    // Typography, 
    Button } from '@material-ui/core';
import { useDispatch
    // , useSelector 
} from 'react-redux';
import { loginAttempt, getOrganizationAttempt, getAccessTokenDetails, registerAttempt, subscribePack } from '../../auth/redux_actions';
import PegogLogo from '../Login/assets/home-left combined.svg'
import IllumineLogo from '../Login/assets/illumine-home.svg'
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
    filledRoot: {
        // '& > *': {
        //     margin: theme.spacing(1),
        // },
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        /* background-color: rgba(0, 0, 0, 0.09); */
        borderRadius: '17px',
        padding: '0px',
        /* border-top-right-radius: 4px; */
        border: '2px solid #E0E0E0',
        backgroundColor: 'transparent',
        width: '100%',
        fontFamily: "tablet-gothic, sans-serif",
        // '&$focused': {
        //     backgroundColor: '#fff',
        // }
        '&:hover': {
            backgroundColor: '#f5f5f5 !important'
        }
    },
    filledRootFocused: {
        backgroundColor: '#f5f5f5 !important'
    },
    formControlRoot: {
        display: 'block',
        fontFamily: "tablet-gothic, sans-serif"
    },
    
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        },
    },
    filledInput: {
        padding: '20px 10px'
    }
}));


export default ({ app, auth }) => {

    useEffect(() => {
        let email = "su@ggmail.com";
        let password = "acs12345";
        let flag = 0;
        dispatch(loginAttempt({
            email,
            password, flag,
            successCallBack: (val) => {
                //alert('success')
                setValues({ ...values, uToken: val.tokens.refresh.token });
            },
            failureCallback: () => {
                //alert('failed');
            }
        }));
    }, [])

    const dispatch = useDispatch();
    const [values, setValues] = React.useState({
        accesscode: '',
        fullname: '',
        email: '',
        password: '',
        showPassword: false,
        error: '',
        uToken: ''
    });

    const handleChange = (prop) => (event) => {
        if (prop === 'accesscode')
            setValues({ ...values, [prop]: (event.target.value).toUpperCase() });
        else
            setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleValidation = (eml, pwd, fnm) => {
        let errors = {};
        let formIsValid = true;

        //Email
        if (!eml) {
            formIsValid = false;
            errors["user-email"] = "Email cannot be empty";
            setValues({ ...values, error: 'Email cannot be empty' });
        }
        //Password
        if (!pwd) {
            formIsValid = false;
            errors["user-password"] = "Password cannot be empty";
            setValues({ ...values, error: "Password cannot be empty" });
        }

        if (!fnm) {
            formIsValid = false;
            errors["fullname"] = "Full Name cannot be empty";
            setValues({ ...values, error: "Full Name cannot be empty" });
        }

        if (typeof fnm !== "undefined") {
            if (!fnm.match(/^[a-zA-Z ]+$/)) {
                formIsValid = false;
                errors["fullnamename"] = "Only letters";
                setValues({ ...values, error: "Full Name accepts only letters" });
            }
        }

        if (typeof eml !== "undefined") {
            let lastAtPos = eml.lastIndexOf("@");
            let lastDotPos = eml.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    eml.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    eml.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["user-email"] = "Email is not valid";
                setValues({ ...values, error: "Email cannot be empty" });
            }
        }
        // this.setState({ errors: errors });
        return formIsValid;
    }
    const handleRegister = () => {
        let { email, password, accesscode, fullname } = values;
        let chkVal = handleValidation(email, password, fullname);
        // let chkVal1 = false;

        // TO-DO: Port to Backend

        if (chkVal) {
            dispatch(getAccessTokenDetails({ accesscode, email }))
                .then(result => {
                    // chkVal1 = true;
                    //let { accesscodeData } = this.props.auth;

                    if (result.data) {
                        if (result.data.length > 0) {
                            let allowedCount = result.data[0].allowedCount;
                            let organizationid = result.data[0].organizationId;
                            let usedCount = result.data[0].usedCount;
                            let pkids = result.data[0].packIds;
                            // let acid = result.data[0].id;

                            let pkid = [];
                            //let pkid = "[";
                            // {
                                pkids.map((el, i) => {
                                    if (el) {
                                        let arrval = '{"PackId": "' + el + '", "fromDate": "08/01/2020", "toDate": "08/31/2020", "status": "Active"}';
                                        pkid.push(arrval);
                                    }
                                })
                            // }
                            // pkid = pkid.substring(0, pkid.length - 1);
                            // pkid += "]";

                            let uid = null;
                            if (parseInt(allowedCount, 10) > parseInt(usedCount, 10)) {
                                dispatch(registerAttempt({
                                    email,
                                    password,
                                    accesscode,
                                    fullname,
                                    organizationid,
                                    successCallBack: (val) => {
                                        if (val.message) {
                                            setValues({ ...values, error: val.message })
                                        }
                                        uid = val.user.id;
                                        if (uid !== null) {
                                            //use that token
                                            let uTok = values.uToken;

                                            //subscribe
                                            dispatch(subscribePack({ uid, pkid, uTok }))
                                                .then(result => {
                                                    setValues({ ...values, error: "Registration Successfully Done!" })
                                                    let flag = 1;
                                                    dispatch(loginAttempt({
                                                        email,
                                                        password, flag,
                                                        successCallBack: (usr) => {
                                                            let orgid = usr.user.organizationid;
                                                            let token = usr.tokens.access.token;
                                                            dispatch(getOrganizationAttempt({ orgid, token }))
                                                                .then(result => {
                                                                    // let conductflag = result.conductflag;
                                                                })
                                                        },
                                                        failureCallback: () => {
                                                            //alert('failed');
                                                        }
                                                    }));
                                                });
                                        }
                                    },
                                    failureCallback: () => {
                                        //alert('failed');
                                    }
                                }));

                                // this.setState({label: "Registration Successfully Done.."})
                            }
                            else {
                                setValues({ ...values, error: "Licence Limit Over!" })
                            }
                        }
                        else {
                            //this.setState({label: "Some values are not correct. Please try again."})
                            setValues({ ...values, error: "Invalid Access Code" })
                        }
                    }
                    else {
                        setValues({ ...values, error: "Invalid Access Code" })
                    }
                })
        }
    }



    const classes = useStyles();
    return (
        <div className='d-flex w-100 h-100'>
            <div className='w-35'>
                <img src={PegogLogo} style={{ height: '100vh' }} alt='pegogLogo' />
            </div>
            {/* <div style={{ width: '33%' }}> */}
            <div className='ap-login-container'>
                <div className='ap-login-field-container box-shadow' style={{ padding: '15px 30px' }}>
                    <div className='ap-login-welcome-font-color tablet-gothic' style={{ marginTop: 'unset' }}>Register</div>
                    {values.error && <div className='text-center tablet-gothic ap-error-msg'>
                        {values.error}
                    </div>}
                    <div>
                        <TextField
                            id="filled-required"
                            placeholder="Enter your Full Name"
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.filledRoot,
                                    focused: classes.filledRootFocused,
                                    input: classes.filledInput
                                }
                            }}
                            onChange={handleChange('fullname')}
                            style={{ margin: '15px 0' }}
                            classes={{
                                root: classes.formControlRoot
                            }}
                            InputLabelProps={{
                                classes: {
                                    filled: classes.filledEmailLabel,
                                    shrink: classes.filledEmailLabelShrink
                                }
                            }}
                        />
                        <TextField
                            placeholder="Enter your Email ID"
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.filledRoot,
                                    focused: classes.filledRootFocused,
                                    input: classes.filledInput
                                }
                            }}
                            onChange={handleChange('email')}
                            style={{ margin: '15px 0' }}
                            classes={{
                                root: classes.formControlRoot
                            }}
                            InputLabelProps={{
                                classes: {
                                    filled: classes.filledEmailLabel,
                                    shrink: classes.filledEmailLabelShrink
                                }
                            }}
                        />
                        <FormControl variant="filled" style={{ margin: '15px 0' }} classes={{
                            root: classes.formControlRoot
                        }}>
                            {/* <InputLabel htmlFor="filled-adornment-password" classes={{
                                filled: classes.filledPasswordLabel,
                                shrink: classes.filledPasswordLabelShrink
                            }}>Password</InputLabel> */}
                            <FilledInput
                                placeholder='Password'
                                id="filled-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                disableUnderline
                                // style={{ margin: '15px 0' }}
                                classes={{
                                    root: classes.filledRoot,
                                    focused: classes.filledRootFocused,
                                    input: classes.filledInput
                                }}

                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <TextField
                            placeholder="Access Code"
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.filledRoot,
                                    focused: classes.filledRootFocused,
                                    input: classes.filledInput
                                }
                            }}
                            value={values.accesscode}
                            onChange={handleChange('accesscode')}
                            style={{ margin: '15px 0' }}
                            classes={{
                                root: classes.formControlRoot
                            }}
                            InputLabelProps={{
                                classes: {
                                    filled: classes.filledEmailLabel,
                                    shrink: classes.filledEmailLabelShrink
                                }
                            }}
                        />
                    </div>
                    <div>
                        <Button className='login-button-ap' onClick={handleRegister} classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}>
                            Register
                        </Button>
                    </div>
                    <div className='text-center tablet-gothic'>
                        Have an account? <Link to='/auth/login' className='tablet-gothic' style={{ color: '#BA1F00', textDecoration: 'underline', fontWeight: '600' }} >Login</Link>
                    </div>


                    {/* <div className="dashboard-cntr"> */}
                    {/* </div> */}
                    {/* </div> */}

                </div>
                <div className='ap-gray-color-1 tablet-gothic text-center' style={{ fontSize: '0.8rem', margin: '7px 0px' }}>
                    By clicking on register, you agree to the terms of use, privacy policy, and cookie policy
                </div>
            </div>
            <div style={{ width: '30%', position: 'relative' }}>
                <img src={IllumineLogo} style={{ position: 'absolute', bottom: '8%', right: '5%' }} alt='IllumineLogo' />
            </div>
            {
                auth && auth.isLoggedIn &&
                <Redirect
                    to={{
                        pathname: "/application"
                    }}
                />
            }
        </div>
    )
}
