// import React, { useEffect } from 'react';
// import Button from '@material-ui/core/Button';
// import { updateFlag } from "../../containers/application/redux_actions";
// import WalkThrough from './assets/Walkthrough-Pedgog-2.svg'
// import { Link } from 'react-router-dom';
// import { useState } from 'react';

// function IntroScreen(userId, user_token) {
//     const [screen, setScreen] = useState(<div>Loading...</div>);
//     const clickHandle = () => {
//         let user_Id = userId.userId;
//         let user_token = userId.user_token
//         updateFlag({ user_Id, user_token });
//     }
//     const customButton_1 = {
//         backgroundColor: '#ffbc36',
//         cursor: 'pointer',
//         position: 'absolute',
//         bottom: '30px',
//         right: '30px'
//     }

//     setTimeout(() => {
//         setScreen(<div style={{ height: '100%', width: '100%', position: 'relative' }}>
//             <img src={WalkThrough} style={{ height: '100%' }} />
//             <Button style={customButton_1} onClick={clickHandle} >
//                 <Link style={{ textDecoration: 'none', color: 'black' }} to={`/application/explore`}>Begin to Explore!</Link>
//             </Button>
//         </div>)
//     }, 2000)


//     return (
//         <div style={{ height: '100%', width: '100%'}}>
//             {
//                 screen
//             }
//         </div>
//     )
// }

// export default IntroScreen;

import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import { updateFlag } from "../../containers/application/redux_actions";


function IntroScreen({ parentClose, parentDisplay, auth }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        parentDisplay();
    };

    const justClose = () => {
        setOpen(false);
    }

    const handleClose = async () => {
        let user_Id = auth.user_uid;
        let user_token = auth.user_token
        updateFlag({ user_Id, user_token });
        setOpen(false);
        parentClose();
    };

    const customButton_1_small = {
        backgroundColor: '#ffbc36',
        cursor: 'pointer',
        fontSize: '12px'
    }

    const customButton_1 = {
        backgroundColor: '#ffbc36',
        cursor: 'pointer',
    }

    const StyledDialog = withStyles({
        paper: {
            background: '#fff9c2 !important',
            padding: '10px',
            borderRadius: '20px',
            lineHeight: '1.5'
        }
    })(Dialog);

    return (
        <div>
            <Button style={customButton_1_small} onClick={handleClickOpen}>
                Skip/Next
            </Button>
            <StyledDialog
                open={open}
                keepMounted
                onClose={justClose}
            >
                <div style={{ padding: '24px', textAlign: 'center' }}>
                    <div style={{ fontSize: '42px', marginBottom: '15px' }}>You are good to go!</div>
                    <ThumbUpTwoToneIcon style={{ fontSize: '150px', color: 'grey' }} />
                    <DialogActions>
                        <Button style={customButton_1} onClick={handleClose}>
                            Begin and Explore!
                    </Button>
                    </DialogActions>
                </div>
            </StyledDialog>
        </div>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(IntroScreen);
