import React
// , { useState, useEffect,useRef } 
from 'react';
// import { useSelector } from 'react-redux';
import { Radar } from 'react-chartjs-2';
// import { getEngagement } from '../../containers/application/redux_actions'


export default ({ width, height, engagementData, manageToolData, isFullScreen, data, responses, currentEngagement, uniqueStudentsName, typeOfEngagment }) => {
    // const user_token = useSelector((state) => state.auth.user_token)
    // useEffect(() => {
    //     getEngagement(user_token, data.engagement)
    // }, [])

    // const toolTipref = useRef('chartjs-tooltip')
    // const responses = useSelector(state => state.app.response[data.engagement]);
    // const currentEngagement = useSelector(state => state.app.currentEngagment);

    // const uniqueStudentsName = useSelector(state => state.app.uniqueStudentsName[data.engagement]);

    // console.log(responses)
    // console.log(currentEngagement)
    // console.log(currentEngagement.answers[0]['dropDown_2'].map(item_2 => {
    //     console.log(responses[item_2])
    //     return responses[item_2] && responses[item_2].length > 0 ? responses[item_2].reduce((total, res) => {
    //         console.log(res)
    //         if (res.tags[0] === item_2) {
    //             console.log(total)
    //             return total + 1
    //         }
    //         return total
    //     }) : console.log('yes')
    // }));
    // let value = ''
    let options_radar = '', toolTipValues = [];
    // let labels = []
    let _data = {}
    const dataSetColor = {
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ]
    }

    if (responses && currentEngagement && currentEngagement.answers[0]) {

        // const toolTipValues = [
        //     ['user1',],
        //     ['user2', 'user3'],
        //     ['user6', 'user7', 'user8']
        // ];
        // const toolTipValues = Object.keys(responses).map((item, i) => {
        //     labels = [...labels, item];
        //     _data = [..._data, responses[item].length]
        //     return responses[item];
        // })
        // const a=responses

        _data = {
            labels: currentEngagement.answers[0]['dropDown_2'].map(item => item),
            datasets: currentEngagement.answers[0]['dropDown_1'].map((item_1, i) => {
                return {
                    label: item_1,
                    data: currentEngagement.answers[0]['dropDown_2'].map((item_2, index_2) => {

                        if (responses[item_2] === undefined) return 0
                        let totalNoResponse = 0;
                        responses[item_2].forEach(res => {

                            if (res.tags[0] === item_1) {
                                // if (!toolTipValues[i]) {
                                //     toolTipValues
                                //     if (toolTipValues[i][index_2]) {
                                //         toolTipValues[i][index_2] = [...toolTipValues[i][index_2], res.user]
                                //     }
                                //     else toolTipValues[i][index_2] = [res.user]
                                // }
                                // else {
                                //     toolTipValues[i] = [];
                                // }
                                if (!toolTipValues[i]) toolTipValues[i] = [];
                                if (!toolTipValues[i][index_2]) toolTipValues[i][index_2] = [res.user]
                                else toolTipValues[i][index_2] = [...toolTipValues[i][index_2], res.user]
                                totalNoResponse += 1
                            }
                        })
                        return totalNoResponse
                    }),
                    fill: true,
                    backgroundColor: dataSetColor.backgroundColor[i],
                    borderColor: dataSetColor.borderColor[i]
                }
            }),

        }

    }

    console.log(toolTipValues)
    // const __data = {
    //     labels: ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5', 'Thing 6'],
    //     datasets: [
    //         {
    //             label: '# of Votes',
    //             data: [2, 9, 3, 5, 2, 3],
    //             backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //             borderColor: 'rgba(255, 99, 132, 1)',
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    options_radar = {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            line: {
                borderWidth: 3
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    if (!toolTipValues[tooltipItem.datasetIndex]) return
                    return toolTipValues[tooltipItem.datasetIndex][tooltipItem.index];
                    // percentage
                    //data['datasets'][0]['data'][tooltipItem['index']] + '%';
                },

                title: function (tooltipItem, data) {
                    return;
                }
            },
            custom: function (tooltip) {
                if (!tooltip) return;
                // disable displaying the color box;
                tooltip.displayColors = false;
            },
        },

        scale: {
            ticks: {
                callback: function () { return "" },
                backdropColor: "rgba(0, 0, 0, 0)"
            }
        }
    }

    return (
        <div style={{ width, height }} >
            <Radar data={_data} options={options_radar} redraw />
        </div >
    );
}