import React from 'react';
import "./ModuleCard.css";
import { Link } from 'react-router-dom';

let ModuleCardExplore = ({ title, description, moduleId, mainFilePath, uniqueAboutIt }) => {
  return <div key={moduleId} className="module-card">
    {
      mainFilePath ?
        <img src={mainFilePath} alt="logo" style={{ width: '274px', height: "154px" }}></img>
        : <img src={"http://43.229.227.26:81/acsportal/explore1.jpg"} alt="logo"></img>
    }

    <div className="-card-body">
      {/* <div className="subtitle" style={{ marginBottom: 6, color: "#28282c" }}>{title}</div> */}
      <h6 style={{ margin: '10px 0px', color: "#4d4d4d" }}>{description}</h6>
      <p style={{ fontSize: '13px', lineHeight: '22px', color: '#4d4d4d' }}>{uniqueAboutIt}</p>
      <div className="cFoot">
        <div className='button-text'>
        </div>
        <div className='button-text'>
          <Link className='button-label' to={`/application/explore/${moduleId}`} >Explore</Link>
        </div>
      </div>
    </div>
  </div>
};

export default ModuleCardExplore;