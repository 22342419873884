import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import logo from "./assets/logo.svg";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./LoginForm.css";

const styles = {
  root: {
    display: "flex",
  },
  label: {
    backgroundColor: "white",
    padding: "0px 5px",
  },
};

class Loginform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      fields: {},
      errors: {},
      label: "",
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }

  handleValidation(eml, pwd) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }
    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" });
    }

    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf("@");
      let lastDotPos = eml.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          eml.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          eml.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email can not be empty" });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { classes } = this.props;
    const toRegister = (props) => <Link to="/auth/register" {...props} />;
    const toForgotPassword = (props) => (
      <Link to="../auth/forgotpassword" {...props} />
    );
    return (
      <div className="flexBox fbox">
        <div className="leftBoxLogin">
          <img src="/assets/logo.svg" alt="Illumine" />
          <div className="d-block ">
            <p className="lfFont my-3">
              Your pathway to delivering <strong>transformative</strong>{" "}
              learning & coaching experiences!{" "}
            </p>
          </div>
        </div>
        <div className="rightBoxLogin">
          <form>
            <h4 style={{ textAlign: "left", margin: "20px 0px" }}>
              {" "}
              Welcome Back!
            </h4>
            <div className={"loginform-cntr"}>
              {this.state.label ? (
                <div className="errorMsg">
                  <label> {this.state.label} </label>
                </div>
              ) : null}

              <FormControl fullWidth className={"input-cntr"}>
                {/* <InputLabel htmlFor="adornment-password">Enter Registered Email ID</InputLabel>
              <Input
                id="user-email"
                label="Your Registered Email ID"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              /> */}

                <TextField
                  className="mb-3"
                  id="user-email"
                  label="Your Registered Email ID"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                />
              </FormControl>
              <FormControl
                fullWidth
                className={"input-cntr"}
                variant="outlined"
              >
                {/* <InputLabel htmlFor="adornment-password">Enter Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              /> */}

                <InputLabel className={classes.label} htmlFor="user-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  className="mb-3"
                  id="user-password"
                  autoComplete="off"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Typography
                className="errorMsg"
                variant="caption"
                gutterBottom
                align="center"
                color="secondary"
              >
                {this.props.authMsg}
              </Typography>

              <FormControl fullWidth className={"input-cntr"}>
                {/* <a href="#" className="errorMsg text-right my-3" variant="caption" gutterBottom>
                    Forgot Password?
                </a> */}
                <Button className={classes.button}>
                  <Typography
                    className="errorMsg text-right"
                    variant="caption"
                    gutterBottom
                    color="primary"
                    component={toForgotPassword}
                  >
                    Forgot Password?
                  </Typography>
                </Button>

                <Button
                  variant="raised"
                  color="primary"
                  className="loginBtn"
                  onClick={() => {
                    let { email, password } = this.state;
                    let chkVal = this.handleValidation(email, password);
                    let flag = 0;
                    if (chkVal) {
                      this.props.loginAttempt({
                        email,
                        password,
                        flag,
                        successCallBack: (usr) => {                          
                          if (usr.message) {
                            this.setState({ label: usr.message });
                          }
                          let orgid = usr.user.organizationid;
                          console.log(usr.user)
                          let token = usr.tokens.access.token;
                          this.props
                            .getOrganizationAttempt({ orgid, token })
                            .then((result) => {
                              // let conductflag = result.conductflag;
                            });
                        },
                        failureCallback: () => {
                          //alert('failed');;
                        },
                      });
                    } else {
                      this.setState({
                        label:
                          "Either Email or Password is incorrect. Please try again.",
                      });
                    }
                  }}
                >
                  Login
                </Button>
              </FormControl>
              <div className="login-link-cntr d-block my-3">
                <Typography className="" variant="caption" gutterBottom>
                  Are you new to PedGog? Click here to
                </Typography>
                <Button className={classes.button}>
                  <Typography
                    className="errorMsg"
                    variant="caption"
                    gutterBottom
                    color="primary"
                    component={toRegister}
                  >
                    Register
                  </Typography>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Loginform.propTypes = {
  classes: PropTypes.object.isRequired,
  loginAttempt: PropTypes.func,
  getOrganizationAttempt: PropTypes.func,
  authMsg: PropTypes.string,
};

export default withStyles(styles)(Loginform);
