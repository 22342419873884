import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EdiText from 'react-editext';
import CloseIcon from '@material-ui/icons/Close';

import './style.css'

export default ({ width, height, engagementData, manageToolData, isFullScreen, data }) => {

    useEffect(() => {
        if (!engagementData || !engagementData[data.id])
            manageToolData({
                toolId: data.id, content: data.drag_drop_deckId,
            })
    }, [data.id])



    const handleSave = (item, key, newValue) => {
        let index = engagementData[data.id][key].findIndex((i) => i.text === item.text)
        if (index >= 0) {
            manageToolData({
                toolId: data.id,
                content: {
                    ...engagementData[data.id],
                    [key]: [
                        ...engagementData[data.id][key].slice(0, index),
                        { text: newValue, category: key },
                        ...engagementData[data.id][key].slice(index + 1)
                    ]
                }
            })
        }
    }



    const handleRemove = (item, key) => {
        let items = engagementData[data.id][key].filter((i) => i.text !== item.text)
        manageToolData({
            toolId: data.id,
            content: {
                ...engagementData[data.id],
                [key]: items
            }
        })
    }



    const handleDragEnd = ({ destination, source }) => {
        if (!destination || !source)
            return
        if (destination.index === source.index && destination.droppableId === source.droppableId)
            return
        const itemCopy = engagementData[data.id][source.droppableId][source.index]
        let temp = engagementData[data.id]
        temp[source.droppableId].splice(source.index, 1)
        temp[destination.droppableId].splice(destination.index, 0, itemCopy)
        manageToolData({
            toolId: data.id, content: temp
        })
    }


    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <DragDropContext onDragEnd={handleDragEnd} >
                    {
                        Object.keys(data.drag_drop_deckId).map((dragDeckId) => {
                            return <Droppable key={dragDeckId} droppableId={dragDeckId}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={`tct-scrollable-darkgray sma-identifying-activity-deck-${dragDeckId} overFlowYAuto pos-relative`}
                                    >
                                        {engagementData[data.id] && engagementData[data.id][dragDeckId] && engagementData[data.id][dragDeckId].map((item, index) => {

                                            return <Draggable
                                                key={item.text}
                                                draggableId={item.text}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    // className='sma-identifying-activity-deck-with-helper-text-container'
                                                    >
                                                        <div className={`sma-identifying-activity-deck-textbox sma-identifying-activity-deck-textbox-${dragDeckId}-border pos-relative`}    >
                                                            {/* <EdiText
                                                                viewContainerClassName='my-custom-view-wrapper0'
                                                                type='textarea'
                                                                inputProps={{
                                                                    className: 'edit-text-textbox-scrollable-darkgray h-100',
                                                                    style: {
                                                                        minWidth: '100%',
                                                                        resize: 'none',
                                                                        minHeight: '100%',
                                                                    }
                                                                }}
                                                                hideIcons
                                                                cancelOnEscape
                                                                submitOnUnfocus
                                                                editOnViewClick
                                                                value={item.text}
                                                                onSave={(newValue) => handleSave(item, dragDeckId, newValue)} >
                                                            </EdiText>
                                                            <CloseIcon
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    color: 'gray'
                                                                }}
                                                                onClick={() => handleRemove(item, dragDeckId)}></CloseIcon> */}

                                                            {item.text}

                                                        </div>
                                                        {dragDeckId !== 'Activity_List' && item.category !== dragDeckId && <div className='d-flex justify-content-flex-end sma-identifying-activity-deck-helper-text-hide'>
                                                            <div className='sma-identifying-activity-deck-helper-text'>Are you sure?</div>
                                                        </div>}
                                                    </div>
                                                )}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        })
                    }
                </DragDropContext>
            </div>
        </div>

    )
}