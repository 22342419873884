import { signOut } from "../auth/redux_actions";
import store from "../../Redux_Store";
// import _ from "lodash";
import Config from "../../config"

const config = {
  // apiurl: "http://localhost:5000/v1/"
  apiurl: Config.API_URL,
  // apiurl: "https://beta.pedgog.in:3000/v1/"
};


export function getFormValues({ sessionId, token }) {
  return (dispatch) => {
    return fetch(config.apiurl + "learningtool/getFormValues/" + sessionId, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((results) => {
        console.log("get form values ==> ", results);
        // const first = _.head(results);

        // const { fieldData } = first;
        results.map((result) => {
          Object.entries(result.fieldData).forEach((field) => {
            const toolId = field[0];
            const text = field[1];

            const blocks = text.split("\n");
            // console.log("blocks ==> ", blocks);
            const _blocks = blocks.map((b) => {
              return { text: b, type: "unordered-list-item" };
            });

            const payload = {
              content: { blocks: _blocks, entityMap: {} },
              toolId: toolId,
            };
            console.log("blocks ==> ", payload);
            dispatch(getFormValuesSuccess(payload));
          });

          // dispatch(getFormValuesSuccess(result.fieldData));
        });
        // return dispatch(getFormValuesSuccess(fieldData));
      })
      .catch((err) => { });
  };
}

function getFormValuesSuccess(data, token) {
  return { type: "MANAGE_TOOL_DATA", payload: data };
  // return { type: "GETFORMVALUES_SUCCESS", payload: data };
}

export function getPrepareModulesAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "GETPREPAREMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "subscribe/getModuleForUser/" + user.userid, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + user.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((userModules) => {
        return dispatch(
          getpreparemoduleAttemptSuccess(userModules, user.token)
        );
      })
      .catch((err) => dispatch(getpreparemoduleAttemptFailure(err)));
  };
}

function getpreparemoduleAttemptSuccess(data, token) {
  return { type: "GETPREPAREMODULE_SUCCEEDED", data };
}

function getpreparemoduleAttemptFailure(err) {
  return { type: "GETPREPAREMODULE_FAILURE" };
}

export function getContinueConductModules(user) {
  return (dispatch) => {
    dispatch({ type: "GETCONTINUECONDUCTMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "session/continue/" + user.userid, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + user.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((continueModules) => {
        return dispatch(
          getContinueConductModuleAttemptSuccess(continueModules, user.token)
        );
      })
      .catch((err) => dispatch(getContinueConductModuleAttemptFailure(err)));
  };
}

function getContinueConductModuleAttemptSuccess(data, token) {
  return { type: "GETCONTINUECONDUCTMODULE_SUCCEEDED", payload: data };
}

function getContinueConductModuleAttemptFailure(err) {
  return { type: "GETCONTINUECONDUCTMODULE_FAILURE" };
}

export function getModuleDetails(val) {
  return (dispatch) => {
    dispatch({ type: "GETMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "appmodule/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(getModuleDetailsSuccess(val.id, json));
      })
      .catch((err) => dispatch(getModuleDetailsFailure(err)));
  };
}

function getModuleDetailsSuccess(id, data) {
  return { type: "GETMODULE_SUCCEEDED", payload: { id, data } };
}

function getModuleDetailsFailure(err) {
  console.log(err);
  return { type: "GETPREPAREMODULE_FAILURE" };
}

export function getRoadmapforModule(val) {
  return (dispatch) => {
    dispatch({ type: "GETROADMAPFORMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "roadmap/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(getRoadmapforModuleSuccess(val.id, json));
      })
      .catch((err) => dispatch(getRoadmapforModuleFailure(err)));
  };
}

function getRoadmapforModuleSuccess(id, data) {
  return { type: "GETROADMAPFORMODULE_SUCCEEDED", payload: { id, data } };
}

function getRoadmapforModuleFailure(err) {
  console.log(err);
  return { type: "GETROADMAPFORMODULE_FAILURE" };
}

export function getTagsAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "GETTAG_ATTEMPT", payload: true });
    return fetch(config.apiurl + "tag", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + user.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((tags) => {
        return dispatch(gettagAttemptSuccess(tags, user.token));
      })
      .catch((err) => dispatch(gettagAttemptFailure(err)));
  };
}

function gettagAttemptSuccess(data, token) {
  return { type: "GETTAG_SUCCEEDED", data };
}

function gettagAttemptFailure(err) {
  return { type: "GETTAG_FAILURE" };
}

export function getUserAttempt(data) {
  return (dispatch) => {
    dispatch({ type: "GETUSER_ATTEMPT", payload: true });
    return fetch(config.apiurl + "users/getUserById/" + data.userid, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((sessions) => {
        if (sessions.code === 401) return dispatch(signOut());
        return dispatch(getUserSuccess(data.userid, sessions));
      })
      .catch((err) => {
        console.log("get User  => error ===");
        console.log(err);
        dispatch(getUserFailure(err));
      });
  };
}

function getUserSuccess(id, data) {
  return { type: "GETUSER_SUCCEEDED", payload: { id, data } };
}
function getUserFailure(err) {
  console.log(err);
  return { type: "GETUSER_FAILURE" };
}
export function getExploreModulesAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "GETEXPLOREMODULE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "appmodule", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + user.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((allModules) => {
        return dispatch(getexploremoduleAttemptSuccess(allModules, user.token));
      })
      .catch((err) => dispatch(getexploremoduleAttemptFailure(err)));
  };
}

function getexploremoduleAttemptSuccess(data, token) {
  return { type: "GETEXPLOREMODULE_SUCCEEDED", data };
}

function getexploremoduleAttemptFailure(err) {
  return { type: "GETEXPLOREMODULE_FAILURE" };
}

export function updateUsedCountAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "UPDATEUSEDCOUNT_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "accesscode/updateUsedCount/" + user.accesscode,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + user.token,
          accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((userModules) => {
        return dispatch(updateUsedCountSuccess(userModules, user.token));
      })
      .catch((err) => dispatch(updateUsedCountFailure(err)));
  };
}

function updateUsedCountSuccess(data, token) {
  return { type: "UPDATEUSEDCOUNT_SUCCEEDED", data };
}

function updateUsedCountFailure(err) {
  return { type: "UPDATEUSEDCOUNT_FAILURE" };
}

export function updatePercentage(data) {
  return (dispatch) => {
    dispatch({ type: "UPDATEPERCENTAGE_ATTEMPT", payload: true });
    fetch(config.apiurl + "progress/createProgress", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: JSON.stringify({
        userId: data.user_uid,
        moduleId: data.moduleId,
        progressType: data.typ,
        roadmapId: data.rrid,
        learningToolsId: data.lid,
        activeContent: "",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        updatePercentageSuccess(dispatch, response);
      })
      .catch((err) => {
        console.log("error ===");
        console.log(err);
      });
  };
}

export function updatePercentageOLD(data) {
  return (dispatch) => {
    dispatch({ type: "UPDATEPERCENTAGE_ATTEMPT", payload: true });
    return fetch(config.apiurl + "progress/createProgress", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: JSON.stringify({
        userId: data.user_uid,
        moduleId: data.moduleId,
        progressType: data.typ,
        roadmapId: data.rrid,
        learningToolsId: data.lid,
      }),
    })
      .then((response) => response.json())
      .then((resp) => {
        updatePercentageSuccess(dispatch, resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

const updatePercentageSuccess = (dispatch, data) => {
  dispatch({ type: "UPDATEPERCENTAGE_SUCCEEDED", payload: data });
  //successCallBack(data);
};

export async function addFormValues(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "ADDFORMVALUES_ATTEMPT", payload: true });
  var raw = JSON.stringify({
    sessionId: data.sessionId,
    fieldData: data.fieldData,
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "learningtool/addFormValues", requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "ADDFORMVALUES_SUCCEEDED", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "ADDFORMVALUES_FAILURE" });
    return console.log('error', error);
  }
}

export const resetLoginPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};

export const setErrMsg = (el) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const preparePage = () => {
  return (dispatch) => {
    dispatch({ type: "PREPARE_PAGE" });
  };
};

export function createSession(data, successCallBack) {
  return (dispatch) => {
    dispatch({ type: "CREATESESSION_ATTEMPT", payload: true });
    // fetch("http://localhost:5001/v1/session", {
    fetch(config.apiurl + "session", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: JSON.stringify({
        sessionName: data.sessionName,
        moduleId: data.moduleId,
        coachId: data.coach_id,
        noOfStudents: "0",
        sessionType: data.sessionType,
        batchId: data.batchId
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (data.getFormValues)
          getFormValuesForBatchAndModule({ user_token : data.user_token, moduleId: data.moduleId, batchId: data.batchId })
        dispatch({ type: "CREATESESSION_SUCCEEDED", payload: response });
        if (successCallBack)
          successCallBack(response)
        // return response
      })
      .catch((err) => {
        console.log("error ===");
        console.log(err);
      });
    // return
  };
}

export function createExperience(data) {
  return (dispatch) => {
    dispatch({ type: "CREATEEXPERIENCE_ATTEMPT", payload: true });
    fetch(config.apiurl + "experiences/createexperiences", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.token,
        accept: "application/json",
      },
      body: JSON.stringify({
        description: data.description,
        moduleId: data.moduleId,
        shareById: data.userId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        createExperienceSuccess(dispatch, response);
      })
      .catch((err) => {
        console.log("error ===");
        console.log(err);
      });
  };
}

const createExperienceSuccess = (dispatch, data) => {
  dispatch({ type: "CREATEEXPERIENCE_SUCCEEDED", payload: data });
};

export function getSessionForCoachAttempt(user) {
  return (dispatch) => {
    dispatch({ type: "GETSESSIONFORCOACH_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "session/getSessionForCoachId/" + user.userid,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + user.token,
          accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((sessions) => {
        return dispatch(getSessionForCoachSuccess(sessions, user.token));
      })
      .catch((err) => {
        console.log("get session for coach  => error ===");
        console.log(err);
      });
  };
}

function getSessionForCoachSuccess(data, token) {
  return { type: "GETSESSIONFORCOACH_SUCCEEDED", data };
}

export function getSessionForSessionIdAttempt(data) {
  return (dispatch) => {
    dispatch({ type: "GETSESSIONFORSESSIONID_ATTEMPT", payload: true });
    return fetch(config.apiurl + "session/" + data.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((sessions) => {
        return dispatch(getSessionForSessionIdSuccess(sessions));
      })
      .catch((err) => {
        console.log("get session for coach  => error ===");
        console.log(err);
      });
  };
}

function getSessionForSessionIdSuccess(data) {
  return { type: "GETSESSIONFORSESSIONID_SUCCEEDED", data };
}

export function getAssessmentForSessionIdAttempt(data) {
  return (dispatch) => {
    dispatch({ type: "GETASSESSMENTFORSESSIONID_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "assessment/getAssessmentForSessionId/" + data.id,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + data.token,
          accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((sessions) => {
        return dispatch(getAssessmentForSessionIdSuccess(sessions));
      })
      .catch((err) => {
        console.log("get assessment for session  => error ===");
        console.log(err);
      });
  };
}

function getAssessmentForSessionIdSuccess(data) {
  return { type: "GETASSESSMENTFORSESSIONID_SUCCEEDED", data };
}

export function updateFlag(data) {
  if (data) {
    fetch(config.apiurl + "users/updateUserTooltipData/" + data.user_Id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data1) => {
        console.log(data1);
      });
  }
}

// Piyush Actions Below
export const p_getModuleDetails = ({ id, token }) => {
  return fetch(config.apiurl + "roadmap/" + id, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "bearer " + token,
      accept: "application/json",
    },
  });
};

export function getRoadmapDetails(val) {
  return (dispatch) => {
    dispatch({ type: "GETROADMAP_ATTEMPT", payload: true });
    return fetch(config.apiurl + "roadmapforid/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch({
          type: "GETROADMAP_SUCCEEDED",
          payload: { id: val.id, data: json },
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(val.id);
        dispatch({ type: "GETROADMAP_FAILURE" });
      });
  };
}

export function getLearningToolDetails(val) {
  return (dispatch) => {
    dispatch({ type: "GETLEARNINGTOOL_ATTEMPT", payload: true });
    return fetch(config.apiurl + "learningtool/" + val.id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch({
          type: "GETLEARNINGTOOL_SUCCEEDED",
          payload: { id: val.id, data: json },
        });
      })
      .catch((err) => dispatch({ type: "GETLEARNINGTOOL_FAILURE" }));
  };
}

export function getOTP(data) {
  return (dispatch) => {
    if (!data.testType) data.testType = "pre";
    dispatch({ type: "GETOTP_ATTEMPT", payload: true });
    fetch(config.apiurl + "session/getOTP", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: JSON.stringify({
        sessionId: data.sessionId,
        testType: data.testType,
        coachId: data.coachId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        return dispatch({
          type: "GETOTP_SUCCEEDED",
          payload: { data: response },
        });
      })
      .catch((err) => {
        console.log("error ===", err);
      });
  };
}

export function getAllGenericModules(val) {
  return (dispatch) => {
    dispatch({ type: "GETALLGENERICMODULES_ATTEMPT", payload: true });
    return fetch(config.apiurl + "appmodule/getAllGenericModules", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: "bearer " + val.user_token,
        accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch({
          type: "GETALLGENERICMODULES_SUCCEEDED",
          payload: { data: json },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "GETALLGENERICMODULES_FAILURE" });
      });
  };
}

export function showSearchBarRedux() {
  return (dispatch) => {
    dispatch({
      type: "SHOW_SEARCH_BAR",
    });
  };
}

// export function handleSearchText(val) {
//   return (dispatch) => {
//     dispatch({
//       type: "HANDLE_SEARCH_TEXT",
//       payload: { data: val }
//     })
//   }
// };

export function getGenericModuleUnits(val) {
  return async (dispatch) => {
    dispatch({ type: "GETGENERICMODULEUNITS_ATTEMPT", payload: true });
    try {
      const res = await fetch(
        config.apiurl + "appmodule/getGenericModuleById/" + val.genericModuleId,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + val.user_token,
            accept: "application/json",
          },
        }
      );
      const json = await res.json();
      return dispatch({
        type: "GETGENERICMODULEUNITS_SUCCEEDED",
        payload: { data: json[0] },
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: "GETGENERICMODULEUNITS_FAILURE" });
    }
  };
}

export function manageToolData(data) {
  return (dispatch) => {
    dispatch({
      type: "MANAGE_TOOL_DATA",
      payload: { toolId: data.toolId, content: data.content },
    });
  };
}

export async function getDataFromFile(data) {
  store.dispatch({ type: "GET_DATA_FROM_FILE_ATTEMPT", payload: true });
  try {
    const res = await fetch(data.url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    const response = await res.json();
    store.dispatch({ type: "GET_DATA_FROM_FILE_SUCCESS", payload: response });
    return response;
  } catch (err) {
    store.dispatch({ type: "GET_DATA_FROM_FILE_ERROR", payload: true });
    console.log("get User  => error === ", err);
  }
}

export async function uploadProject(data) {
  store.dispatch({ type: "UPLOAD_PROJECT_ATTEMPT", payload: true });
  try {
    const res = await fetch(config.apiurl + "project/", {
      method: "POST",
      headers: {
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
      body: data.formdata,
    });
    const response = await res.json();

    if (response.code) throw Error(response.message);
    store.dispatch({ type: "UPLOAD_PROJECT_SUCCESS", payload: response });
    return response;
  } catch (err) {
    store.dispatch({ type: "UPLOAD_PROJECT_ERROR", payload: true });
    console.log("error === ", err);
  }
}

export async function getProject(data) {
  store.dispatch({ type: "GET_PROJECT_ATTEMPT", payload: true });
  try {
    const res = await fetch(config.apiurl + "project/", {
      method: "GET",
      headers: {
        Authorization: "bearer " + data.user_token,
        accept: "application/json",
      },
    });
    const response = await res.json();
    store.dispatch({ type: "GET_PROJECT_SUCCESS", payload: response });
  } catch (err) {
    store.dispatch({ type: "GET_PROJECT_ERROR", payload: true });
    console.log("error === ", err);
  }
}

export function makeOffline(offline) {
  store.dispatch({ type: "MAKE_OFFLINE", payload: offline });
}

export const getEngagement = async (token, engagementId) => {
  try {
    store.dispatch({ type: "GETENGAGEMENT_ATTEMPT" });
    const response = await fetch(`${Config.API_URL}student/engagement/${engagementId}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + token,
          accept: "application/json",
        },
      }
    );
    const response_1 = await response.json();
    if (response_1.code === 401) store.dispatch(signOut());
    else if (response.code) throw Error(response.message);
    store.dispatch({ type: "GETENGAGEMENT_SUCCESS", payload: response_1 });
  } catch (err) {
    store.dispatch({ type: "GETENGAGEMENT_FAILURE" });
    console.log("error ===", err);
  }
}

export function getDashboardConfig(token, userId) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(config.apiurl + "dashboard/" + userId, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}


export async function getOrganisationByParentId(data) {
  console.log(data)
  store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_ATTEMPT", payload: true });
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  try {
    const response = await fetch(config.apiurl + "organization/parent/" + data.parentOrgId, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_SUCCESS", payload: response_1 });
  } catch (error) {
    store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_FAILED", payload: true }); console.log('error', error);
  }

}
export async function updateUserByUserId(data, val) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "UPDATE_USER_BY_ID_ATTEMPT", payload: true });
  console.log(val)
  var raw = JSON.stringify({
    ...val
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "users/" + data.userId, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "UPDATE_USER_BY_ID_SUCCESS", payload: response_1 });
  } catch (error) {
    store.dispatch({ type: "UPDATE_USER_BY_ID_FAILURE" });
    return console.log('error', error);
  }
}


export function studentResponseDelete(data) {
  store.dispatch({ type: "STUDENT_RESPONSE_DELETE", payload: data })
}

export const toggleLanguage = () => {
  store.dispatch({ type: "CHANGE_LANGUAGE" })
}

export const logEvent = (token, coachId, eventName, eventData) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    coachId,
    eventName,
    eventData
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  // fetch("http://localhost:5001/v1/teacherEvent/", requestOptions)
  fetch(config.apiurl + "teacherEvent/", requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}


export async function createBatch(data, val) {
  var myHeaders = new Headers();
  console.log(val)
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "CREATE_BATCH_ATTEMPT", payload: true });
  var raw = JSON.stringify({
    // "batchName": "Mech Batch 1",
    // "stream": "Computer Science",
    // "semester": "Semester 1",
    "coachId": data.userId,
    ...val
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "batch/", requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "CREATE_BATCH_SUCCESS", payload: response_1 });
    return response_1
  } catch (error) {
    store.dispatch({ type: "CREATE_BATCH_FAILED" });
    console.log('error', error);
  }
}

export async function createOrganization(data, val) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "CREATE_ORGANISATION_ATTEMPT" });
  var raw = JSON.stringify({
    ...val,
    coachId: data.userId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "organization", requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "CREATE_ORGANISATION_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "CREATE_ORGANISATION_FAILURE" });
    return console.log('error', error);
  }
}


export async function createTakeaway(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };
  try {
    store.dispatch({ type: "GET_TAKEAWAY_ATTEMPT", payload: true });
    const response = await fetch(`${config.apiurl}takeaway/${data.sessionId}?access=${data.access}&activeContent=${data.activeContentId}&learningTool=${data.learningToolId}`, requestOptions);
    // const response = await fetch("http://localhost:8000/v1/takeaway/" + data.sessionId + `?access=${data.access}&activeContent=${data.activeContentId}&learningTool=${data.learningToolId}`, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_TAKEAWAY_SUCCESS", payload: response_1 });
    return response_1
  } catch (error) {
    store.dispatch({ type: "GET_TAKEAWAY_FAILED", payload: true }); console.log('error', error);
    return error
  }
}

export async function startPostSession(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${data.user_token}`);
  store.dispatch({ type: "START_POST_SESSION_ATTEMPT" });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return await fetch(`${config.apiurl}session/postSession/${data.sessionId}`, requestOptions)
    .then(response => {
      store.dispatch({ type: "START_POST_SESSION_SUCCESS" });
      return response.json();
    })
    .catch(err => {
      store.dispatch({ type: "START_POST_SESSION_FAILURE" });
      console.log(err)
    })
}

export function getAssessmentReport({ user_token, sessionId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${config.apiurl}analytics/assessment/${sessionId}`, requestOptions)
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}

export function getRatingReport({ user_token, sessionId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${config.apiurl}analytics/rating/${sessionId}`, requestOptions)
    .then(response => response.json())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}


export function getAttendence({ user_token, sessionId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  // fetch(`http://localhost:5001/v1/session/attendence/${sessionId}`, requestOptions)
  fetch(`${config.apiurl}session/attendence/${sessionId}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if (result.code)
        store.dispatch(signOut())
      else
        store.dispatch({ type: "STUDENT_ATTEND", payload: result })
    })
}


export async function getBatchDetails(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "GET_BATCH_ATTEMPT" });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "batch/" + data.user_id, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_BATCH_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "GET_BATCH_FAILURE" });
    return console.log('error', error);
  }
}

export async function getBatchStudents(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "GET_BATCH_STUDENTS_ATTEMPT" });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}/student`, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_BATCH_STUDENTS_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "GET_BATCH_STUDENTS_FAILURE" });
    return console.log('error', error);
  }
}

export async function markBatchAttendance(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "MARK_BATCH_ATTENDANCE_ATTEMPT" });

  var raw = JSON.stringify({
    sessionId: data.sessionId,
    students: data.students
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}/markAttendance`, requestOptions);
    if (response.status === 200)
      store.dispatch({ type: "MARK_BATCH_ATTENDANCE_SUCCESS" });
    else
      throw response
  } catch (error) {
    store.dispatch({ type: "MARK_BATCH_ATTENDANCE_FAILURE" });
    return console.log('error', error);
  }
}

export function resumeSession(data) {
  if (data)
    store.dispatch({ type: "RESUME_SESSION_SUCCESS", payload: data });
}

export async function updateBatchStudentList(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "UPDATE_BATCH_STUDENT_LIST_ATTEMPT" });
  console.log(data)
  var raw = JSON.stringify({
    students: data.students
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}/student`, requestOptions);
    const response_1 = await response.json();
    console.log(response_1)
    store.dispatch({ type: "UPDATE_BATCH_STUDENT_LIST_SUCCESS" });
    data.successCallBack(response_1)
  } catch (error) {
    store.dispatch({ type: "UPDATE_BATCH_STUDENT_LIST_FAILURE" });
    data.failureCallBack(error)
  }
}


export async function deleteBatch(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "DELETE_BATCH_ATTEMPT" });

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `batch/${data.batchId}`, requestOptions);
    if (response.status === 200)
      store.dispatch({ type: "DELETE_BATCH_SUCCESS" });
    else
      throw response
  } catch (error) {
    store.dispatch({ type: "DELETE_BATCH_FAILURE" });
    return console.log('error', error);
  }
}

export async function getFormValuesForBatchAndModule(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "GET_FORM_DATA_BATCH_ATTEMPT" });

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + `learningtool/getFormValuesForBatchAndModule/${data.batchId}/${data.moduleId}`, requestOptions);
    const response_1 = await response.json();
    if (response_1.code)
      throw response_1
    store.dispatch({ type: "GET_FORM_DATA_BATCH_SUCCESS", payload: response_1 });
    store.dispatch({ type: "MANAGE_TOOL_DATA", payload: { prevSession: true, data: response_1 } });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "GET_FORM_DATA_BATCH_FAILURE" });
    return console.log('error', error);
  }
}

export function storeSelectedBatch(data) {
  if (data)
    store.dispatch({ type: "STORE_SELECTED_BATCH", payload: data });
}

export function getBatchForCoachAndModule({ user_token, coachId, moduleId }) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${user_token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`${config.apiurl}batch/getBatchForCoachAndModule/${coachId}/${moduleId}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if (result?.code)
        return;
      store.dispatch({ type: "GET_BATCH_SUCCESS", payload: result });
      return result;
    })
    .catch(error => console.log('error', error));
}