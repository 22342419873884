import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { createSession, getBatchDetails, getFormValuesForBatchAndModule } from "../../containers/application/redux_actions";
import Config from "../../config"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const StyledDialog = withStyles({
  // '@global': {
  //   '.MuiPaper-rounded': {
  //     borderRadius: '15px',
  //     padding: '30px'
  //   },
  //   '.MuiOutlinedInput-root': {
  //     borderRadius: '15px',
  //   },
  //   '.MuiDialog-paper': {
  //     width: '600px'
  //   },
  //   '.MuiTypography-body1': {
  //     lineHeight: '2'
  //   }
  // },
  paper: {
    width: '600px',
    borderRadius: '15px',
    padding: '30px'
  }
})(Dialog);


const DialogTitle = withStyles(styles)((props) => {

  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const customButton_1 = {
  backgroundColor: '#ffbc36',
  cursor: 'pointer'
}

// const customButton_2 = {
//   backgroundColor: '#8D8D8D',
//   cursor: 'pointer'
// }

const customLink = {
  color: '#8D8D8D',
  textDecoration: 'none',
  margin: '0px 15px'
}

const acpStyle = {
  backgroundColor: "#7cba00",
  padding: "8px 16px",
  color: "#fff",
  fontSize: ".8rem",
  fontWeight: 500
}

function ConductDialog({ moduleId, title, description, createSession, user_token, coach_id, app, testType, buttonText, buttonTextComponent, auth, rootClassName, buttonClassName }) {
  const [open, setOpen] = React.useState(false);
  const [textData, setText] = React.useState('');
  const [textError, setTextError] = React.useState(false);

  const date = new Date();
  const currentDate = date.toLocaleDateString();
  const currentTime = date.toLocaleTimeString();
  // var currentTime = new Date();
  const [batchName, setBatchName] = React.useState('');
  // var currentOffset = currentTime.getTimezoneOffset();

  // var ISTOffset = 330;   // IST offset UTC +5:30 

  // var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

  // // ISTTime now represents the time in IST coordinates
  // // console.log(ISTTime, currentDate)
  // var hoursIST = ISTTime.getHours()
  // var minutesIST = ISTTime.getMinutes()


  useEffect(() => {
    open && (auth?.user?.email === "srishti.j@illumine.in" || auth?.user?.email === "rajkumar@illumine.in") && getBatchDetails({ user_token, user_id: coach_id })
  }, [open])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  }

  const handleDropDownBatchChange = (event) => {
    setText(event.target.value);
    setBatchName(event.nativeEvent.target.textContent);
  }

  const clickEvent = async () => {
    if (textData.length > 0) {
      (auth?.user?.email === "srishti.j@illumine.in" || auth?.user?.email === "rajkumar@illumine.in")
        ? await createSession({ user_token, moduleId, coach_id, sessionName: batchName, sessionType: testType, batchId: textData, getFormValues: true })
        : await createSession({ user_token, moduleId, coach_id, sessionName: textData, sessionType: testType });
    }
    else {
      setTextError(true);
    }
  }

  return (
    <div className={rootClassName}>
      <div className={`${buttonClassName} button-text`} onClick={handleClickOpen}>
        {buttonTextComponent ? buttonTextComponent : <div className='button-label' style={Config.ACP_SHOW && window.location.host === Config.ACP_HOST ? acpStyle : {}}>
          {buttonText ? buttonText : "conduct"}
        </div>
        }
      </div>
      <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Creating a New Session
        </DialogTitle>
        <DialogContent>
          {/* {((Config.AP_SHOW && window.location.host === Config.AP_HOST) || auth && auth.orgDetails && auth.orgDetails.organizationname === 'Asian Paints') ? */}
          {(auth?.user?.email === "srishti.j@illumine.in" || auth?.user?.email === "rajkumar@illumine.in") ?
            <FormControl variant="outlined" fullWidth style={{ marginBottom: '5%' }} error={textError}>
              <InputLabel id="moduleName-select-label">Select Batch</InputLabel>
              <Select
                labelId="Select-Batch-label"
                id="Select-Batch-select"
                value={textData}
                onChange={handleDropDownBatchChange}
                label="Select Batch"
              >
                <MenuItem value="Select" disabled>
                  <em>Select</em>
                </MenuItem>
                {
                  app?.batchDetails?.map(batch => <MenuItem key={batch?._id} value={batch?._id}>{batch?.batchName}</MenuItem>)
                }
              </Select>
            </FormControl>
            :
            <TextField required error={textError} style={{ width: '100%', marginBottom: '25px' }} label="Enter Session Name" variant="outlined" onKeyUp={handleChange} />}
          <div style={{ display: 'flex', width: '100%', marginBottom: '25px' }}>
            <div style={{ width: '50%', paddingRight: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>COURSE:</div>
              <Typography>{title}</Typography>
            </div>
            <div style={{ width: '50%', paddingLeft: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>TOPIC:</div>
              <Typography>{description}</Typography>
            </div>
          </div>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Date: </span> {currentDate}
          </Typography>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Start Time: </span> {currentTime}
          </Typography>

          {/* <span style={{ fontWeight: 'bold' }}>Estimated End Time: </span>{app.moduleDetails[moduleId].estimatedTime.hours} hrs {app.moduleDetails[moduleId].estimatedTime.minutes} mins */}
          {Config.ACP_SHOW && window.location.host === Config.ACP_HOST ? "" : <div>
            <span style={{ fontWeight: 'bold' }}>Estimated End Time: </span>
            {app.moduleDetails[moduleId].estimatedTime && app.moduleDetails[moduleId].estimatedTime.hours ? app.moduleDetails[moduleId].estimatedTime.hours + ' hr ' : ''}
            {app.moduleDetails[moduleId].estimatedTime && app.moduleDetails[moduleId].estimatedTime.minutes} mins
          </div>}
        </DialogContent>
        <DialogActions>
          {Config.ACP_SHOW && window.location.host === Config.ACP_HOST ? "" : <Typography><Link style={customLink} to={`/application/prepare/${moduleId}`} >No I'd like to Prepare</Link></Typography>}
          {textData.length > 0 ?
            <Link style={{ textDecoration: 'none', color: 'black' }} to={`/application/conduct/${moduleId}`} >
              <Button style={customButton_1} onClick={clickEvent}>create session</Button></Link>
            :
            <Button style={customButton_1} onClick={clickEvent} >
              create session
            </Button>}
        </DialogActions>
      </StyledDialog>
    </div>
  );
}


const mapStateToProps = state => ({
  app: state.app,
});


const mapDispatchToProps = dispatch => {
  return {
    createSession: data => dispatch(createSession(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConductDialog);