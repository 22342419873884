import React from 'react';
// import './style.css'
import TextEditor from '../../../../Tools/TextEditor/TextEditor';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {
    // const top = ['36%', '69%'];
    // const right = ['44%', '25%', '4.5%'];
    const top = ['27%', '32%', '27%', '69%', '69%', '69%'];
    const right = ['39%', '21%', '4%'];
    const textBoxHeight = ['24%', '20%', '25%', '21%', '21%', '21%'];


let descriptionSize = '12px';
// let subTitleSize = '12px';
if (isFullScreen) {
    // subTitleSize = '16px'
    descriptionSize = '16px';
}

return (
    <div style={{ width, height }}>
        <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
            {
                // data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill in...'
                //     className='transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll font-style-italic'
                //     style={{ fontSize: descriptionSize, position: 'absolute', width: '17%', height: '15.5%', top: top[Math.floor(i / (data.textBoxId.length / 2))], right: right[i % (data.textBoxId.length / 2)] }}
                //     value={engagementData[data.id] ? engagementData[data.id][textBoxId] ? engagementData[data.id][textBoxId] : '' : ''}
                //     onChange={(event) => manageToolData({
                //         toolId: data.id, content: {
                //             ...engagementData[data.id],
                //             [textBoxId]: event.target.value
                //         }
                //     })}
                // />)
            }
            {

                data.textBoxId.map((textBoxId, i) =>
                    <div key={textBoxId} className='pos-absolute d-flex roboto' style={{ fontSize: descriptionSize, lineHeight: descriptionSize, width: '16%', height: textBoxHeight[i], top: top[i], right: right[i % (data.textBoxId.length / 2)] }}>
                        <TextEditor id={data.id} initialData={engagementData[data.id] ? engagementData[data.id][data.textBoxId[i]] : null}
                            manageToolData={manageToolData}
                            scrollable='tct-scrollable-darkgray'
                            preFilledTextBoxData={['Fill in...']}
                            // textEditorClass='UPTC_CS_Square-textEditorClass'
                            wrapperClass='text-editor-WIAC_diff_in_the_lives'
                            toolBarClass='CD-CNP-textbox-toolbar'
                            hideToolbar={true}
                            oneHierarchy={true}
                            textBoxId={textBoxId}
                            engagementData={engagementData}
                        />
                    </div>
                )
            }
        </div>
    </div >
);
}