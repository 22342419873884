import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
// import { getEngagement } from '../../containers/application/redux_actions'
// import { startEngagement, stopEngagement } from '../../containers/application/Socket';
import Interaction from "../../containers/application/Interaction"
import './style.css';
import store from "../../Redux_Store"

function etah(event) {    // event type and event handler (etah) 
    switch (event.eventName) {
        case "START":
            console.log("Hello")
            break;
        case "STOP":
            // Interaction.stopEngagement()
            console.log("Bye")
            break
        case "SUBMIT":
            console.log("Submit")
            store.dispatch({ type: "STUDENT_RESPONSE", payload: { userId: event.senderId, data: event.eventData, engagementId: event.engagementId } })
            break;

        // case "TEAM_BOARD_SUBMIT":
        //     console.log("TEAM_BOARD_SUBMIT")
        //     store.dispatch({ type: "TEAM_BOARD_STUDENT_RESPONSE", payload: { userId: event.senderId, data: event.eventData, engagementId: event.engagementId } })
        //     break;
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            console.log(event.eventData)
            break;
        default:
            console.log(event)
    }
}
export default ({ engagementData,isFullScreen, data, engagementStatus, assignGroup }) => {
    const responses = useSelector(state => state.app.response[data.engagement]);
    const students = useSelector(state => state.app.uniqueStudentsName[data.engagement]);
    const userId = useSelector(state => state.auth.user.id)
    const otp = useSelector(state => state.app.otpData.otp)
    const studentJoinedList = useSelector(state => state.app.studentJoinedList)
    const createdSession = useSelector(state => state.app.createdSession)
    const status = useSelector(state => state.app.engagementStatus[data.engagement])

    let responseCount = 0;
    if (responses) {
        Object.keys(responses).map((item) => {
            responseCount += responses[item].length;
        })
    }
    console.log(responses, students, responseCount)
    useEffect(() => {
        Interaction.initalize(data.engagement, etah, "Coach", userId, "Student", otp)
    }, [])
    const changeButtonType = async () => {
        if (createdSession && otp) {
            store.dispatch({ type: 'START_AND_STOP_ENGAGEMENT', payload: { engId: data.engagement, status } })
            if (!engagementStatus || !engagementStatus[data.engagement]) {
                await Interaction.startEngagement()
                Interaction.sendEvent("START", data.engagement)
                if (assignGroup && engagementData[data.sessionType]) {
                    let groupData = {};
                    Object.keys(engagementData[data.sessionType]).map((key) => {
                        if (engagementData[data.sessionType][key].item && Object.keys(engagementData[data.sessionType][key].item).length > 0) {
                            // groupData[key] = engagementData[data.sessionType][key].item
                            groupData[key] = Object.keys(engagementData[data.sessionType][key].item).map(_item => engagementData[data.sessionType][key]['item'][_item].userId)
                        }
                    })
                    
                    Interaction.sendEvent("ASSIGN_GROUP", { ...groupData })
                }

            }
            else {
                Interaction.sendEvent("STOP", data.engagement)
                Interaction.stopEngagement()
            }
        }
    }
    // let noOfStudentsSize = '26px';
    // let studentRespondedSize = '9px'
    if (isFullScreen) {
        // noOfStudentsSize = '42px';
        // studentRespondedSize = '12px'
    }

    const totalStudent =  Object.keys(studentJoinedList).length ? Object.keys(studentJoinedList).length : 0
    console.log(totalStudent, students, responseCount, studentJoinedList)
    return (
        <div className='w-100 h-100 d-flex' >
            {
                !engagementStatus || !engagementStatus[data.engagement] ? <Button variant="contained" className='live-session-start-button w-40 h-100' onClick={changeButtonType}>
                    START
                </Button> : <Button variant="contained" className='live-session-stop-button w-40 h-100' onClick={changeButtonType} >
                    STOP

                    {/* <div style={{lineHeight:studentRespondedSize, marginTop:'9%'}}>
                        <div style={{ fontSize: noOfStudentsSize }}>{students && students.length ? students.length : 0}</div>
                        <div style={{ fontSize: studentRespondedSize, marginTop:'19%' }}>Student Responded</div>
                    </div> */}
                </Button>
            }
            {/* {
                studentJoinedList && studentJoinedList.length > 0 && studentJoinedList.map(name => <div key={name}> 🟢 {name}</div>)
            } */}
            <div className='d-flex w-60 justify-space-evenly text-center align-items-end'>
                {totalStudent > 0 && <div>
                    <div>
                        {totalStudent}
                    </div>
                    <div style={{ fontSize: '0.8rem' }}>Student(s)</div>
                </div>}

                {/* {responseCount > 0 && <div>
                    <div>{responseCount}</div>
                    <div>Response(s)</div>
                </div>} */}
            </div>

        </div>
    );
}