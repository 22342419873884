import React, { useState, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { CircularProgressbar } from 'react-circular-progressbar';
import Collapsible from 'react-collapsible';
import Chevron from '@material-ui/icons/KeyboardArrowDownRounded';
import 'react-circular-progressbar/dist/styles.css';
import './drawer.css';

import {
  useSelector,
  //  useDispatch
} from 'react-redux';

import { addFormValues } from '../../containers/application/redux_actions';
import { convertFromRaw } from 'draft-js';
import Config from '../../config'

let DrawerMenu = ({ isLoaded, data, userModuleState, units, isDrawerCalled,
  updateDrawerCalled, roadmaps, currentRoadmap, currentUnit, updatecurrentUnit, setcurrentScene,
  uid, token, drawerVariant, typPC, availableModules, percomps, setCurrentRoadmap }) => {
  let handleDrawerToggle = () => { updateDrawerCalled(false) };
  let [sectionTimes, updateSectionTime] = useState({});
  let percomp = 0;


  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch();

  const convertEditorTypeData = () => {
    let convertedData = {};
    Object.keys(app.engagementData).map((textBox, i) => {
      convertedData[textBox] = app.engagementData[textBox].blocks ? convertFromRaw(app.engagementData[textBox]).getPlainText() : app.engagementData[textBox];
    })
    return convertedData;
  }

  const toolDataSave = () => {
    let sessionId = app.createdSession.id;
    let user_token = auth.user_token;
    let fieldData = convertEditorTypeData();
    addFormValues({ sessionId, user_token, fieldData })
  }


  useEffect(() => {
    updateSectionTime({});
    Object.keys(data.organiser && data.organiser.roadmaps ? data.organiser.roadmaps : {}).map(sectionId => {
      let section = { LearningTools: {} }
      section = roadmaps && roadmaps[sectionId] ? roadmaps[sectionId] : section;
      sectionTimes[sectionId] = 0;
      Object.keys(section.LearningTools).map((uId, i) => {
        sectionTimes[sectionId] = parseInt(sectionTimes[sectionId]) + parseInt(units[uId] ? units[uId].estimatedTime : 0);
        updateSectionTime(sectionTimes);
      })
    });
  }, [units]);
  let a = isLoaded ? <Drawer
    variant={drawerVariant}
    anchor={'left'}
    open={isDrawerCalled}
    onClose={handleDrawerToggle}
    classes={{
      root: 'drawer-root',
      paper: 'drawer-paper',
      docked: 'drawer-docked',
    }}
    ModalProps={{
      container: document.getElementById('linear-navigator'),
      keepMounted: true,
      // BackdropProps: { {} }
    }}
    SlideProps={{}}
    PaperProps={{ elevation: 2 }}
  >
    <div>
      <div style={{ padding: '1rem', display: 'flex', backgroundColor: '#fafafa' }}>
        <div style={{ color: '#c6922a', padding: '5px 8px 0px 0px', width: 35 }}>

          {typPC === "Prepare"
            ? availableModules.forEach(module => {
              if (data.id === module.moduleId) {
                percomp = module.percentageCompleted;
              }
            })
            : availableModules.forEach(module => {
              if (data.id === module.moduleId) {
                percomp = module.percentageCompletedConduct;
              }
            })
          }

          {percomps > 0
            ? <CircularProgressbar
              value={percomps}
              strokeWidth={16}
              styles={{ path: { stroke: '#c6922a' } }}
            />
            : <CircularProgressbar
              value={percomp}
              strokeWidth={16}
              styles={{ path: { stroke: '#c6922a' } }}
            />
          }

          {/* 
          <CircularProgressbar
            value={userModuleState && userModuleState.percentageCompleted
              ? userModuleState.percentageCompleted
              : 0}
            strokeWidth={16}
            styles={{ path: { stroke: '#c6922a' } }}
          /> */}
        </div>
        <div>
          <h2>{data.description}</h2>
          <p>{data.title}</p>
        </div>
      </div>
      <div>{Object.keys(data.organiser && data.organiser.roadmaps ? data.organiser.roadmaps : {}).map((sectionId) => {
        let section = { LearningTools: {} }
        section = (roadmaps && roadmaps[sectionId]) ? roadmaps[sectionId] : section;
        let header = <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h3>{section.roadMapTitle}</h3>
            <div className='subheading'>{sectionTimes[sectionId]} mins</div>
          </div>
          <Chevron className='chevron' /></div>
        return <Collapsible
          key={sectionId}
          trigger={header}
          className={(currentRoadmap === sectionId) ? 'active-section' : 'section-blk'}
          open={Config.ACP_SHOW && window.location.host === Config.ACP_HOST}
        >
          {Object.keys(section.LearningTools).map((uId, i) => {
            return <div className={(currentUnit === uId) ? 'tool-cntr active' : 'tool-cntr inactive'}
              key={uId} onClick={() => { setcurrentScene(0); setCurrentRoadmap(sectionId); updatecurrentUnit(uId); typPC !== "Prepare" && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave(); }}>
              <div className='tool-id'>{i + 1}</div>
              <div style={{ paddingTop: 2 }}>
                <h6>{units[uId] ? units[uId].title : ''}</h6>
                <div className='subtitle'>{parseInt(units[uId] ? units[uId].estimatedTime : '')} mins</div>
              </div>
            </div>
          })}
        </Collapsible>
      })}</div>
    </div>
  </Drawer> : null;
  return a
}

export default DrawerMenu;