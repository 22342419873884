import React, {
    // useState, useEffect, 
    useRef
} from 'react';
// import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
// import { getEngagement } from '../../containers/application/redux_actions'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.register(ChartDataLabels);


export default ({ width, height, engagementData, manageToolData, isFullScreen, data, responses, currentEngagement, uniqueStudentsName, typeOfEngagment }) => {
    // const user_token = useSelector((state) => state.auth.user_token)
    // useEffect(() => {
    //     getEngagement(user_token, data.engagement)
    // }, [])

    const toolTipref = useRef('chartjs-tooltip')
    // const responses = useSelector(state => state.app.response[data.engagement]);
    // const currentEngagement = useSelector(state => state.app.currentEngagment);

    // const uniqueStudentsName = useSelector(state => state.app.uniqueStudentsName[data.engagement]);

    console.log(responses)
    console.log(currentEngagement)
    let value = '', options = '', toolTipValues = [];
    let labels = [], _data = []

    if (responses) {

        // const toolTipValues = [
        //     ['user1',],
        //     ['user2', 'user3'],
        //     ['user6', 'user7', 'user8']
        // ];
        // const toolTipValues = Object.keys(responses).map((item, i) => {
        //     labels = [...labels, item];
        //     _data = [..._data, responses[item].length]
        //     return responses[item];
        // })
        // const a=responses
        if (typeOfEngagment === 'DropDown') {
            toolTipValues = currentEngagement && Object.keys(currentEngagement.answers[0]).map((item, i) => {
                labels = [...labels, item.text];
                _data = [..._data, responses[item.text] === undefined ? 0 : responses[item.text].length]
                return responses[item.text] && responses[item.text].map((t) => {
                    return t.user
                })
            })
        }
        else {
            toolTipValues = currentEngagement && currentEngagement.answers.map((item, i) => {
                labels = [...labels, item.text];
                _data = [..._data, responses[item.text] === undefined ? 0 : responses[item.text].length]
                return responses[item.text] && responses[item.text].map((t) => {
                    return t.user
                })
            })
        }


        value = {
            labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: _data || null,
                    backgroundColor: [
                        '#FFE0E6',
                        '#FFF5DD',
                        '#DBF2F2',
                        '#B595EC',
                        '#ccb3b8',
                        '#A8CFF5',
                        '#E6EC97',
                        '#F7D4D4',
                        '#8C8C8C',
                        '#F7D4D4',
                        '#DBF2F2',
                        '#B595EC',
                        '#ccb3b8',
                        '#E6EC97',
                        '#F7D4D4',
                    ],

                    barPercentage: 1,
                    categoryPercentage: 1,


                },
            ],

        }
        //  eng1-5
        // total responce -5,
        // total n0 stu-5,
        //  eng2-5
        // total responce -3,
        // total n0 stu-5,
        //  eng3-
        // total responce -4,
        // total n0 stu-5,

        options = {

            legend: {
                display: false //This will do the task
            },
            maintainAspectRatio: false,

            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        let datasets = ctx.chart.data.datasets;
                        //console.log(datasets)
                        //console.log(value)
                        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                            //let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                            // console.log(sum)
                            //console.log(ctx.chart.data.labels[ctx.dataIndex])
                            //console.log(ctx)
                            let res = ctx.chart.data.datasets[0].data[ctx.dataIndex]

                            // let user = Object.values(responses)

                            //console.log(ctx.chart.data.datasets[0].data.length)
                            let percentage = uniqueStudentsName && Math.round((res / uniqueStudentsName.length) * 100) + "%";


                            return percentage;
                        } else {
                            return;
                        }
                    },
                    font: {
                        weight: "normal"
                    },

                    color: "#000000",
                    anchor: 'end',
                    align: 'top'

                }
            },
            scales: {
                xAxes: [{
                    barThickness: 30,
                    maxBarThickness: 100,
                    barPercentage: 1,
                    gridLines: {

                        drawOnChartArea: false,

                    },

                }],
                yAxes: [{
                    gridLines: {
                        display: false,

                    },

                    ticks: {
                        display: false,
                        beginAtZero: true,

                    },
                }]
            },

            responsive: true,

            title: {
                display: true,
                text: ''
            },
            tooltips: {

                callbacks: {
                    label: function (tooltipItem, data) {
                        return toolTipValues[tooltipItem.index];
                        // percentage
                        //data['datasets'][0]['data'][tooltipItem['index']] + '%';
                    },

                    title: function (tooltipItem, data) {
                        return;
                    }
                },
                custom: function (tooltip) {
                    if (!tooltip) return;
                    // disable displaying the color box;
                    tooltip.displayColors = false;
                },

            }
        }
    }

    console.log(value)

    return (
        <div style={{ width, height }}>
            <Bar data={value} ref={toolTipref} options={options} redraw />
        </div>
    );
}