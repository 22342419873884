const envSettings = window;

export default {
  API_URL: envSettings.API_URL,
  SOCKET_URL: envSettings.SOCKET_URL,  
  ACP_HOST: envSettings.ACP_HOST,
  ACP_SHOW: envSettings.ACP_SHOW,
  AP_HOST: envSettings.AP_HOST,
  AP_SHOW: envSettings.AP_SHOW,
  MyAPP_URL: envSettings.MyAPP_URL
}