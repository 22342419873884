import React from 'react';
import TakeAway_Button from '../../../Tools/TakeAway_Button';
import TextEditor from '../../../Tools/TextEditor/TextEditor';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                <div className={'Sustainable-Success-Win-Vs-Lose-textbox'} style={{ fontSize: isFullScreen ? '18px' : '12px', lineHeight: isFullScreen ? '18px' : '12px' }}>
                    <TextEditor id={data.textBoxId} initialData={engagementData[data.textBoxId] || null}
                        manageToolData={manageToolData} placeholder='Fill here...'
                        preFilledTextBoxData={data.preFilledTextBoxData[data.textBoxId]}
                        scrollable='tct-scrollable-darkgray'
                        textEditorClass='UPTC_CS_Square-textEditorClass'
                        wrapperClass='text-editor-WIAC_diff_in_the_lives'
                        toolBarClass='CD-CNP-textbox-toolbar'
                    />
                </div>
                {/* <div style={{
                    position: "absolute", width: '50%',
                    height: '15%'
                }}>
                    <TakeAway_Button currentUnit={currentUnit} data={data} engagementData={engagementData} />
                </div> */}
            </div>
        </div >
    );
}