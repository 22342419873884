import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import APConductDialog from '../ConductDialog'

const conductLink = <div>conduct &nbsp;&nbsp; <span style={{ fontSize: 'xx-large' }}>&#129122;</span></div>

export default function ModuleCard({ index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app }) {
    const titleRef = useRef('');

    return (
        <div key={description} className='d-flex w-45 h-60' style={{ padding: '20px' }} >
            <div className='ap-program-count-num'>{index < 9 ? ('0' + (index + 1)) : (index + 1)}</div>
            <div className='ap-module-card-box-shadow ap-module-card overflow-hidden'>
                <div ref={titleRef} className='ap-module-card-title-container'>
                    <div className='ap-module-card-title'>{description}</div>
                </div>
                {/* <div style={{ margin: '20px -10px' }}> */}
                <div className='ap-module-card-img' style={{ height: `calc(100% - ${titleRef.current.clientHeight}px)` }}>
                    <img src={mainFilePath} className='w-100 h-100' alt='mainFilePath' />
                    <div className="ap-module-card-overlay">
                        <div className="ap-text tablet-gothic">
                            <Link className='ap-prepare-hover ap-prepare-hover-color' to={`/application/prepare/${moduleId}`} style={{ color: 'white' }} >prepare &nbsp;&nbsp; <span style={{ fontSize: 'xx-large' }}>&#129122;</span></Link>                            

                            <APConductDialog
                                moduleId={moduleId}
                                title={title}
                                description={description}
                                mainFilePath={mainFilePath}
                                user_token={user_token}
                                coach_id={coach_id}
                                testType="pre"
                                auth={auth}
                                app={app}
                                buttonTextComponent={conductLink}
                                buttonClassName='ap-prepare-hover ap-conduct-hover-color h-100 color-white'
                                rootClassName='h-50'
                            />
                            {/* <ConductDialog
                                moduleId={moduleId}
                                title={title}
                                description={description}
                                user_token={user_token}
                                coach_id={coach_id}
                                testType="pre"
                                buttonTextComponent={conductLink}
                                buttonClassName='ap-prepare-hover ap-conduct-hover-color h-100'
                                rootClassName='h-50'
                                auth={auth}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* </div> */}
        </div>
    )
}
