import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';


function TextEditor({ id, manageToolData, initialData, hideToolbar, placeholder, wrapperClass, textEditorClass, toolBarClass, readOnly, scrollable, isFullScreen, preFilledTextBoxData, oneHierarchy, twoHierarchy, textBoxId, engagementData, toolName }) {
    let a = initialData ? convertFromRaw(initialData) : null;
    let b = a ? EditorState.createWithContent(a) : preFilledTextBoxData ?
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(preFilledTextBoxData.map(ele => `<li>${ele}</li>`).toString().replace(/,/g, ''))
            ))
        : EditorState.createEmpty();
  
    const [editorState, setEditorState] = useState(b);
    useEffect(() => {
        setEditorState(b);
    }, [textBoxId, id, isFullScreen]);
    
    let onEditorStateChange = (_editorState) => {
        const contentState = _editorState.getCurrentContent();
        if (oneHierarchy) {

            manageToolData({
                toolId: id, content: {
                    ...engagementData[id],
                    [textBoxId]: convertToRaw(contentState)
                }
            })
            setEditorState(_editorState);
        }
        else if (twoHierarchy) {

            manageToolData({
                toolId: toolName, content: {
                    ...engagementData[toolName],
                    [id]: {
                        ...engagementData[toolName][id],
                        [textBoxId]: convertToRaw(contentState)
                    }
                }
            })
            setEditorState(_editorState);
        }
        else {
            manageToolData({ toolId: id, content: convertToRaw(contentState) });
            setEditorState(_editorState);
        }
    };

    const scrollableCss = scrollable ? scrollable : 'scrollable';

    // function myBlockStyleFn(contentBlock) {
    //     console.log(contentBlock);
    //     // const type = contentBlock.getType();
    //     // if (type === 'blockquote') {
    //     //     return 'superFancyBlockquote';
    //     // }
    //     return 'ISE_Growing_Deep_text_style'
    // }

    return (

        <Editor
            editorState={editorState}
            toolbarClassName={toolBarClass ? toolBarClass : "textblk-toolbox"}
            wrapperClassName={wrapperClass ? `${wrapperClass}` : 'text-editor-wrapper'}
            editorClassName={textEditorClass ? `${textEditorClass} ${scrollableCss}` : `textblk-editor ${scrollableCss}`}
            toolbar={{ options: ['list'], list: { className: toolBarClass ? toolBarClass : "textblk-toolbox" } }}
            toolbarHidden={hideToolbar ? hideToolbar : false}
            placeholder={placeholder ? placeholder : ''}
            onEditorStateChange={onEditorStateChange}
            readOnly={readOnly ? readOnly : false}
        // customBlockRenderFunc={myBlockStyleFn}
        // blockStyleFn={myBlockStyleFn}
        />
    )
}

export default TextEditor;
