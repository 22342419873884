import React, { useRef, useState, useEffect } from 'react'
import LeftNav from '../LeftNav'
import './style.css'
import APModuleCard from './ModuleCard'
import ModuleLogo from './assets/programvisual.svg';
import LoadingSpinner from './assets/Dual Ring-1s-79px.svg';
import Icon from '@mdi/react';
import { mdiSwapVertical } from '@mdi/js';
import SwitchPanel from '../SwitchPanel';

export default ({ app, auth, menuItems, menuItems1 }) => {

    const availableModules = menuItems.filter((el, i) => {
        if (el.dataSet.length > 0)
            return true
        return false
    })

    function populateModules(dataArray) {
        return dataArray.map((el, i) => {
            if (el) {
                let { title, description, id, mainFilePath, uniqueAboutIt } = el;
                return <APModuleCard
                    key={i}
                    index={i}
                    title={title}
                    description={description}
                    mainFilePath={mainFilePath}
                    moduleId={id}
                    uniqueAboutIt={uniqueAboutIt}
                    user_token={auth.user_token}
                    coach_id={auth.user_uid}
                    auth={auth}
                    app={app}
                />
            }
            return null;
        })
    }

    const [mIndex, setMindex] = useState(0);
    const [mSwitch, setMswitch] = useState(false);
    const [mSwitchSpin, setMSwitchSpin] = useState(false);
    const [tagImg, setTagImg] = useState({})
    const userNameRef = useRef(null)
    const executeScroll = () => userNameRef.current.scrollIntoView({ behavior: 'smooth' })


    const handleSwitch = (index) => {
        if (typeof (index) === 'number') {
            setMindex(index);
        }
        setMswitch(!mSwitch)
        setMSwitchSpin(true)
        setTimeout(() => {
            setMSwitchSpin(false)
        }, 250)
        executeScroll();
    }

    const tagImgHelperFn = () => {
        if (availableModules.length > 0) {
            availableModules && availableModules[mIndex] && availableModules[mIndex].dataSet && availableModules[mIndex].dataSet && availableModules[mIndex].dataSet[0].tag[0] && availableModules[mIndex].dataSet[0].tag[0].imgUrlPortrait ?
                setTagImg({
                    loading: false,
                    src: availableModules[mIndex].dataSet[0].tag[0].imgUrlPortrait
                }) :
                setTagImg({
                    loading: false,
                    src: ModuleLogo
                })
        }
        else
            setTagImg({
                loading: true,
                src: LoadingSpinner
            })
    }

    useEffect(() => {
        tagImgHelperFn();
    }, [availableModules.length, mIndex])


    return (
        <div className={"dashboard-cntr"}>
            <LeftNav />
            <div className='d-flex w-100 h-100'>
                <div className={`w-25 h-100 ap-program-leftImg-container  ${!mSwitch ? 'overflow-hidden' : 'overFlowYAuto'} tct-scrollable-darkgray`} >
                    <div className='d-flex flex-column h-100 w-100'>
                        <div>
                            <div ref={userNameRef} className='ap-program-username' >
                                Welcome back! {auth.user.fullname}
                            </div>
                            <div className='d-flex w-100'>
                                <div className='ap-program-title'>
                                    {availableModules.length > 0 && availableModules[mIndex].title}
                                </div>
                                <div className='w-20'>
                                    {availableModules.length > 1 && <Icon path={mdiSwapVertical}
                                        size={1.5}
                                        color={mSwitch ? '#BA1F00' : '#bababa'}
                                        spin={mSwitchSpin ? 0.5 : 0}
                                        onClick={handleSwitch}
                                    />}
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: '1', position: 'relative', width: '100%' }}>
                            {mSwitch ? <div>
                                <div className='ap-switch-module-list' style={{ textDecoration: 'underline', cursor: 'unset' }}>SWITCH</div>
                                {
                                    availableModules.length > 0 && availableModules.map((module, i) => {
                                        if (i === mIndex)
                                            return null;
                                        return <div key={`SWITCH + ${i}`} className='ap-switch-module-list' onClick={() => handleSwitch(i)}>{module.title}</div>
                                    })
                                }
                            </div> :
                                // <div className='w-100' style={{ height: tagImg.loading ? '25%' : '100%' }}>
                                <img src={tagImg.src} className={`w-100 ap-program-leftImg ${tagImg.loading ? 'h-25 bottom-50' : ''}`} alt='ModuleLogo' />
                                // </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='w-75 h-100 tct-scrollable-darkgray overFlowYAuto' style={{ padding: '1.5rem', backgroundColor: '#F7F7F7' }}>
                    <div className='ap-program-count'>Program Modules ({availableModules.length > 0 && availableModules[mIndex].dataSet.length})</div>
                    <div className="d-flex flex-wrap w-100 h-100">
                        {availableModules.length > 0 && populateModules(availableModules[mIndex].dataSet)}
                    </div>
                </div>
            </div>
            {/* {menuItems && menuItems.map((el, i) => {
                if (el.dataSet.length > 0) {
                    return <div key={`menu-${i}`} className='d-flex w-100 h-100'>
                        <div className='w-30 h-100 ap-program-leftImg-container overflow-hidden' >
                            <div className='ap-program-username' >
                                Welcome back! {auth.user.fullname}
                            </div>
                            <div className='ap-program-title'>
                                {el.title}
                            </div>
                            <div className='w-100' style={{ height: '75%', position: 'relative', top: '10%' }}>
                                <img src={ModuleLogo} className='w-100 h-100' alt='ModuleLogo' />
                            </div>
                        </div>
                        <div className='w-70 h-100 tct-scrollable-darkgray overflow-auto' style={{ padding: '1.5rem', backgroundColor: '#F7F7F7' }}>
                            <div className='ap-program-count'>Program Modules ({el.dataSet.length})</div>
                            <div className="d-flex flex-wrap w-100">
                                {populateModules(el.dataSet)}
                            </div>
                        </div>
                    </div>
                }
                return null
            })} */}
        </div>
    )
}
