import React from 'react'  
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
// import TableHead from '@material-ui/core/TableHead';  
import TableRow from '@material-ui/core/TableRow';  
// import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';  
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button,
} from "@material-ui/core";
import TextField from '@material-ui/core/Input';
import { getSessionForCoachAttempt, getSessionForSessionIdAttempt, getAssessmentForSessionIdAttempt } from "../../containers/application/redux_actions";
import SearchIcon from "./assets/search";

import "./ReportForms.css";

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   container: {
//     maxHeight: 440,
//   },
// });
// const [selected, setSelected] = React.useState([]);

// const classes = useStyles();
function populateData(data, aData) {
  if (data) {
    if (data.data) {
      return <div>
        <h3>{data.data[0].sessionName} - {data.data[0].sessionDate} </h3>
        <div>
          {data.data[0].moduleId.title} | {data.data[0].noOfStudents} participants
        </div>
        
        {aData.data 
        ? 
        aData.data.map((arr) => {  
          return <div>{arr.studentName}</div>
          // <div>{arr.studentName}</div>
        })        
        : null 
        }
      </div>
    }
  }
}

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chk: 0, showSearchBar: false, searchText: '',
      childOpen: true, sessionData: [],
      sessionid: 0, sessData: [], selected: [], assessData: []
    };
  }

  async componentDidMount() {
    let userid = this.props.auth.user_uid;
    let token = this.props.auth.user_token;

    const { getSessionForCoachAttempt} = this.props;

    await getSessionForCoachAttempt({ userid, token })
      .then(result => {
        let { allSessions } = this.props.app;
        this.setState({  
          sessionData: allSessions  
        }); 
      }
    )
  }

  render() {    
    //const classes = useStyles();
    // if (this.state.chk === 0) {
    //   return <div>Loading...</div>
    // }
    // else {
      // let { allSessions } = this.props.app;
      let token = this.props.auth.user_token;

      return (
        <div className="with-left-nav wrapper prepare-dashboard">
          <div style={{ display: 'flex', marginBottom: '1.875rem', alignItems: 'flex-end' }}>
            <div className="-headerTitle">
              <h6>Report Zone</h6>
              <h1>Session Logs & Performance Reports</h1>
            </div>
            <div id='filter-container' style={{ display: 'flex', alignItems: 'center' }}>
              <div className="subheading">Find Session</div>
              <div style={{ cursor: 'pointer', display: 'flex', marginLeft: '1.25rem' }} onClick={() => { this.setState({ showSearchBar: !this.state.showSearchBar }) }}>
                <SearchIcon fill={this.state.showSearchBar ? '#e4001b' : '#8c8c8c'} />
              </div>
            </div>
          </div>

          <div className="App">
            <header className="App-header">
              <div>
                {
                  this.state.showSearchBar ?
                    <form className="search-bar" noValidate autoComplete="off">
                      <TextField id="search-field"
                        value={this.state.searchText}
                        placeholder="Enter a module name to search"
                        classes={{ root: "search-text" }}
                        fullWidth={true}
                        onChange={(ev) => { this.setState({ searchText: ev.target.value }) }}
                      />
                      <Button onClick={(ev) => { this.setState({ showSearchBar: false }); this.setState({ searchText: '' }) }} >Close</Button>
                    </form>
                    : null
                }
              </div>
            </header>
          </div> 

          {/* Sort Sessions by Date Modules */}
          <div style={{display:'flex', flexDirection:'row'}}>
          <div className="dvScroll">
           <Paper>
      <TableContainer>
        <Table stickyHeader width="120px" aria-label="sticky table">
        <TableBody>  
            {  
              this.state.sessionData.map((p, index) => {  
                return <TableRow key={index}  onClick={() => {this.setState({ selected: index });}}>  
                  <TableCell align="left" style={{ color: this.state.selected === index? 'red': 'black'}}>
                  <a style={{cursor: "pointer"}} onClick={() => {
                    let id = p.sessionid;
                    this.props.getSessionForSessionIdAttempt({id, token})
                  .then(result => {
                    this.setState({ sessionid: id });
                    this.setState({ sessData: result });

                    this.props.getAssessmentForSessionIdAttempt({id, token})
                    .then(resultA => {
                    this.setState({ assessData: resultA });
                    })
                  })
                  }}>
                    <div>
                      {p.sessionName} - {p.sessionDate}
                    </div>
                    <div>
                      {p.moduleId.title} | {p.noOfStudents} participants
                    </div>
                  </a>
                  </TableCell>  
                </TableRow>  
              })  
            }  
          </TableBody> 
        </Table>
      </TableContainer>
    </Paper> 
  
          </div>        
        
          <div className="dvScroll">
            {(this.state.assessData)
            ? populateData(this.state.sessData, this.state.assessData)
            : null
            }
          </div>
          <div className="dvScroll"></div>
        </div></div>
      );
    // }
  }
}

Report.propTypes = {
  getSessionForCoachAttempt: PropTypes.func.isRequired,
  getSessionForSessionIdAttempt: PropTypes.func.isRequired,
  getAssessmentForSessionIdAttempt: PropTypes.func.isRequired,
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getSessionForCoachAttempt,
    getSessionForSessionIdAttempt,
    getAssessmentForSessionIdAttempt,
  },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(Report);
