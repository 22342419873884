import React
// , { useState } 
from "react";
// import PropTypes from "prop-types";
import AuthLayout from "../../../layouts/auth.layout";
import {
  Button,
  FormControl,
  // IconButton,
  // InputAdornment,
  TextField,
} from "@material-ui/core";
// import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  useForm,
  // Controller
} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// import { loginAttempt } from "../../redux_actions";
import "./styles.scss";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  // password: yup.string().required(),
});

function Login(props) {
  const {
    handleSubmit,
    // control,
    // register,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  // const [showPassword, setShowPassword] = useState(false);

  // const handleShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const onSubmit = (data) => {
    console.log("on submit ==> ", data, validationSchema);
  };

  const handleEmailChange = (e) => { };

  return (
    <React.Fragment>
      <AuthLayout>
        <div className="login_container">
          <div className="login_wrapper">
            <div>Welcome!</div>
            <form>
              <FormControl fullWidth className="form_control">
                <TextField
                  variant="outlined"
                  name="email"
                  onChange={handleEmailChange}
                  label="Your Registered Email ID"
                />
              </FormControl>
              <FormControl fullWidth className="form_control">
                <TextField
                  variant="outlined"
                  name="password"
                  label="Password"
                />
              </FormControl>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="submit_btn"
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>

              <div className="f-links">
                Are you a new User? Click here to{" "}
                <Link to="/auth/register/university">Register</Link>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </React.Fragment>
  );
}

Login.propTypes = {};

export default Login;
