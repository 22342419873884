import React, { useState, useEffect } from "react";
// import { useHistory, Redirect } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tab, Tabs, 
  // Link,
   Button, Typography,
    // FormControl 
  } from "@material-ui/core";
import { getModuleDetails, getRoadmapDetails, getLearningToolDetails, updatePercentage, createExperience } from "./../redux_actions";
import TextField from '@material-ui/core/TextField';

// import { Document, Page, pdfjs, View, Text } from "react-pdf";
// import { Player, ControlBar, BigPlayButton, LoadingSpinner, ReplayControl } from "video-react";

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


// import VideoPopup from "../../../components/Video_Popup";


import "./channelPrepare.css";
import LeftNav from "../../../components/LeftNav";
import LinearNavigator from "../../../components/LinearNavigator";
import BackIcon from './assets/back.svg';
import imgDots from './assets/3dots.svg';
import imgVDots from './assets/3VDots.svg';
import Config from "../../../config"
import APLeftNav from '../../asianPaints/LeftNav'

const { innerHeight: WinHeight } = window;
// const { innerWidth: WinWidth, innerHeight: WinHeight } = window;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const StyledDialog = withStyles({
//   '@global': {
//     '.MuiPaper-rounded': {
//       borderRadius: '15px',
//       padding: '25px'
//     },
//     '.MuiOutlinedInput-root': {
//       borderRadius: '15px',
//     },
//     '.MuiDialog-paper': {
//       width: '480px'
//     },
//     '.MuiTypography-body1': {
//       lineHeight: '1.5'
//     }
//   },
// })(Dialog);

const useStylesDialog = makeStyles((theme) => ({
  StyledDialog: {
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
      padding: '25px'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '15px',
    },
    '& .MuiDialog-paper': {
      width: '480px'
    },
    '& .MuiTypography-body1': {
      lineHeight: '1.5'
    }
  },
}));


const DialogTitle = withStyles(styles)((props) => {

  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const customButton_1 = {
  backgroundColor: '#ffbc36',
  width: '400px',
  cursor: 'pointer'
}



let Prepare = (props) => {
  const [open, setOpen] = React.useState(false);
  const [textData, setText] = React.useState('');
  // const [textError, setTextError] = React.useState(false);

  let [isComponentLoaded, updateComponentLoadStatus] = useState(false);
  let [beginLearningTools, updateBeginLearningTools] = useState(false);
  let [activeTabIndex, updateActiveTabIndex] = useState(1);
  let { user_uid, user_token } = props.auth;
  let { availableModule } = props.app;
  let [classnm, setClassNm] = useState("activeClass");

  const Dialogclasses = useStylesDialog();

  let moduleId = props.match.params.moduleId;
  let moduleData = props.app.moduleDetails[moduleId];
  let roadMaps = Object.keys(moduleData && moduleData.organiser && moduleData.organiser.roadmaps ? moduleData.organiser.roadmaps : {})
    .sort((a, b) => {
      return moduleData.organiser.roadmaps[a].order - moduleData.organiser.roadmaps[b].order
    });
  let firstRoadMapId = roadMaps[0];
  let firstRoadmap = props.app.roadmaps[roadMaps[0]];
  let sortedUnitsOfFirstRoadmap = firstRoadmap ? Object.keys(firstRoadmap.LearningTools).sort((a, b) => {
    return firstRoadmap.LearningTools[a] - firstRoadmap.LearningTools[b];
  }) : null;
  let firstTool = sortedUnitsOfFirstRoadmap ? sortedUnitsOfFirstRoadmap[0] : null;

  let [TypeTitle, setTypeTitle] = useState(moduleData.ImproveEngagement && moduleData.ImproveEngagement[0] && moduleData.ImproveEngagement[0].type ? moduleData.ImproveEngagement[0].type : "");

  // function onSelfCertify(modId) {
  //   window.location.href = "/application/selfcertify/" + modId.moduleId;
  // }

  useEffect(() => {
    props.getModuleDetails({ id: moduleId, token: user_token })
      .then(res => {
        if (res.payload) {
          let roadMaps = (res.payload.data && res.payload.data.organiser && res.payload.data.organiser.roadmaps && Object.keys(res.payload.data.organiser.roadmaps).sort((a, b) => {
            return res.payload.data.organiser.roadmaps[a].order - res.payload.data.organiser.roadmaps[b].order
          })) || [];

          roadMaps.forEach(rdId => {
            props.getRoadmapDetails({ id: rdId, token: user_token })
              .then(ress => {
                if (ress.payload) {
                  Object.keys(ress.payload.data.LearningTools).forEach(uId => {
                    props.getLearningToolDetails({ id: uId, token: user_token })
                  });
                }
              });
          });
        }
      });
    updateComponentLoadStatus(true)
  }, [isComponentLoaded]);

  //   useEffect(() => {
  //     // const timer = setTimeout(() => {
  //     //   console.log('This will run after 5 second!')
  //     // }, 5000);

  // const [numPages, setNumPages] = useState(null);
  function loadFile(filetype, fileurl, fileIcon) {
    if (fileurl) {
      if (filetype === 'png' || filetype === 'svg' || filetype === 'bmp' || filetype === 'jpeg' || filetype === 'jpg' || filetype === 'tiff') {
        return <img src={fileurl} />
      }
      if (filetype === 'pdf' || filetype === 'mp4' || filetype === 'video') {
        return <div style={{ textAlign: 'center' }}>
          {/* <Document
            file={fileurl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)} >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} />)}
          </Document> */}
          {/* <object data={fileurl} type="application/pdf" width="100%" height="100%">
            <p>Alternative text - include a link <a _target='blank' href={fileurl}>to the PDF!</a></p>
          </object> */}

          <a target='_blank' href={fileurl} rel="noopener noreferrer">
            <img src={fileIcon || "https://pedgog2.s3.ap-south-1.amazonaws.com/Becoming+future+ready+contributors/01-+Think+solutions/Icon+for+video.png"} alt="illumine" style={{ width: "150px", height: "150px" }} />
          </a>
        </div>
      }
      // if (filetype === 'mp4' || filetype === 'video') {
      //   return <VideoPopup url={fileurl} />
      // }
      // else if (filetype === 'mp4' || filetype === 'avi') {
      //   return <div> <Player playsInline fluid={false} width={320} height={320}>
      //     <source src={fileurl} />
      //     <BigPlayButton position="center" />
      //     <LoadingSpinner />
      //     <ControlBar autoHide={false}>
      //       <ReplayControl seconds={5} order={2.1} />
      //       <ReplayControl seconds={10} order={2.2} />
      //       <ReplayControl seconds={30} order={2.3} />
      //     </ControlBar>
      //   </Player></div>
      // }
    }
    return null;
  }

  function populateData(val, dataArray) {
    return dataArray.map((el, i) => {
      if (el) {
        if (val === el.type) {
          return <div key={i}>
            <h5 className="clsHead">{el.type}</h5>
            <div className="row d-flex scrollable" style={{ height: '320px', overflow: 'scroll' }}>
              <div className="d-flex flex-column" style={{ flex: 1 }}>
                {el.details.map((ell, index) => {
                  return <div key={index} className="col">
                    <div className="card">
                      <div className="card-body">
                        <div className="clsHead">{ell.Title}</div>
                        <div className="clsDet">{ell.Description}</div>
                        {ell.url ? loadFile(ell.type, ell.url, ell.icon) : null}
                      </div>
                    </div>
                  </div>
                })}
              </div>
              <div className="d-flex flex-column" style={{ flex: 1 }}>
                {
                  el.files ?
                    (el.files.map((file, index) => {
                      return <div key={index} className="col">
                        <div className="card">
                          <div className="card-body">
                            <div className="clsHead">{file.title}</div>
                            <div className="clsDet">{file.description} </div>
                            <div>{loadFile(file.type, file.url, file.icon)}</div>
                          </div>
                        </div>
                      </div>
                    })) :
                    <div className="card">
                      <div className="card-body">
                        <div className="clsHead">{el.fileTitle}</div>
                        <div className="clsDet">{el.fileDescription} </div>
                        <p>{loadFile(el.fileType, el.fileUrl, el.icon)}</p>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        }
      }
    })
  }

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  }

  const clickEvent = (uid, token) => {
    console.log(textData);
    if (textData.length > 0) {
      //let { user_uid, user_token } = props.auth;
      console.log(user_token);
      props.createExperience({ moduleId: moduleId, token: user_token, userId: user_uid, description: textData })
      handleClose();
      //createSession({user_token, moduleId, coach_id, sessionName: textData, sessionType: testType, });
    }
    else {
      // setTextError(true);
    }
  }

  let slideHeight, slideWidth;
  slideHeight = WinHeight - 200 - 37.5;
  slideWidth = slideHeight * 16 / 9;

  let [currentUnit, updatecurrentUnit] = useState(null);
  let [currentScene, setcurrentScene] = useState(0);
  let [currentRoadmap, setCurrentRoadmap] = useState(null);

  // let {availableModule} = this.props.app;
  let availableModules = availableModule;

  let tool = currentUnit
    ? props.app.learningTools[currentUnit]
    : props.app.learningTools[firstTool];

  let scene = props.app.learningTools[currentUnit || firstTool]
    ? props.app.learningTools[currentUnit || firstTool].activeContent[currentScene || 0]
    : null;

  return (
    <div className={"dashboard-cntr"}>
      {Config.ACP_SHOW && window.location.host === Config.ACP_HOST ? "" :
        ((Config.AP_SHOW && window.location.host === Config.AP_HOST) || props.auth.orgDetails.organizationname === 'Asian Paints') ? <APLeftNav /> :
          <LeftNav />}
      <div className="with-left-nav wrapper prepare-pg" style={{ padding: 0 }}>
        <div className="prepare-bg">
          <p className="module-title">
            {/* <div className='subtitle' style={{ color: '#fff',fontSize: '14px' }}> */}
            <a href="javascript:history.back()"> <img src={BackIcon} alt="Illumine" /></a>
            Prepare Zone | {moduleData ? moduleData.title : ''}
            {/* </div> */}
          </p>
          <div className='prep-pg-actions' style={{ margin: '0rem 4rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '93.5%' }}>
            {/* <Tabs value={activeTabIndex} onChange={(ev, val) => { updateActiveTabIndex(val) }} */}
            <Tabs value={activeTabIndex} onChange={(ev, val) => { updateActiveTabIndex(val) }}
              classes={{ root: 'prep-tabs-root', indicator: 'prep-tabs-indicator' }}>
              <Tab classes={{ root: 'prep-tab-root', selected: 'prep-tab-selected' }} label="Coaching Guide" value={1} />
              <Tab classes={{ root: 'prep-tab-root', selected: 'prep-tab-selected' }} label="Improve Engagement" value={2} />
            </Tabs>

            {/* <div onClick={() => { onSelfCertify({ moduleId }) }} style={{
              backgroundColor: '#ffbc36', height: 30,
              borderRadius: 4, margin: '0px 10px', lineHeight: '1.875rem', width: 100, textAlign: 'center',
              cursor: 'pointer', color: 'initial'
            }}>Self Certify</div> */}

            {/* <div onClick={handleClickOpen} style={{
              backgroundColor: '#ffbc36', height: 30,
              borderRadius: 4, margin: '0px 5px', lineHeight: '1.875rem', width: 140, textAlign: 'center',
              cursor: 'pointer', color: 'initial'
            }}>Share Experience</div> */}

          </div>
        </div>
        {activeTabIndex === 1 ? <div>
          {beginLearningTools ? <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden' }}>
            <div style={{ paddingLeft: 60, paddingTop: 40, display: 'flex' }}>
              <LinearNavigator moduleData={moduleData}
                slideWidth={slideWidth}
                slideHeight={slideHeight}
                currentUnit={currentUnit || firstTool}  //ToDo: null should be replaced with usermodule variable
                currentRoadmap={currentRoadmap || firstRoadMapId}
                units={props.app.learningTools}
                roadmaps={props.app.roadmaps}
                updatecurrentUnit={updatecurrentUnit}
                setcurrentScene={setcurrentScene}
                currentScene={currentScene}
                user_uid={user_uid}
                user_token={user_token}
                drawerVariant="temporary"
                typPC="Prepare"
                availableModules={availableModules}
                setCurrentRoadmap={setCurrentRoadmap}
                auth={props.auth}
              />
              <div className='module-card-body scrollable' style={{ height: slideHeight, padding: '2rem 2rem 0.5rem', width: '450px', backgroundColor: 'rgb(255, 253, 225)' }}>
                <p className='mild-text'>GUIDE TO CONDUCT</p>
                {tool ? <div className='prep-description' dangerouslySetInnerHTML={{ __html: scene ? scene.description : tool.description }} /> : null}
              </div>
            </div>
          </div>
            : <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <h1 style={{ paddingLeft: '60px' }}>Learn how to conduct</h1>
              <div style={{ paddingLeft: '60px', paddingTop: '25px', display: 'flex' }}>
                <div className='scrollable module-card-body' style={{
                  width: slideWidth, height: '360px',
                  padding: '2rem', display: 'flex', overflowX: 'hidden'
                }}>

                  <div style={{ width: '30px', height: '30px', lineHeight: '38px', textAlign: 'center', marginRight: '1rem', borderRadius: '2px', backgroundColor: '#8c8c8c' }}>
                    <img src={imgVDots} alt="Illumine" />
                  </div>
                  <div style={{ width: '100%' }}>
                    <p className='mild-text'>MODULE WALK THROUGH</p>

                    <h2 className='module-heading'>{moduleData ? moduleData.description : ''}<small className='estd-time'>Estimated time: {moduleData && moduleData.estimatedTime && moduleData.estimatedTime.hours ? moduleData.estimatedTime.hours + 'hr' : null} {moduleData && moduleData.estimatedTime && moduleData.estimatedTime.minutes}min</small></h2>

                    <hr style={{ margin: '15px -80px', opacity: '0.4' }} />
                    <div className='prep-description'>{moduleData ? moduleData.whyImportant : ''}</div>
                    <h3 style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', margin: "1rem 0rem" }}>Roadmap</h3>
                    {Object.keys(moduleData && moduleData.organiser && moduleData.organiser.roadmaps ? moduleData.organiser.roadmaps : {}).map(sectionId => {
                      let section = { LearningTools: {} }
                      section = (props.app.roadmaps && props.app.roadmaps[sectionId]) ? props.app.roadmaps[sectionId] : section;
                      return <div key={sectionId} style={{ marginBottom: '2rem', lineHeight: '26px', fontSize: 14 }}>
                        <div><h3>{section.roadMapTitle}</h3></div>
                        <div>{section.description}</div>
                        <div>{Object.keys(section.LearningTools).map((els, i) => {
                          // const wait=ms=>new Promise(resolve => setTimeout(resolve, ms)); 
                          // wait(4000);
                          if (props.app.learningTools[els]) {
                            return <div key={els}>{`${i + 1}: ${props.app.learningTools[els].title}`}</div>
                          }
                          else {
                            return null
                          }
                        })}</div>
                      </div>
                    })}
                  </div>
                </div>
                <div className='scrollable module-card-body' style={{
                  height: '360px', flexGrow: 1,
                  padding: '2rem 2rem 0.5rem', maxWidth: 450, backgroundColor: '#fffde1',
                }}>
                  <p className='mild-text'>GUIDE TO CONDUCT</p>
                  <h3>{moduleData.deepDiveTitle}</h3>
                  <div className='prep-description'>{moduleData.deepDiveDescription}</div>
                  {/* <div className='prep-description'>{moduleData.uniqueAboutIt} </div> */}
                  <h3 style={{ marginTop: '1rem' }}>Some Best Practices</h3>
                  <ul style={{ marginLeft: '1rem' }}>{moduleData.externalLinks.map((el, i) => {
                    return <li className='prep-description' style={{ padding: '0px' }} key={`external-links-` + i}>{el}</li>
                  })}</ul>
                </div>
              </div>
              <div style={{
                height: 35, backgroundColor: 'rgb(226, 226, 226)', display: 'flex',
                justifyContent: 'flex-end', alignItems: 'center', marginLeft: 60,
                maxWidth: `calc(${slideWidth}px - 0px)`
                // maxWidth: `calc(${slideWidth}px + 450px)`
              }}>
                <div onClick={() => { updateBeginLearningTools(true) }} style={{
                  backgroundColor: '#ffbc36', height: 30,
                  borderRadius: 4, margin: '0px 10px', lineHeight: '1.875rem', width: 100, textAlign: 'center',
                  cursor: 'pointer'
                }}>Begin</div>
              </div>
            </div>}
        </div> :
          <div>
            <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden' }}>
              <div className="w-100 p-5">
                <h2>Ways to help improve class outcomes</h2>
                <div className="d-flex flex-wrap" style={{ paddingTop: '20px' }}>
                  {moduleData.ImproveEngagement &&
                    <div className="left-card">
                      <ul>
                        {Object.keys(moduleData.ImproveEngagement).map((el, i) => {
                          // return <li className={classnm}><a onClick={() => {setClassNm("inactiveClass"); setTypeTitle(moduleData.ImproveEngagement[el].type)}}
                          return <li key={i}><a className={classnm} style={{ cursor: "pointer" }} onClick={() => { setClassNm("inactiveClass"); setTypeTitle(moduleData.ImproveEngagement[el].type) }}
                          >{moduleData.ImproveEngagement[el].type} <img src={imgDots} alt="Illumine" /></a></li>
                        })}
                      </ul>
                    </div>
                  }
                  {moduleData.ImproveEngagement &&
                    <div className="right-card">
                      {populateData(TypeTitle, moduleData.ImproveEngagement)}
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>}
      </div>

      <div>
        <Dialog className={Dialogclasses.StyledDialog} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Share Your Experience
          </DialogTitle>
          <DialogContent>

            <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
              <div style={{ width: '100%', paddingLeft: '10px' }}>
                <Typography>
                  Do you have a best practice or a success story or any experience you'd like to share with fellow coaching practitioners? Share with us and we'll feature it..
                </Typography>
                {/* <TextField value={textData} onChange={handleChange} required multiline rows={6} error={textError} style={{ width: '100%', marginTop:'5px', marginBottom: '10px' }} label="Share Your Experiences" variant="outlined"  /> */}
                <TextField value={textData} onChange={handleChange} autoComplete="off" required multiline rows={6} label="Share Your Experiences" style={{ width: '100%', marginTop: '5px', marginBottom: '10px' }} variant="outlined" />
              </div></div>
          </DialogContent>
          <DialogActions>
            <Button style={customButton_1} onClick={clickEvent} >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ getRoadmapDetails, getLearningToolDetails, getModuleDetails, updatePercentage, createExperience }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Prepare);