import React, { useState, useEffect, useRef } from 'react'
import LeftNav from "../LeftNav"
import SwitchPanel from '../SwitchPanel'
import './index.css'
import { mdiAccountEdit, mdiAccountPlus, mdiCloudOutline, mdiCloudUpload, mdiConsolidate, mdiPencilOutline } from '@mdi/js';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Icon from '@mdi/react';
import { Button, Input, Tooltip } from '@material-ui/core';
import readXlsxFile from 'read-excel-file';
import { createBatch, createSession, getBatchDetails, getBatchStudents, getOTP, markBatchAttendance, resumeSession, startPostSession, updateBatchStudentList, deleteBatch } from "../../application/redux_actions"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CircularProgress from '@material-ui/core/CircularProgress';

export default ({ app, auth }) => {
    const [batches, setBatch] = useState({});
    const [edit, setEdit] = useState(false);

    const addButtonRef = useRef(null);

    useEffect(async () => {
        await getBatchDetails({ user_token: auth.user_token, user_id: auth.user_uid }).then(async (res) => {
            await res.map(batch => getBatchStudents({ user_token: auth.user_token, batchId: batch._id }))
        })
        setBatch(app.batchStudentsDetails)

    }, [])

    const addBatch = () => {
        setBatch((oldBatch) => {
            return { ...oldBatch, ['Batch ' + (Object.keys(oldBatch).length + 1)]: { batchName: '', students: [] } }
        })

        setTimeout(function () {
            if (addButtonRef?.current)
                addButtonRef.current.scrollIntoView({ behavior: "smooth" })
        }, 0);
    }

    const changetBatchName = (key) => (event) => {
        console.log(key, event.target.value)
        setBatch((oldBatch) => {
            return { ...oldBatch, [key]: { ...oldBatch[key], batchName: event.target.value } }
        })
    }

    const readExcel = (index) => async (event) => {
        readXlsxFile(event.target.files[0]).then(async (rows) => {
            // `rows` is an array of rows    // each row being an array of cells.             
            let students = await rows.map((student) => { console.log(student); return { "name": student[0], "email": student[1] } }).sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            // window.temp = students            

            let old;
            setBatch((oldBatch) => {
                return { ...oldBatch, [index]: { ...oldBatch[index], students } }
            })

            const alert = (message) => {
                window.alert(message)
                setBatch((oldBatch) => {
                    let old = oldBatch
                    delete old[index]
                    return { ...old }
                })
            }
            if (window.confirm('Are you sure')) {
                let batchName = { batchName: batches[index].batchName ? batches[index].batchName : index }
                await createBatch({ user_token: auth.user_token, userId: auth.user_uid }, batchName).then(res => {
                    if (res?.code) {
                        alert(res?.message)
                        return;
                    }

                    if (res?._id)
                        updateBatchStudentList({
                            user_token: auth.user_token,
                            batchId: res._id, students,
                            successCallBack: (resp) => {
                                if (resp?.code) {
                                    alert(resp?.message)
                                    deleteBatch({ user_token: auth.user_token, batchId: res._id })
                                    return;
                                }
                            },
                            failureCallBack: (error) => {
                                alert(error);
                                deleteBatch({ user_token: auth.user_token, batchId: res._id })
                                return
                            }
                        },
                        )
                })
            }
        })
        // createBatch({ user_token: auth.user_token, userId: auth.user_uid }, batch)

    }

    const handleDragEnd = ({ destination, source }) => {
        console.log(source, destination)
        if (!destination || !source) {
            return
        }
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return
        }

        let temp = batches;
        const itemCopy = batches[source.droppableId].students[source.index];
        console.log(temp, itemCopy)
        temp[source.droppableId].students.splice(source.index, 1)
        temp[destination.droppableId].students.splice(destination.index, 0, itemCopy)
        setBatch(temp)

    }

    const view = () => {
        return <div className="d-flex flex-wrap w-100">
            <div className="w-100 mt-20px">
                {Object.keys(batches).map((batch, i) => {
                    let batchStudents = batches[batch]?.students;

                    return <div key={batch + 'view'}>
                        {batchStudents?.length > 0 ? <><div
                            className={`fw-bold mb-10px w-30 ${i != 0 ? 'mt-20px' : ''}`}
                            value={batches[batch]?.batchName}
                            placeholder='Enter the batch name here.'
                            onChange={changetBatchName(batch)}
                        >
                            {batches[batch]?.batchName}
                        </div>
                            <div className={`${batchStudents?.length > 0 ? 'd-grid gtc-4' : 'text-center'} bg-whitesmoke br-30px p-3`} >
                                {batchStudents?.map((student, count) => {
                                    return <Tooltip key={student.studentId} title={student?.email}>
                                        <span> {`${count + 1}. ${student?.name}`}</span>
                                    </Tooltip>
                                })}
                            </div>
                        </> : <>
                            <Input
                                className={`fw-bold mb-10px w-30 ${i != 0 ? 'mt-20px' : ''}`}
                                value={batches[batch]?.batchName}
                                placeholder='Enter the batch name here.'
                                onChange={changetBatchName(batch)}
                            />
                            <div className='text-center bg-whitesmoke br-30px p-3'>
                                <input
                                    className="pointer d-none"
                                    type="file"
                                    id={`upload_file-${i}`}
                                    accept=".xlsx, .xls"
                                    onChange={readExcel(batch)}
                                />
                                <label htmlFor={`upload_file-${i}`}>
                                    <Button ref={addButtonRef} component="span" className="ws-no-wrap">
                                        <Icon path={mdiCloudUpload} size={1} className="va-middle" />
                                        <span className="ml-5">Upload a File</span>
                                    </Button>
                                </label>
                            </div>
                        </>
                        }
                    </div>
                })}
            </div>
        </div>
    }

    const editMode = () => {
        const deleteBatchFn = async (batchName, batchId) => {
            if (window.confirm('Are you sure')) {
                deleteBatch({ user_token: auth.user_token, batchId })
                let temp = { ...batches };
                delete temp[batchName];
                setBatch(temp);
            }
        }
        return <div className="d-flex flex-wrap w-100">
            <div className="w-100 mt-20px">
                <DragDropContext onDragEnd={handleDragEnd}>
                    {Object.keys(batches).map((batch, i) => {
                        let batchStudents = batches[batch]?.students;
                        return <div key={`${batch}+drop_container`} className='w-85 margin-auto'>
                            {batchStudents?.length ? <>
                                <div className={`fw-bold mb-10px w-30 ${i != 0 ? 'mt-20px' : ''}`}>
                                    {batches[batch]?.batchName}
                                </div>
                                <Droppable droppableId={batch}>
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={'bg-white bc-grey bs-dashed br-30px p-3 d-flex flex-wrap align-content-baseline'}
                                        >
                                            {batchStudents?.map((student, count) => {
                                                return <Draggable key={student?.email} draggableId={student?.email} index={count}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="w-22 p-0-5rem m-0-5rem br-12px bc-grey bs-dotted"
                                                        >
                                                            <Tooltip title={student?.email}>
                                                                <span>{`${count + 1}. ${student?.name}`}</span>
                                                            </Tooltip>
                                                        </div>)}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable></>
                                :
                                <>
                                    <Input
                                        className={`fw-bold mb-10px w-30 ${i != 0 ? 'mt-20px' : ''}`}
                                        value={batches[batch]?.batchName}
                                        placeholder='Enter the batch name here.'
                                        onChange={changetBatchName(batch)}
                                    />
                                    <div className='bg-white bc-grey bs-dashed br-30px p-3 text-center'>
                                        < input
                                            className="pointer d-none"
                                            type="file"
                                            id={`upload_file-${i}`}
                                            accept=".xlsx, .xls"
                                            onChange={readExcel(batch)}
                                        />
                                        <label htmlFor={`upload_file-${i}`}>
                                            <Button ref={addButtonRef} component="span" className="ws-no-wrap">
                                                <Icon path={mdiCloudUpload} size={1} className="va-middle" />
                                                <span className="ml-5">Upload a File</span>
                                            </Button>
                                        </label>
                                    </div>
                                </>
                            }
                            <div className="ml-auto mr-1-5rem ap-batch-dashboard-delete-button">
                                <Button className="red font-weight-bold" onClick={() => deleteBatchFn(batch, batches[batch]?.batchId)} startIcon={<DeleteForeverIcon />}>
                                    Delete
                                </Button>
                            </div>
                        </div>

                    })}
                </DragDropContext>
            </div>
        </div>
    }

    return <>
        <div className={"dashboard-cntr"}>
            <LeftNav />
            <div className='d-flex w-100 h-100'>
                {/* <SwitchPanel /> */}
                <div className='w-100 h-100 tct-scrollable-darkgray overFlowYAuto' style={{ padding: '1.5rem', backgroundColor: '#F7F7F7' }}>
                    {/* <div className='ap-program-count'> */}
                    <div className="d-flex ai-center">
                        <span className="ap-program-count">Your Batches</span>
                        {/* </div> */}
                        <div className="ml-auto d-flex mr-1-5rem">
                            <Button component="span" className="ws-no-wrap orange" onClick={addBatch} startIcon={<Icon path={mdiAccountPlus} size={1} />}>
                                Add Batch
                            </Button>
                            <Button component="span" className="ws-no-wrap orange" startIcon={<Icon path={mdiAccountEdit} size={1} />} onClick={() => setEdit(true)}>
                                Edit Batches
                            </Button>
                        </div>
                    </div>
                    {
                        Object.keys(batches).length === 0 ? <div className='w-100 h-100 flex-center'>
                            <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
                        </div> : edit ? editMode() : view()
                    }
                </div>
            </div>
        </div>
    </>
}
