import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
// import { withStyles } from "@material-ui/core/styles";

import { signOut } from "./redux_actions";

let Logout = class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false
    };

    this.setState({notLoggedIn: false})
    this.setState({loggedIn: false})

    this.notLoggedIn = classes => {
      return (
        <div className={classes.root+" login-cntr"}>
          <div className="login-artifact" />
        </div>
      );
    };

    this.loggedIn = (
      <Redirect
        to={{
          pathname: "/application"
        }}
      />
    );
  }

  componentDidMount() {
    //logout;
    this.props.signOut();
  }

  render() {
    const { classes } = this.props;
    if (
      this.props.location.pathname === "/channel" &&
      this.props.auth.isLoggedIn
    ) {
      return;
    } else if (this.props.auth.isLoggedIn) {
      return this.loggedIn;
    } else {
      return this.notLoggedIn(classes);
    }
  }
};

Logout.propTypes = {
  classes: PropTypes.object.isRequired,
  signOut: PropTypes.func,
  location: PropTypes.object,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  notification: state.notification,
  auth: state.auth
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signOut,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Logout);
