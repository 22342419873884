import React, { Component } from "react";
import MainRoute from "./Route"
import Config from "../../config"
//ACP Links
import APRoute from "../asianPaints/AuthRoute"

let Application = class Application extends Component {
  render() {
    if (Config.AP_SHOW && window.location.host === Config.AP_HOST) {
      return <APRoute />
    }
    else {
      return <MainRoute />
    }
  }
};

export default Application;
