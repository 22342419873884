import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./Login";
import Logout from "./Logout";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import myProfile from "./MyProfile";

// Specific Orgs.
import SpecificLogin from "./specific/Login/index";
import SpecificRegister from "./specific/Register/index";

let Auth = class Auth extends Component {
    render() {
        return (
            <Switch>
                {/* Specific Organization Routes */}
                <Route path="/auth/login/university" component={SpecificLogin} />
                <Route path="/auth/register/university" component={SpecificRegister} />
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/logout" component={Logout} />
                <Route path="/auth/register" component={Register} />
                <Route path="/auth/forgotpassword" component={ForgotPassword} />
                <Route path="/auth/resetpassword" component={ResetPassword} />
                <Route path="/auth/myprofile" component={myProfile} />
                <Route path="/auth/" component={Login} />
            </Switch>
        );
    }
};

export default Auth;
