import React from 'react';
import BackIcon from './assets/back.svg';

import { useSelector
  // , useDispatch
 } from 'react-redux';

import { addFormValues } from '../../containers/application/redux_actions';
import { convertFromRaw } from 'draft-js';
import Switch from '@material-ui/core/Switch';

let TopNavigation = (props) => {

  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch();
  let { isHindi, toggleLanguage, data, units, currentUnit, currentScene } = props
  let slides = units && units[currentUnit] && units[currentUnit].activeContent
    ? units[currentUnit].activeContent
    : {};

  const convertEditorTypeData = () => {
    let convertedData = {};
    Object.keys(app.engagementData).map((textBox, i) => {
      convertedData[textBox] = app.engagementData[textBox].blocks ? convertFromRaw(app.engagementData[textBox]).getPlainText() : app.engagementData[textBox];
    })
    return convertedData;
  }

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  const toolDataSave = () => {
    let sessionId = app.createdSession.id;
    let user_token = auth.user_token;
    let fieldData = convertEditorTypeData();
    addFormValues({ sessionId, user_token, fieldData })
  }
  return <div style={{ paddingLeft: '1.4rem', color: '#fff', backgroundColor: '#0b1e3d', width: '100%', display: 'flex', alignItems: 'center' }}>
    <a href="javascript:history.back()" onClick={() => app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave()}> <img style={{ display: 'flex' }} src={BackIcon} alt="Illumine" /></a>
    <div style={{ padding: '1rem' }}> Conduct Zone | {data.description} </div>
    {slides[currentScene] && slides[currentScene].url && isObject(slides[currentScene].url) ?
      <div color="primary" style={{ textAlign: "end", padding: "0rem 2.5rem", marginLeft: 'auto' }}>
        <span>A</span>
        <Switch color="primary" checked={isHindi} onChange={toggleLanguage} name="language" inputProps={{ 'aria-label': 'secondary checkbox' }} />
        <span>अ</span>
      </div> : ""}
  </div >
}

export default TopNavigation;