import React, { Component } from "react";
import { connect } from "react-redux";
import MainRoute from "./Route"
import Config from "../../config"
//ACP Links
import ACPRoute from "../acp"
import APRoute from "../asianPaints/AppRoute"

let Application = class Application extends Component {
  render() {
    if (Config.ACP_SHOW && window.location.host === Config.ACP_HOST) {
      return <ACPRoute />
    }
    else if ((Config.AP_SHOW && window.location.host === Config.AP_HOST) || this.props.auth.orgDetails.organizationname === 'Asian Paints') {
      return <APRoute app={this.props.app} auth={this.props.auth} />
    }
    else {
      return <MainRoute />
    }
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app,
});


export default connect(
  mapStateToProps, null
)(Application);
