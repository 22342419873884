// import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import config from '../config';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { startPostSession } from '../containers/application/redux_actions';
import store from '../Redux_Store';
import Interaction from '../containers/application/Interaction';

const etah = (event) => {
    switch (event.eventName) {
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            break;
        case "ASSESSMENT_DONE":
            store.dispatch({ type: "ASSESSMENT_DONE", payload: event })
            break;
        default:
            console.log(event)
    }
}

export default ({ width, height }) => {
    const { moduleId } = useParams()
    const { user_token } = useSelector((state) => state.auth)
    const session = useSelector(state => state.app.createdSession)
    const sessionId = session["id"]
    // const [isStarted, setStarted] = useState(false)
    const [copy, setCopy] = useState(false)
    const { studentJoinedList, assessmentDoneStudent } = useSelector(state => state.app)

    useEffect(() => {
        Interaction.startSession(sessionId, etah)
    }, [sessionId])

    return (
        <div style={{ width, height }}>
            {/* <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex', height: '100%' }}> */}
            <div style={{ flexDirection: 'column', display: 'flex', height: '100%', marginLeft: '2%' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='ap-conduct-myapp-text my-1'>
                        MyApp Link for Assessment
                    </div>
                    <div className='d-flex'>
                        <CopyToClipboard
                            text={`${config.MyAPP_URL}classroom/assessment/${moduleId}`}
                            // text={`${config.MyAPP_URL}classroom/assessment/${session.sessionid}`}
                            // text={`${config.MyAPP_URL}classroom/assessment/${sessionId}`}
                            style={{ padding: '0px 10px', border: '1px solid #707070', borderRadius: '20px', font: 'normal normal bold 12px/18px tablet-gothic', letterSpacing: '0.48px', cursor: "pointer", marginRight: '1rem' }}
                            onCopy={() => { setCopy(true); startPostSession({ user_token, sessionId }) }}>
                            {copy ? <span style={{ color: 'green' }}>Link copied </span> : <span>copy link </span>}
                        </CopyToClipboard>
                        <div>{`${config.MyAPP_URL}classroom/assessment/${moduleId}`}</div>
                        {/* <div>{`${config.MyAPP_URL}classroom/assessment/${session.sessionid}`}</div> */}
                    </div>
                    {Object.values(studentJoinedList).length !== 0 ? <div className='w-100 h-50'>
                        <div className='ap-conduct-dialog-participant-text m-0-8 ml-0'>{Object.values(assessmentDoneStudent).length} {Object.values(assessmentDoneStudent).length === 1 ? 'participant taken assessment' : 'participants taken assessment'}</div>
                        <div className='d-flex flex-wrap w-100 h-90 overFlowYAuto tct-scrollable-darkgray align-content-baseline'>{Object.values(studentJoinedList).map((student) => {
                            return <>
                                <div className='d-flex align-items-center height-fit-content align-content-baseline m-0-8 ml-0 ap-conduct-dialog-participant-name' style={{ fontFamily: "tablet-gothic" }}>
                                    {Object.keys(assessmentDoneStudent).includes(student.userId) ? <div className='w-10px h-10px br-5px mr-0-5rem bg-green'></div> : <div className='w-10px h-10px br-5px mr-0-5rem bg-grey'></div>}
                                    {student.username}</div>
                            </>
                        })
                        }</div>

                    </div> : <div className='ap-conduct-dialog-participant-text m-0-8 ml-0 ap-conduct-dialog-no-participant-text width-fit'>No participants have joined yet.</div>}
                </div>
            </div>
        </div >
    );
}