import React from "react";
import PropTypes from "prop-types";
// import queryString from 'query-string';
import {
  IconButton,
  // Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography,
  // TextField, 
  OutlinedInput
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import logo from "./assets/logo.svg";
// import backArrow from "./assets/BackArrow.svg";
import successIcon from "./assets/Success.svg";

import "./ResetPassword.css";

const styles = {};

class ResetPasswordform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      passwordConfirm: "",
      showPassword: false,
      showPasswordConfirm: false,
      fields: {},
      errors: {},
      label: "",
      successDiv: false
    }

    const styles = {
      label: {
        backgroundColor: "white",
        padding: '0px 5px'
      }
    };

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = (value) => {
      if (value === 'password')
        this.setState({ showPassword: !this.state.showPassword });
      else if (value === 'confirm password')
        this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm });
    };
  }

  handleValidation(pwd, pwdc) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" })
    }
    //PasswordConfirm
    if (!pwdc) {
      formIsValid = false;
      errors["user-passwordConfirm"] = "Confirm Password cannot be empty";
      this.setState({ label: "Confirm Password can not be empty" })
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    // console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }



  render() {
    // const { classes } = this.props;
    console.log(this.state);
    return (
      <div className={"auth-form-cntr"}>
        <div className="flexBox fbox">
          <div className="leftBoxLogin">
            <img src={logo} alt="Illumine" />
            <div className="d-block my-3">
              <p className="lfFont" >Your pathway to delivering <strong>transformative </strong> learning & coaching experiences! </p>
            </div>
          </div>
          <div className="rightBoxLogin">
            {this.state.successDiv === false ?
              <form>
                <div className="errorMsg">
                  <label style={{ fontSize: '12px' }}> {this.state.label} </label>
                </div>

                <h4 className="text-left mb-3">Set up a new password for your account​</h4>
                <div className={"loginform-cntr"}>

                  {/* <FormControl fullWidth className={"input-cntr"}>
                  <TextField className="mb-3" id="user-password" label="Choose a new Password" autoComplete="off"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                              <Visibility />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                    variant="outlined" />
                </FormControl> */}

                  <FormControl fullWidth className="input-cntr mb-3" variant="outlined">
                    <InputLabel style={{ backgroundColor: "white", padding: '0px 5px' }} htmlFor="outlined-adornment-password">Choose a new Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword('password')}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  {/* 
                <FormControl fullWidth className={"input-cntr"}>
                  <TextField className="mb-3" id="user-passwordConfirm" label="Confirm your new Password" autoComplete="off"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.passwordConfirm}
                    onChange={this.handleChange("passwordConfirm")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                              <Visibility />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                    variant="outlined" />
                </FormControl> */}
                  <FormControl fullWidth className="input-cntr mb-3" variant="outlined">
                    <InputLabel style={{ backgroundColor: "white", padding: '0px 5px' }} htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-confirm-password"
                      type={this.state.showPasswordConfirm ? 'text' : 'password'}
                      value={this.state.passwordConfirm}
                      onChange={this.handleChange("passwordConfirm")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword('confirm password')}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  <Typography
                    className="errorMsg"
                    variant="caption"
                    gutterBottom
                    align="center"
                    color="secondary"
                  >
                    {this.props.authMsg}
                  </Typography>

                  <FormControl
                    fullWidth
                    className={"input-cntr"}
                  >

                    <Button
                      variant="raised"
                      color="primary"
                      className="loginBtn"
                      onClick={() => {
                        let { password, passwordConfirm } = this.state;
                        let params = this.getQueryVariable('token');
                        let token = params;
                        if (password === passwordConfirm) {
                          let chkVal = this.handleValidation(password, passwordConfirm);

                          if (chkVal) {
                            this.props.resetPasswordUpdate({
                              password,
                              token,
                              successCallBack: (res) => {
                                this.setState({ label: "Password successfully updated." })
                                this.setState({ successDiv: true })
                              },
                              failureCallback: (error) => {                                   
                                this.setState({ label: error });
                              }
                            })
                          }
                          else {
                            this.setState({ label: "Invalid Entry!" })
                          }
                        }
                        else {
                          this.setState({ label: "Password does not match." })
                        }
                      }}
                    >
                      Set New Password
                    </Button>
                  </FormControl>
                </div>
              </form> : <div className='successDiv'>
                <div className="flexBox align-items-center successText">
                  <img src={successIcon} style={{ height: '42px' }} alt="Illumine" /> <label className="text-success ml-3">Success</label>
                </div>
                <small className="mb-3 d-block">Password Reset Successful!.</small>
                <Link to='/auth/login' style={{ textDecoration: 'none', color: 'black' }}>
                  <Button style={{ backgroundColor: "#ffbc36", cursor: "pointer" }}>Login with New Password</Button>
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordform.propTypes = {
  classes: PropTypes.object.isRequired,
  loginAttempt: PropTypes.func,
  resetPasswordUpdate: PropTypes.func,
  authMsg: PropTypes.string
};

export default withStyles(styles)(ResetPasswordform);
