import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import ChevronLeft from './assets/chevronLeft';
import ChevronRight from './assets/chevronRight';
import fscrn from './assets/fullscreen.svg';

import { useSelector
  // , useDispatch
 } from 'react-redux';

import { addFormValues } from '../../containers/application/redux_actions';
import { convertFromRaw } from 'draft-js';
import './style.css'

import Config from "../../config"

const btnCntr = {
  height: 40, width: 55, margin: '0px 5px', display: 'flex', borderRadius: 4,
  justifyContent: 'center', alignItems: 'center', backgroundColor: '#efefef',
  cursor: 'pointer'
}

let lastRoadmap = '';
let lastUnit = '';
let moduleId = '';
export function updUnit(roadmaps, currentRoadmap, currentUnit, updatecurrentUnit, setcurrentScene) {
  let j = 0;
  // {
    Object.keys(roadmaps[currentRoadmap].LearningTools).map((uId, i) => {
      if (j === 1) {
        setcurrentScene(0);
        updatecurrentUnit(uId);
        j = 0;
      }
      if (currentUnit === uId) {
        j = 1;
      }
    })
  // }
}

export function fnupdatePercentage(data) {

  if (data) {
    // fetch('http://localhost:3000/v1/progress/createProgressPercentage', {
    fetch(Config.API_URL + 'progress/createProgressPercentage', {
      //fetch('https://beta.pedgog.in:3000/v1/progress/createProgressPercentage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "bearer " + data.token,
        "accept": "application/json"
      },
      body: JSON.stringify({
        userId: data.uid,
        moduleId: data.moduleId,
        progressType: data.typPC,
        roadmapId: data.rrid,
        learningToolsId: data.lid,
        activeContent: data.aContent,
        isLastSlide: data.isLastSlide,
        sessionId: data.sessionId,
        currentSlide: data.cScne
      })
    })
      .then(response => response.json())
      .then(data1 => {
        console.log(data1)
        if (data1.result > 0) {
          data.updatepercomps(data1.result)
        }
      }
      )
      .catch(error => {
        console.log(error);
      })
  }
}

let Navigation = ({ isFullScreen, toggleFullScreen, setcurrentScene, currentScene, lastScene, currentUnit, updatecurrentUnit, currentRoadmap, units, uid, token, roadmaps, typPC, data, percomps, updatepercomps, setCurrentRoadmap }) => {
  // console.log(units)
  let aContent = units && units[currentUnit] && units[currentUnit].activeContent && units[currentUnit].activeContent[currentScene]
    ? units[currentUnit].activeContent[currentScene].id
    : '';
  let lid = '';
  moduleId = data.id;

  const currentSlide = units && units[currentUnit] && units[currentUnit].activeContent
    ? units[currentUnit].activeContent[currentScene] : null;

  const app = useSelector(state => state.app);
  const auth = useSelector(state => state.auth);
  // const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (typPC === "Prepare") {
      window.location.href = "/application/prepare";
    }
    else if (typPC === "Conduct") {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clickEvent = () => {
    if (typPC === "Prepare") {
      window.location.href = "/application/prepare";
    }
    else if (typPC === "Conduct") {
      window.location.href = "/application/conduct/";
    }
  }

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const StyledDialog = withStyles({
    paper: {
      width: '600px',
      borderRadius: '15px',
      padding: '30px'
    }
  })(Dialog);


  const DialogTitle = withStyles(styles)((props) => {

    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const customButton_1 = {
    backgroundColor: '#ffbc36',
    cursor: 'pointer'
  }

  const customButton_2 = {
    backgroundColor: '#8D8D8D',
    cursor: 'pointer'
  }

  const convertEditorTypeData = () => {
    let convertedData = {};
    Object.keys(app.engagementData).map((textBox, i) => {
      // console.log(textBox);
      // console.log(convertFromRaw(app.engagementData[textBox]).getPlainText());
      convertedData[textBox] = app.engagementData[textBox].blocks ? convertFromRaw(app.engagementData[textBox]).getPlainText() : app.engagementData[textBox];
    })
    return convertedData;
    // let a = initialData ? convertFromRaw(initialData) : null;
    // let b = a ? EditorState.createWithContent(a) : EditorState.createEmpty();
  }

  const toolDataSave = () => {
    // console.log(app)
    // console.log(auth)
    let sessionId = app.createdSession.id;
    let user_token = auth.user_token;
    let fieldData = convertEditorTypeData();
    // let learningToolsId = units[currentUnit]._id;
    addFormValues({ sessionId, user_token, fieldData })
  }

  return <div style={{
    padding: '8px 0', backgroundColor: '#e2e2e2', display: 'flex',
    alignItems: 'center', justifyContent: 'space-between'
  }}>
    <img alt='Illumine' style={{ width: "40px", alignSelf: 'flex-end', cursor: 'pointer', marginLeft: "20px", filter: 'invert(50%)' }} src={fscrn}
      onClick={() => {
        toggleFullScreen(!isFullScreen)
      }} />
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={`btn${currentScene === 0 ? '-inactive' : ''}`} style={btnCntr} onClick={() => {
        typPC !== 'Prepare' && currentSlide.type === 'tool' && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
        if (currentScene > 0) {
          setcurrentScene(currentScene - 1)
        }
      }}>

        <ChevronLeft /></div>
      <div className={`btn${currentScene === lastScene ? '-inactive' : ''}`} style={btnCntr} onClick={() => {
        typPC !== 'Prepare' && currentSlide.type === 'tool' && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
        if (currentScene < lastScene) {
          setcurrentScene(currentScene + 1)
          moduleId = data.id;
          let rrid = currentRoadmap;
          let isLastSlide = "0";
          lid = currentUnit;
          let cScne = currentScene + 1;

          if (cScne === lastScene) {
            isLastSlide = "1"
            // fnupdatePercentage({ uid, moduleId, typPC, rrid, lid, token, aContent, updatepercomps, isLastSlide });
            // isLastSlide = "0"
          }
          let sessionId = app && app.createdSession && app.createdSession.id
          fnupdatePercentage({ uid, moduleId, typPC, cScne, rrid, lid, token, aContent, updatepercomps, isLastSlide, sessionId });
        }
      }}><ChevronRight />
      </div>
    </div>

    <div style={{ display: 'flex' }}>

      {
        Object.keys(data.organiser && data.organiser.roadmaps ? data.organiser.roadmaps : {}).map(rid => {
          //      Object.keys(roadmaps).map(rid => {
          lastRoadmap = rid
        })
      }
      {
        roadmaps[currentRoadmap] ?
          Object.keys(roadmaps[currentRoadmap].LearningTools).map(uId => {
            lastUnit = uId
          })
          : null
      }

      {
        (currentRoadmap === lastRoadmap) ?
          (currentUnit === lastUnit) ?
            <div className='nav-next-button' onClick={() => {
              // let i = 0;
              typPC !== 'Prepare' && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave()
              // {
                if (currentRoadmap === lastRoadmap) {
                  setTimeout(() => handleClickOpen(), 300)
                }
              // }
            }}>
              {typPC === "Prepare" ?
                <label>Finish</label>
                :
                <label>End Session</label>
              }
            </div>
            :
            <div className='nav-next-button' onClick={() => {
              updUnit(roadmaps, currentRoadmap, currentUnit, updatecurrentUnit, setcurrentScene);
              typPC !== 'Prepare' && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
            }}>
              Next Unit
            </div>
          :
          (currentUnit === lastUnit) ?
            <div className='nav-next-button' onClick={() => {
              let i = 0;
              typPC !== 'Prepare' && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
              // {
                Object.keys(data.organiser ? data.organiser.roadmaps : {}).map(sectionId => {
                  if (i === 1) {
                    i = 0;
                    setCurrentRoadmap(sectionId);
                    setcurrentScene(0);
                    let o = 0;
                    Object.keys(roadmaps[sectionId].LearningTools).map(uId => {
                      if (o === 0) {
                        updatecurrentUnit(uId);
                        o = 1;
                      }
                    });

                    return;
                  }
                  if (sectionId === currentRoadmap) {
                    i = 1;
                  }
                })
              // }
            }}>
              <label>Next Section</label>
            </div>
            :
            <div className='nav-next-button' onClick={() => {
              updUnit(roadmaps, currentRoadmap, currentUnit, updatecurrentUnit, setcurrentScene);
              typPC !== 'Prepare' && app.engagementData && Object.keys(app.engagementData).length !== 0 && toolDataSave();
            }}>
              Next Unit
            </div>
      }

      <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', width: '100%', marginBottom: '15px' }}>
            <div style={{ width: '100%', paddingLeft: '10px' }}>
              <Typography>
                Are you sure you want to end session?
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button style={customButton_1} onClick={handleClose}>
            Don't End
          </Button>
          <Button style={customButton_2} onClick={clickEvent}>
            <Link style={{ textDecoration: 'none', color: 'black' }} to={`/application/prepare`} >End</Link>
          </Button>
        </DialogActions>
      </StyledDialog>

    </div>
  </div >
}

export default Navigation;
