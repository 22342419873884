import React from "react";
// import PropTypes from "prop-types";
import AuthLayout from "../../../layouts/auth.layout";
import {
  Button, FormControl,
  // Grid, 
  TextField
} from "@material-ui/core";

import "./styles.scss";
import { Link } from "react-router-dom";

function Register(props) {
  return (
    <React.Fragment>
      <AuthLayout>
        <div className="register_container">
          <div className="register_wrapper">
            <div>Please Register yourself</div>
            <form>
              <FormControl fullWidth className="form_control">
                <TextField variant="outlined" label="Full Name" />
              </FormControl>
              <FormControl fullWidth className="form_control">
                <TextField variant="outlined" label="Email ID" />
              </FormControl>
              <FormControl fullWidth className="form_control">
                <TextField variant="outlined" label="Set Password" />
              </FormControl>
              <FormControl fullWidth className="form_control">
                <TextField
                  variant="outlined"
                  label="Access Code"
                  InputProps={{
                    endAdornment: (
                      <Button className="verify_btn" disabled={true}>
                        Verify
                      </Button>
                    ),
                  }}
                />
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="submit_btn"
              >
                Register
              </Button>
              <div className="f-links">
                Are you a registered user? Click here to{" "}
                <Link to="/auth/login/university">Login</Link>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </React.Fragment>
  );
}

Register.propTypes = {};

export default Register;
