import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Button,
  Typography, TextField
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import logo from "../LoginForm/assets/logo.svg";

import "./Registration.css";
import { connect } from "react-redux";
import CountryCode from '../Add_CollegeName_Popup/CountryCode.json'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Config from "../../config";
// import States from '../../containers/acp/Add_SchoolName_Popup/states.json'


const styles = {
  root: {
    display: "flex",
  },
  label: {
    backgroundColor: "white",
    padding: '0px 5px'
  }
};

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      accesscode: "",
      fullname: "",
      fields: {},
      errors: {},
      label: "",
      result: null,
      uToken: "",
      organization: {
        userFilled: '',
        selected: ''
      },
      schoolType: {
        userFilled: '',
        selected: ''
      },
      schoolState: {
        userFilled: '',
        selected: ''
      },
      phone: '',
      pinCode: '+91',
      state: ''
    };

    this.handleChange = prop => event => {
      if (prop === 'accesscode')
        this.setState({ [prop]: (event.target.value).toUpperCase() });
      else this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
    console.log(this.props.auth)
  }



  handleValidation(eml, pwd, fnm) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }
    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" })
    }
    //fullname
    if (!fnm) {
      formIsValid = false;
      errors["fullname"] = "Full Name cannot be empty";
      this.setState({ label: "Full Name can not be empty" });
    }

    if (typeof fnm !== "undefined") {
      if (!fnm.match(/^[a-zA-Z ]+$/)) {
        formIsValid = false;
        errors["fullnamename"] = "Only letters";
        this.setState({ label: "Full Name accepts only letters" });
      }
    }



    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf('@');
      let lastDotPos = eml.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && eml.indexOf('@@') === -1 && lastDotPos > 2 && (eml.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email is not valid" })
      }
    }

    if (this.props.auth && this.props.auth.organisationList && this.props.auth.organisationList.organization && this.props.auth.organisationList.organization.length > 0) {
      if (this.state.phone.length < 10 || (!this.state.organization.selected && !this.state.organization.userFilled) || (this.state.organization.selected === null && !this.state.organization.userFilled)) {
        this.setState({ label: "Please Check all the fields" })
        formIsValid = false;
      }
    }


    this.setState({ errors: errors });
    return formIsValid;
  }

  handleAutoCompleteChange = (parentField, field, newValue) => {
    this.setState({
      ...this.state,
      [parentField]: {
        ...this.state[parentField],
        [field]: newValue
      }
    })
  }

  handlePhoneNumber = (event) => {
    let regex = /^\d{0,10}$/;
    if (regex.test(event.target.value))
      this.setState({
        ...this.state,
        phone: event.target.value
      })
  }

  async componentDidMount() {
    //Login with system 
    let email = "su@ggmail.com";
    let password = "acs12345";
    let flag = 0;
    this.props.loginAttempt({
      email,
      password, flag,
      successCallBack: (val) => {
        //alert('success')
        this.setState({ uToken: val.tokens.refresh.token });
      },
      failureCallback: () => {
        //alert('failed');
      }
    });
  }

  isACP = Config.ACP_SHOW && window.location.host === Config.ACP_HOST

  render() {
    const { classes } = this.props;
    const toLogin = props => <Link to="../auth/login" {...props} />;
    //const toResetPassword = props => <Link to="../auth/reset-password" {...props} />;
    console.log(this.state)

    return (

      <div className="d-flex w-100 overflow-hidden" style={{ justifyContent: 'space-evenly' }}>
        {console.log(this.state)}
        <div className="leftBoxLogin">
          <img src="/assets/logo.svg" alt="Illumine" />
          <div className="d-block myy-3">
            <p className="lfFont" >Your pathway to delivering <strong>transformative</strong> learning & coaching experiences!</p>
          </div>
        </div>
        <div className="rightBoxReg h-100">
          <form className='h-100'>
            <div className="d-block myy-3">
              <p className="lfFontN" >Register yourself to begin coaching </p>
            </div>

            <div className="loginform-cntr h-90 overFlowYAuto tct-scrollable" style={{ padding: 10 }}>

              {this.state.label ?
                (<div className="errorMsg">
                  <label> {this.state.label} </label>
                </div>) : null
              }



              <FormControl
                fullWidth
                className={"input-cntr"}
              >
                {/* <InputLabel htmlFor="adornment-password">Enter Your Email ID</InputLabel>
              <Input
                id="user-email"
                label="Enter Your Email ID"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              /> */}

                <TextField className="mbb-3" id="user-email" label="Enter Your Email ID" variant="outlined" value={this.state.email} onChange={this.handleChange("email")} />
              </FormControl>

              <FormControl
                fullWidth
                className={"input-cntr"}
                variant="outlined"
              >

                <InputLabel className={classes.label} htmlFor="user-password">
                  Password
                </InputLabel>
                <OutlinedInput className="mbb-3" id="user-password" autoComplete="off"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />


                {/* <InputLabel htmlFor="adornment-password">Set Password</InputLabel>
              <Input
                id="user-password1"
                label="Set Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              /> */}
              </FormControl>

              <FormControl
                fullWidth
                className={"input-cntr"}
              >
                <TextField className="mbb-3"
                  autoComplete='off'
                  id="accesscode" label="Access Code" variant="outlined"
                  value={this.state.accesscode} onChange={this.handleChange("accesscode")}
                  onBlur={() => {
                    // verifyAccessCode(this.state.accesscode, this.state.uToken);
                    this.props.getAccessTokenDetails({ accesscode: this.state.accesscode, email: this.state.email, user_token: this.state.uToken })
                  }}
                />



              </FormControl>
              <FormControl
                fullWidth
                className={"input-cntr"}
              >
                {/* <InputLabel htmlFor="adornment-password">Enter Your Full Name</InputLabel>
            <Input
              id="fullname"
              label="Enter Your Full Name"
              type="text"
              autoComplete="on"
              value={this.state.fullname}
              onChange={this.handleChange("fullname")}
            /> */}

                <TextField className="mbb-3" id="fullname" label="Enter Your Full Name" variant="outlined" value={this.state.fullname} onChange={this.handleChange("fullname")} />



              </FormControl>
              {
                this.props.auth?.organisationList?.organization?.length > 0 ? <div>
                  <FormControl className="mbb-3 w-100" variant="outlined" style={{ flexDirection: 'row' }}>
                    <Select
                      id="phone_pin"
                      value={this.state.pinCode || ''}
                      onChange={this.handleChange('pinCode')}
                      className='w-30'
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {
                        CountryCode.map((item) => <MenuItem key={item.code} value={item.dial_code}>{item.code} : {item.dial_code}</MenuItem>)
                      }
                    </Select>
                    <TextField style={{ marginLeft: '3%' }} fullWidth label="Enter Mobile Number" variant="outlined" value={this.state.phone} onChange={this.handlePhoneNumber} />
                  </FormControl>
                  {this.isACP ? <>
                    <Autocomplete
                      value={this.state.schoolType.selected}
                      onChange={(event, newValue) => {
                        this.handleAutoCompleteChange('schoolType', 'selected', newValue)
                      }}
                      openOnFocus
                      freeSolo
                      autoHighlight
                      className="mbb-3"
                      inputValue={this.state.schoolType.userFilled}
                      onInputChange={(event, newInputValue) => {
                        this.handleAutoCompleteChange('schoolType', 'userFilled', newInputValue)
                      }}
                      options={[...new Set(this.props.auth.organisationList.organization.map(org => org.schoolType))]}
                      getOptionLabel={(option) => option || ''}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="School Type"
                        variant="outlined" />}
                    />
                    <Autocomplete
                      value={this.state.schoolState.selected}
                      onChange={(event, newValue) => {
                        this.handleAutoCompleteChange('schoolState', 'selected', newValue)
                      }}
                      openOnFocus
                      freeSolo
                      autoHighlight
                      className="mbb-3"
                      inputValue={this.state.schoolState.userFilled}
                      onInputChange={(event, newInputValue) => {
                        this.handleAutoCompleteChange('schoolState', 'userFilled', newInputValue)
                      }}
                      options={[...new Set(this.props.auth.organisationList.organization.filter(org => this.state.schoolType.selected != "" ? org.schoolType == this.state.schoolType.selected : org.schoolType).map(org => org.state))]}
                      getOptionLabel={(option) => option || ''}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="States"
                        variant="outlined" />}
                    />
                    <Autocomplete
                      value={this.state.organization.selected}
                      onChange={(event, newValue) => {
                        this.handleAutoCompleteChange('organization', 'selected', newValue)
                      }}
                      openOnFocus
                      freeSolo
                      autoHighlight
                      className="mbb-3"
                      inputValue={this.state.organization.userFilled}
                      onInputChange={(event, newInputValue) => {
                        this.handleAutoCompleteChange('organization', 'userFilled', newInputValue)
                      }}
                      options={this.props.auth.organisationList.organization.filter(org => this.state.schoolType.selected != "" ? org.schoolType == this.state.schoolType.selected : org.schoolType).filter(org => this.state.schoolState.selected != "" ? org.state == this.state.schoolState.selected : org.state)}
                      getOptionLabel={(option) => option.organizationname || ''}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="School Name"
                        variant="outlined" />}
                    />
                  </>
                    : <Autocomplete
                      value={this.state.organization.selected}
                      onChange={(event, newValue) => {
                        this.handleAutoCompleteChange('organization', 'selected', newValue)
                      }}
                      openOnFocus
                      freeSolo
                      autoHighlight
                      className='mbb-3'
                      defaultValue={""}
                      inputValue={this.state.organization.userFilled}
                      onInputChange={(event, newInputValue) => {
                        this.handleAutoCompleteChange('organization', 'userFilled', newInputValue)
                      }}
                      id="college-list"
                      options={this.props.auth.organisationList.organization}
                      getOptionLabel={(option) => {
                        if (option.organizationname)
                          return option.organizationname
                        return ''
                      }}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Select/Type your Institution name"
                        variant="outlined" />}
                    />
                  }
                  {/* <FormControl className="mbb-3 w-100" variant="outlined" style={{ flexDirection: 'row' }}>
                    <InputLabel id="state-select">Select your State</InputLabel>
                    <Select
                      className='w-100'
                      id="state-select"
                      value={this.state.state || ''}
                      onChange={this.handleChange('state')}
                      placeholder='Select your State'
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {
                        Object.keys(States).map((item) => <MenuItem key={item} value={States[item]}>{States[item]}</MenuItem>)
                      }
                    </Select>
                  </FormControl> */}
                </div> : null
              }
              <Typography
                className="errorMsg"
                variant="caption"
                gutterBottom
                align="center"
                color="secondary"
              >
                {this.props.authMsg}
              </Typography>

              <FormControl
                fullWidth
                className={"input-cntr"}
              >
                <Button
                  variant="raised"
                  color="primary"
                  className="loginBtn"
                  onClick={() => {
                    let { email, password, accesscode, fullname } = this.state;
                    let chkVal = this.handleValidation(email, password, fullname);
                    // let chkVal1 = false;

                    // TO-DO: Port to Backend

                    if (chkVal) {
                      this.props.getAccessTokenDetails({ accesscode, email })
                        .then(result => {
                          // chkVal1 = true;
                          //let { accesscodeData } = this.props.auth;

                          if (result.data) {
                            if (result.data.length > 0) {
                              let allowedCount = result.data[0].allowedCount;
                              let organizationid = this.state.organization.selected ? this.state.organization.selected.id : result.data[0].organizationId;
                              let usedCount = result.data[0].usedCount;
                              let pkids = result.data[0].packIds;
                              // let acid = result.data[0].id;

                              let pkid = [];
                              //let pkid = "[";
                              // {
                              pkids.map((el, i) => {
                                if (el) {
                                  let arrval = '{"PackId": "' + el + '", "fromDate": "08/01/2020", "toDate": "08/31/2020", "status": "Active"}';
                                  pkid.push(arrval);
                                }
                              })
                              // }
                              // pkid = pkid.substring(0, pkid.length - 1);
                              // pkid += "]";

                              let uid = null;
                              if (parseInt(allowedCount, 10) > parseInt(usedCount, 10)) {
                                this.props.registerAttempt({
                                  email,
                                  password,
                                  accesscode,
                                  fullname,
                                  organizationid,
                                  phone: this.state.pinCode + this.state.phone,
                                  successCallBack: (val) => {
                                    if (val.message) {
                                      this.setState({ label: val.message })
                                    }
                                    uid = val.user.id;
                                    if (uid !== null) {
                                      //use that token
                                      let uTok = this.state.uToken;

                                      //subscribe
                                      this.props.subscribePack({ uid, pkid, uTok })
                                        .then(result => {
                                          this.setState({ label: "Registration Successfully Done!" })
                                          let flag = 1;
                                          this.props.loginAttempt({
                                            email,
                                            password, flag,
                                            successCallBack: (usr) => {
                                              let orgid = usr.user.organizationid;
                                              let token = usr.tokens.access.token;
                                              this.props.getOrganizationAttempt({ orgid, token })
                                                .then(result => {
                                                  // let conductflag = result.conductflag;
                                                })
                                            },
                                            failureCallback: () => {
                                              //alert('failed');
                                            }
                                          });
                                        });
                                    }
                                  },
                                  failureCallback: () => {
                                    //alert('failed');
                                  }
                                });

                                // this.setState({label: "Registration Successfully Done.."})
                              }
                              else {
                                this.setState({ label: "Licence Limit Over!" })
                              }
                            }
                            else {
                              //this.setState({label: "Some values are not correct. Please try again."})
                              this.setState({ label: "Invalid Access Code" })
                            }
                          }
                          else {
                            this.setState({ label: "Invalid Access Code" })
                          }
                        })
                    }
                  }}
                >
                  Register
                </Button>
              </FormControl>
              <div className="login-link-cntr d-block myy-3">
                <Typography className="" variant="caption" gutterBottom>
                  By clicking on register, you agree to the Terms of Use, Privacy Policy, and the Cookie Policy
                </Typography>

                <p> Are you a registered user? Click here to <Button className={classes.button}>
                  <Typography
                    className="errorMsg"
                    variant="caption"
                    gutterBottom
                    color="primary"
                    component={toLogin}
                  >
                    Login
                  </Typography>
                </Button> </p>
              </div>
            </div>
          </form>
        </div>
      </div >
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  getAccessTokenDetails: PropTypes.func,
  registerAttempt: PropTypes.func,
  loginAttempt: PropTypes.func,
  subscribePack: PropTypes.func,
  getOrganizationAttempt: PropTypes.func,
  authMsg: PropTypes.string
};


const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(withStyles(styles)(Registration));
// export default connect()
