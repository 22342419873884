const INITIAL_STATE = {
  authInProgress: false,
  resetPasswordInProgress: false,
  passwordLinkSent: false,
  userRegistrationInProgress: false,
  anyAuthRequestInProgress: false,
  isLoggedIn: false,
  user: null,
  user_token: "",
  user_uid: "",
  user_name: "",
  loginErrorMsg: "",
  forgotPasswordErrorMsg: "",
  registrationErrorMsg: "",
  availableModule: [],
  accesscodeData: {}, //Access Code Details
  accesscodeDataLength: 0,
  register_uid: "", //Registered user id
  login_flag: 0,
  login_err: "",
  errMsg: "",
  orgDetails: {},
  exploreFlag: "",
  prepareFlag: "",
  conductFlag: "",
  organisationList: {},
  updatedUser: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "LOGIN_ATTEMPT": //login progress
      return { ...state, msg: "", authInProgress: true, engagmentData: '', genericModule: {}, genericUnits: {}, availableModule: [] };
    case "LOGIN_SUCCEEDED": //login sucess
      try {
        if (action.payload.code === 401) {
          return {
            ...state,
            ...INITIAL_STATE,
            login_err: action.payload.message
          };
        }
        else {
          if (action.payload.user.email === "su@ggmail.com") {
            return {
              ...state,
              ...INITIAL_STATE,
              user: action.payload.user,
              user_uid: action.payload.user.id,
              user_token: action.payload.tokens.refresh.token,
              user_name: action.payload.user.name,
              login_flag: action.flag
            }
          }
          else {
            return {
              ...state,
              ...INITIAL_STATE,
              isLoggedIn: true,
              user: action.payload.user,
              user_uid: action.payload.user.id,
              user_token: action.payload.tokens.refresh.token,
              user_name: action.payload.user.fullname,
              login_flag: action.flag,
              exploreFlag: action.payload.user.exploreFlag,
              prepareFlag: action.payload.user.prepareFlag,
              conductFlag: action.payload.user.conductFlag,
              engagmentData: '', genericModule: {}, genericUnits: {}, availableModule: []
            };
          }
        }
      }
      catch { }
      break;
    case "REGISTER_SUCCEEDED": //register sucess
      if (action.payload.user) {
        return {
          ...state,
          ...INITIAL_STATE,
          register_uid: action.payload.user.id,
          engagmentData: ''
        };
      }
      else {
        return {
          ...state,
          errMsg: action.payload.message,
        };
      }
    case "LOGIN_FAILED": //login failed
      return { ...state, loginErrorMsg: action.payload, authInProgress: false };
    case "LOGOUT_ATTEMPT": //logout progress
      return { ...state, ...INITIAL_STATE, engagmentData: '' };
    case "LOGOUT_SUCCEEDED": //logout sucess
      return { ...state, engagmentData: '' };
    case "LOGOUT_FAILED": //logout failed
      return { ...state, loginErrorMsg: "Logout Failed" };
    case "SET_LOGIN_ERROR_MSG":
      return { ...state, loginErrorMsg: action.payload };
    case "REGISTER_ATTEMPT":
      return { ...state, loginErrorMsg: "", authInProgress: true };
    case "MYPROFILE_ATTEMPT":
      return { ...state, loginErrorMsg: "", authInProgress: true };
    case "GETACCESSTOKENDETAILS_ATTEMPT":
      return { ...state, isLoadingData: true, loginErrorMsg: '' };
    case "GETACCESSTOKENDETAILS_SUCCEEDED":
      return {
        ...state,
        loginErrorMsg: '',
        accesscodeData: action.data,
        accesscodeDataLength: action.data.length
      };
    case "GETACCESSTOKENDETAILS_FAILURE":
      return {
        ...state,
        loginErrorMsg: action.data
      }
    case "SUBSCRIBEPACK_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "SUBSCRIBEPACK_SUCCEEDED":
      return { ...state, isLoadingData: true };
    case "SUBSCRIBEPACKFORUSER_ATTEMPT":
      return { ...state, isLoadingData: true };
    case "SUBSCRIBEPACKFORUSER_SUCCEEDED":
      return { ...state, isLoadingData: true };
    case "PASSWORD_RESET_IN_PROGRESS":
      console.log('password reset in progress' + action.payload);
      return {
        ...state,
        forgotPasswordErrorMsg: "",
        resetPasswordInProgress: action.payload
      };
    case "SET_RESET_PASSWORD_ERROR_MSG":
      console.log('reset password error msg' + action.payload);

      return {
        ...state,
        forgotPasswordErrorMsg: action.payload,
        resetPasswordInProgress: false
      };
    case "RESET_PASSWORD_LINK_SENT":
      console.log('reset password link sent' + action.payload);

      return {
        ...state,
        resetPasswordInProgress: false,
        passwordLinkSent: true
      };
    case "PASSWORD_UPDATE":
      return { ...state };
    case "PASSWORD_UPDATE_SUCCESS":
      return { ...state, user_uid: action.payload };
    case "UPDATE_TOKEN":
      return { ...state, user_token: action.payload };
    case "UPDATE_USER_UID":
      return { ...state, user_uid: action.payload };
    case "RESET_LOGIN_PAGE":
      return { ...state, loginErrorMsg: "" };
    case "RESET_FORGOT_PASSWORD_PAGE":
      return { ...state, ...INITIAL_STATE };
    case "RESET_REGISTER_NEW_USER_PAGE":
      return { ...state, ...INITIAL_STATE };
    // case "GETORGANIZATION_ATTEMPT":
    //   return { ...state };
    case "GETORGANIZATION_SUCCEEDED":
      return { ...state, orgDetails: action.data };
    case "GET_ORGANISATIONBYPARENTID_SUCCESS":
      return { ...state, organisationList: action.payload };
    case "UPDATE_USER_BY_ID_SUCCESS":
      return { ...state, user: action.payload, updatedUser: true, organisationList: {}, orgDetails: {} };
    case "UPDATE_USER_BY_ID_CHANGE":
      return { ...state, updatedUser: false };
    default:
      return state;
  }
};
