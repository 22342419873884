import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { createSession, getOTP } from "../../application/redux_actions"
// import { createSession, getAttendence, getOTP } from "../../application/redux_actions"
import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from "react-copy-to-clipboard";
// import OtpInput from "react-otp-input";

import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import Interaction from '../../application/Interaction';
import store from '../../../Redux_Store';
import { Link } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        // width: '100%',
        // maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: '90%',
        height: '90%',
        borderRadius: '24px',
        overflow: 'hidden'
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        }
    }
}));


function ConfirmationDialog(props) {

    const handleCancel = () => {
        props.handleCloseConfirm();
    };

    const handleOk = () => {
        props.handleCloseConfirm();
        props.handleCloseInfo();
        // window.location.pathname = "/application"
    };


    return (
        <Dialog
            aria-labelledby="confirmation-dialog-title"
            open={props.confirmOpen}
        >
            <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
            {/* <DialogContent dividers>
                Hello world 2
            </DialogContent> */}
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    No
                </Button>
                <Button onClick={handleOk} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
const etah = (event) => {
    // console.log(event)
    switch (event.eventName) {
        case "JOIN_SESSION":
            store.dispatch({ type: "STUDENT_JOINED", payload: event.eventData })
            break;

        default:
            console.log(event)
    }
}

function InfoDialog({ open, index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, testType, handleCloseInfo }) {

    const classes = useStyles();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const { createdSession, otpData, studentJoinedList } = useSelector(state => state.app)

    const date = createdSession && createdSession.createdAt && new Date(createdSession?.createdAt)
    // const currentDate = date.toLocaleDateString();
    // const currentTime = date.toLocaleTimeString();
    const [copy, setCopy] = useState(false)

    const handleOpenConfirm = () => {
        setConfirmOpen(true);
    };

    const handleCloseConfirm = () => {
        setConfirmOpen(false);
    };

    // useEffect(() => {
    //     if (open)
    //         dispatch(createSession({ user_token, moduleId, coach_id, sessionType: testType, sessionName: new Date() }, (result) => {
    //             dispatch(getOTP({ user_token, sessionId: result.id, testType, coachId: coach_id }))
    //             Interaction.startSession(result.id, etah)
    //         }))
    // }, [open])

    // useEffect(() => {
    //     if (open && createdSession?.id) {

    //     }
    // }, [createdSession])

    return (
        <Dialog
            aria-labelledby="info-dialog-title"
            open={open}
            maxWidth={false}
            classes={{
                paper: classes.paper,
            }}
        >
            {console.log()}
            <div className='d-flex h-100'>
                <div className='d-flex flex-column h-100 w-30' style={{ padding: '2rem' }}>
                    <div>
                        <div className='ap-conduct-popup-title'>
                            {description}
                        </div>
                        <div className='ap-conduct-popup-session-id-text' style={{ padding: '0' }}>
                            <div className='mt-1'>
                                SESSION ID : {createdSession?.sessionid}
                            </div>
                            <div className='mt-3'>
                                DATE : {moment(date).format('DD/MM/YYYY')}
                            </div>
                            <div className='my-0-3'>
                                TIME: {moment(date).format('LT')}
                            </div>
                            <div className='my-0-3'>
                                DURATION :  {app && app.moduleDetails && app.moduleDetails[moduleId].estimatedTime && app.moduleDetails[moduleId].estimatedTime.hours ? app.moduleDetails[moduleId].estimatedTime.hours + ' hr ' : ''}
                                {app && app.moduleDetails && app.moduleDetails[moduleId].estimatedTime && app.moduleDetails[moduleId].estimatedTime.minutes} mins
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: '1', position: 'relative', width: '100%' }}>
                        <img src={mainFilePath} className='w-100 ap-program-leftImg' style={{ paddingLeft: '0px' }} alt='ModuleLogo' />
                    </div>
                </div>
                <div className='w-70 ap-gray-background-2' style={{ padding: '1.5rem 2.5rem' }}>
                    <div className='d-flex flex-column h-80'>
                        <div className='ap-conduct-myapp-text'>
                            MyApp Link for participant
                        </div>
                        <div className='d-flex' style={{ marginTop: '15px' }}>
                            {otpData?.otp ? <>
                                <CopyToClipboard text={`${config.MyAPP_URL}auth?accesscode=STARMAKERAP&&otp=${otpData?.otp}`} style={{ padding: '0px 10px', border: '1px solid #707070', borderRadius: '20px', font: 'normal normal bold 12px/18px tablet-gothic', letterSpacing: '0.48px', cursor: "pointer" }} onCopy={() => setCopy(true)}>
                                    {copy ? <span style={{ color: 'green' }}>Link copied </span> : <span>copy link </span>}
                                </CopyToClipboard>
                                <div className='ap-conduct-myapp-otp' style={{ marginLeft: '10px', letterSpacing: '0.56px' }}>
                                    {/* {`${config.MyAPP_URL}classroom?otp=${otpData.otp}`} */}
                                    {`${config.MyAPP_URL}auth?accesscode=STARMAKERAP&&otp=${otpData?.otp}`}
                                </div>
                            </> : <CircularProgress />}
                        </div>
                        <div className='box width-fit'>
                            <span className='otp-text'>OTP &nbsp;</span>
                            <span className="ml-70 mr-70" style={{ font: "normal normal 600 22px/32px tablet-gothic", letterSpacing: "5.72px" }}> {otpData?.otp} </span>
                        </div>
                        {Object.values(studentJoinedList).length !== 0 ? <div className='w-100 h-50'>
                            <div className='ap-conduct-dialog-participant-text m-0-8 ml-0'>{Object.values(studentJoinedList).length} {Object.values(studentJoinedList).length === 1 ? 'participant is attending' : 'participants are attending'}</div>
                            <div className='d-flex flex-wrap w-100 h-90 overFlowYAuto tct-scrollable-darkgray align-content-baseline'>{Object.values(studentJoinedList).map((student) => {
                                return <>
                                    <div className='d-flex align-items-center height-fit-content align-content-baseline m-0-8 ml-0 ap-conduct-dialog-participant-name' style={{ fontFamily: "tablet-gothic" }}><div className='ap-conduct-dialog-student-joined-circle'></div> {student.username}</div>
                                </>
                            })
                            }</div>

                        </div> : <div className='ap-conduct-dialog-participant-text m-0-8 ml-0 ap-conduct-dialog-no-participant-text width-fit'>No participants have joined yet.</div>}
                    </div>
                    <div className='d-flex justify-content-flex-end'>
                        <div className='mx-2'>
                            {/* <Button className='ap-conduct-dialog-button' classes={{ label: classes.buttonLabel, root: classes.buttonRoot }} style={{ background: '#FFBC36 0% 0% no-repeat padding-box' }}> */}
                            <Link to={`/application/conduct/${moduleId}`} style={{ color: "black", font: "normal normal bold 14px/18px tablet-gothic", letterSpacing: "0.56px" }}>
                                <Button style={{ background: '#FFBC36 0% 0% no-repeat padding-box', borderRadius: "20px", padding: '10px 15px', width: '215px', height: '57px' }}>
                                    Begin Session
                                </Button>
                            </Link>
                            <div onClick={handleOpenConfirm} style={{ textAlign: 'center', textDecoration: 'underline', font: "normal normal 600 12px/18px tablet-gothic", letterSpacing: "0.48px", color: "#6B6B6B", textTransform: "uppercase", paddingTop: '10px', cursor: "pointer" }}>
                                CANCEL SESSION
                            </div>
                        </div>
                        <div className='mx-2'>
                            {/* <Button className='ap-conduct-dialog-button' classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}>
                                <Link to={`/application/conduct/${moduleId}`}>
                                    Trail Session
                                </Link>
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationDialog
                classes={{
                    paper: classes.paper,
                }}
                id="confirm-id"
                keepMounted
                confirmOpen={confirmOpen}
                open={open}
                handleCloseInfo={handleCloseInfo}
                handleCloseConfirm={handleCloseConfirm}
            />

        </Dialog >
    );
}



export default function ConductDialog({ index, title, description, mainFilePath, moduleId, uniqueAboutIt, user_token, coach_id, auth, app, buttonTextComponent, buttonClassName, rootClassName }) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(window.location.pathname == `/application/otp/${moduleId}` ? true : false)
    const handleCloseInfo = () => {
        setOpen(false);
        window.location.pathname = `/application`
    };

    return (
        <div className={rootClassName}>
            <Link className={buttonClassName} to={`/application/otp/${moduleId}`} onClick={() => dispatch(createSession({ user_token, moduleId, coach_id, sessionType: "pre", sessionName: new Date() }, (result) => {
                dispatch(getOTP({ user_token, sessionId: result.id, testType: "pre", coachId: coach_id }))
                Interaction.startSession(result.id, etah)
            }))}>
                {buttonTextComponent}
            </Link>

            <InfoDialog
                id="Info-id"
                moduleId={moduleId}
                title={title}
                description={description}
                mainFilePath={mainFilePath}
                user_token={user_token}
                coach_id={coach_id}
                auth={auth}
                app={app}
                keepMounted
                open={open}
                // setOpen={setOpen}
                testType="pre"
                handleCloseInfo={handleCloseInfo}
            />
        </div >
    );
}