import { useSelector } from 'react-redux';
import React,{useState,useEffect} from 'react';
// import { getEngagement } from '../../containers/application/redux_actions'
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd';
import _ from "lodash";
import './styles.css';
import {v4} from 'uuid';
import Button from '@material-ui/core/Button';
import EdiText from 'react-editext';
// import Avatar from '@material-ui/core/Avatar';
import logo from './Group 3140.svg'
import shutterstock from'./shutterstock_565164751 [Converted].png'
import Circle from './Group 3420.svg'
import Start_N_Stop from '../Start_N_Stop'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export default ({ width, height, engagementData, manageToolData, isFullScreen, data, engagementStatus, sessionId }) => {
 
    const responses = useSelector(state => state.app.response[data.engagement]);

    const [search,setSearch]=useState('')
    const [tag_filter, setTag_filter] = useState('')
    // const [select_tags,setSelect_tags]=('')
    const [text,setText]=useState('')
    
    const [dragApp, setDragApp]= useState({
        
        //columns
        "First":{
          title:"Ideal0",   //key
          item:
          responses && Object.keys(responses).map((r) => 
            ({id: v4(), text: r, user: responses[r].map((u)=>u.user),tags: responses[r].map((u)=>u.tags)})), 
        },

        "Second":{
          title:"Financial & transactional value",
          item:[]
        },

        "Third":{
          title:"Utility value",
          item:[]
        },

        "Four":{
          title:"Experiential value",
          item:[]
        },

        "Five":{
          title:"Human & social value",
          item:[]
        },
      })
useEffect(() => {
  setDragApp(
   {...dragApp, "First":{
    title:"Ideal0",   //key
    item:
    responses && Object.keys(responses).map((r) => 
      ({id: v4(), text: r, user: responses[r].map((u)=>u.user),tags: responses[r].map((u)=>u.tags)})), 
  }})
}, [responses])
          
// adding Teacher Inputs or Ideas 

                    const handleChange =(e)=>{

                      setText(e.target.value)
                    }
                    const addItem =(e)=>{
                      if(text==="") {
                        alert("Cannot add empty box")
                        return}
                   
                      setDragApp(prev=>{
                        let temp=prev.First.item || []
                        return{
                          ...prev,
                          First:{
                            title:"Ideal0",
                            item:[{
                              id:v4(),
                              text:text,
                              user:["teacher"],
                              tags:[]

                            },
                              ...temp
                            ]} }}
                            
                            )
                            setText('')
                        }
                  
// Editing Ideas 

                    const handleSave = (key, e, newValue) => {
                      
                      let index = dragApp[key].item.findIndex((i) => i.id ===e.id)
                      
                      
                      if(dragApp[key] && dragApp[key].item)
                      {
                        
                        
                        setDragApp({...dragApp, [key]: {...dragApp[key], item: [...dragApp[key].item.slice(0, index), { ...dragApp[key].item[index], text: newValue}, ...dragApp[key].item.slice(index+1) ]}})}
                    }
                  
// Remove Ideas from list

                    const handleRemove=(key, id)=>{
                      // let index = dragApp[key].item.findIndex((i) => i.id ===id)
                      
                      
                      let items = dragApp[key].item.filter((i) => i.id !== id)        
                        
                        setDragApp({...dragApp, [key]: {...dragApp[key], item: items}})
                      
                    } 
                    
                    
                    
// handling drag & drop 

                  const handleDragEnd= ({destination, source}) => {
                  
                                
                                
                  
                    if (!destination){
                      return
                    }
                    if (destination.index === source.index && destination.droppableId === source.droppableId){
                      return
                    }
                    const itemCopy = {...dragApp[source.droppableId].item[source.index]}
                    setDragApp(prev=>{
                      prev={...prev}                                                
                      prev[source.droppableId].item.splice(source.index,1)
                      prev [destination.droppableId].item.splice(destination.index,0,itemCopy)
                      return prev
                    })

                  }

    
    return (
      <div style={{ width, height }}>
      <div style={{ width: '100%', height: '100%'}}>

{/* logo image */}
      <img src={logo} style={{ width: '390px', height: '78px',position:"relative",right: "50px",bottom: "15px" }} />
            <img src={shutterstock} style={{ width: '5%', height: '5%',position:"relative",left: '58.5%',bottom:"20px"}} />
{/* teacher text box     */}
<div className="textbg" >
  
  <input  className="input" type="text" value={text} onChange={handleChange}></input>
    <Button onClick={addItem}>
        Add 
      </Button>
  </div>


{/* drag & Drop  */}

      <div className="ideaBoard">
      
        <DragDropContext onDragEnd={handleDragEnd}>
        
          {_.map(dragApp,(data,key) =>{
             
             
            return (
             
                <div key={key} className={key}>
                {Object.keys(dragApp).indexOf(key) >0 ? 
                     <div ><h3 className="title"> {data.title} </h3></div> :""}
              <Droppable droppableId={key}> 
                  {(provided)=>{
                  return(
                  <div style={{marginLeft:"10px"}}>
                    { Object.keys(dragApp).indexOf(key) === 0 ? 
                  <div className='s&d'>
                
                   <TextField type="text" 
                    className='_search'
                   placeholder="Search..." 
                   variant="outlined"
                   size="small"
                   onChange={(e)=> {setSearch(e.target.value)}}/>
                   
                  <FormControl size="small" variant="outlined" className="_dropdwon">
       
        <Select
         className='ki'
          
          id="demo-simple-select-outlined"
          value={tag_filter}
          onChange={(e)=> {setTag_filter(e.target.value)}}
        
        >
          <MenuItem className='isd'
          value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Financial & transactional value'}>Financial & transactional value</MenuItem>
          <MenuItem value={'Utility value'}>Utility value</MenuItem>
          <MenuItem value={'Experiential value'}>Experiential value</MenuItem>
          <MenuItem value={'Human & social value'}>Human & social value</MenuItem>
        </Select>
      </FormControl>
                      
                    </div> :''} 
                   
                    <div
                        ref={provided.innerRef}                     
                        {...provided.droppableProps}
                        className={data.title}
                        >    
                        
                     {data && data.item && data.item.filter((el)=>{
                       
                      if (data.title !=="Ideal0"){
                        return el
                      }
                       
                      let user=el.user.map((u)=>{
                         return u.toLowerCase()
                       })
                       
                       let _tags=el.tags.flat()
                       let tag=_tags.map((t)=>{
                         
                            // 
                        return t.toLowerCase()
                      })
                      
                   
                  
                      // if (search =="" ||el.text.includes(search.toLocaleLowerCase())|| user.toString().includes(search.toLocaleLowerCase()))  {
                      //   return el
                      // }
                       if (search === "" ||el.text.includes(search.toLocaleLowerCase())|| user.toString().includes(search.toLocaleLowerCase()) ||tag.toString().includes(search.toLocaleLowerCase()))  {
                         return el
                       }
                     
                    
                      
                     
                     })
                  .filter((el)=>{
                    if (data.title !=="Ideal0"){
                      return el
                    }
                    let _tags=el.tags.flat()
                       let tag=_tags.map((t)=>{
                         
                            // 
                        return t.toLowerCase()
                      })
                    if (tag_filter === ""){
                          return el
                        }
                        else if (tag.toString().includes(tag_filter.toLocaleLowerCase())){
                          return el
                        }
                  }) .map((el ,index) =>{
                       
                     return(
                       
                          <Draggable key={el.id} index={index} draggableId={el.id}>
                            {(provided) => {
  
                              return(
                                <div
                                className={"item0"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}             
                                {...provided.dragHandleProps} 
                                >

{/* student profile pics  */}
                                
                     
                                  
                                <span className= "ideas">
 {/* Editing Ideas  */}
 <div style={{display:"flex"}}>
 <div className="user">{el.user}</div>
 <span className="delete">
{/* Removing Ideas from list */}

                                  <DeleteForeverIcon 
                                  fontSize="small"
                                  onClick={() => handleRemove(key, el.id)}></DeleteForeverIcon>
                                  </span>
                                  </div>
 <div className="ed">
                               <EdiText 
                               viewContainerClassName='my-custom-view-wrapper0'
                               type='textarea'
                               inputProps={{
                                 rows: 5
                               }}
                               editButtonClassName="custom-edit-button0"
                               editButtonContent={<EditIcon size="small"/>}
                               value={el.text} 
                               showButtonsOnHover 
                               
                               onSave={(newValue) => handleSave(key, el, newValue)} ></EdiText>
                                            
                                  {/* <span>{el.user}</span>
                                  <span>{el.tags}</span> */}
                               
                                  </div>
                                  </span>
                                  </div>
                                 
                                ) }}
                          </Draggable>
                         
  
                        )
                      })}

                      {provided.placeholder}  
                                                
                    </div>
                    </div>
                )}}
            </Droppable>
            </div>
          )})}
        
       </DragDropContext>
{/* chart image  */}

       <img src={Circle} style={{ width: '16%', height: '16%',position:"relative",left: '39.5%',bottom: "830px" }} />
 {/* start and stop button     */}
 <div className='_done'>


      <div className="startstop"> <Start_N_Stop className="start"
                            size="small"
                            engagementData={engagementData}
                            manageToolData={manageToolData}
                            data={data}
                            isFullScreen={isFullScreen}
                            engagementStatus={engagementStatus}
                            sessionId={sessionId}
                        /></div>
                        </div>
          
      </div>  
     
      </div>

        </div >
    );
}