import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import NavLink from "./NavLink";
// import VideoPopup from "../Video_Popup";
import { connect } from "react-redux";
import PeopleIcon from '@material-ui/icons/People';
// import { deregister, offline, register } from "./offline"
// import { GetApp } from "@material-ui/icons";
// import { auth } from "firebase";
// import { makeOffline } from "../../containers/application/redux_actions";
import './style.css'

const styles = {};
const logo = require('./assets/pedgog.svg');
const grp = require('./assets/conduct.svg');
// const grp1 = require('./assets/coachdev.svg');
const logoutIcon = require('./assets/logout_black_24dp.svg')
class LeftNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accesscode: "",
      animate: false,
      offline: false
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState((prevState) => {
      return { animate: !prevState.animate }
    });
  }

  routeChange() {
    let path = `/application`;
    this.props.history.push(path);
  }

  render() {
    return (
      <div className={"ap-left-nav h-100"}>
        <form className={"h-100"}>
          <nav className={"h-100"}>
            <ul className={"h-100"} >
              {/* <ul className="nav nav-pills pull-right"> */}
              {/* <NavLink to="/application/prepare"> */}
              <div>
                <div style={{ padding: '1rem' }}>
                  <img src={logo} alt="logo" style={{ display: 'block', margin: 'auto' }} />
                </div>
                {/* </NavLink> */}
                <NavLink to="/application">
                  <img src={grp} alt="grp" className="ap-nav-img" />
                  <label className='ap-nav-link-profile-text'>Program</label>
                </NavLink>
                {/* 
                <NavLink to="/application/batch">
                  <PeopleIcon className="ap-nav-img" alt="batch"/>                  
                  <label className='ap-nav-link-profile-text'>Your Batch</label>
                </NavLink> */}

                {/* <NavLink to="/application/prepare">
                    <img src={grp1} alt="grp1" className="Group-2231" />
                    <label>Coaching Foundations</label>
                  </NavLink> */}
                {/* <NavLink to="/application/conduct">
                    <img src={grp2} alt="grp2" className="Group-2226" />
                    <label>Conduct</label>
                  </NavLink>
                  <NavLink to="/application/Projects">
                    <img src={grp3} alt="grp2" className="Group-2226" />
                    <label>Projects</label>
                  </NavLink>
                  <NavLink to="/application/coaching-analytics">
                    <img src={grp3} alt="grp4" className="Group-2226" />
                    <label>Reports</label>
                  </NavLink> */}
                {/* <NavLink to="/application/dashboard">
                    <img src={dashboard} />
                    <label>Dashboard</label>
                  </NavLink> */}
              </div>
              {/* <NavLink to="/application/report">
                <img src={grp3} alt="grp3" className="Group-2227" />
                <label>Report</label>
              </NavLink>
                */}
              {/* <NavLink to="google.com">
                <img src={grp3} alt="grp3" className="Group-2227" />
                <label>Develop</label>
              </NavLink> */}
              {/* <li id="btn-Add">
                  <span>
                    <Link to="/application/AddModule">
                      <img src={grp6} alt="grp6" className="logout"/>
                    </Link>
                    <label>Add Module</label>
                  </span>
                </li> */}
              <div className='last-div' style={{ justifyContent: 'flex-end' }}>
                {/* {
                    'serviceWorker' in navigator ?
                      <li>
                        <span>
                          <a onClick={() => {

                            if (this.props.app.offline) {
                              // console.log("Going Online")
                              makeOffline(false)
                              deregister()
                            }
                            else {
                              {
                                // console.log("Going Offline")
                                makeOffline(true)
                                register(this.props.app);
                              }
                            }
                            this.setState({ offline: !this.state.offline })
                          }}>
                            <img src={download} />
                            <label style={{ whiteSpace: "nowrap" }} >{this.props.app.offline ? "Go Online" : "Go Offline"}</label>
                          </a>
                        </span>
                      </li> : ""} */}
                {/* <li id="btn-logout">
                      <span>
                        <Link to="/auth/logout">
                          <img src={grp5} alt="grsp5" className="logout" />
                        </Link>
                        <label>Logout</label>
                      </span>
                    </li> */}
                {/* <VideoPopup /> */}
                {/* <NavLink to="/application/#">
                    <img src={grp1} alt="grp6" className="Group-2225" />
                    <label>Profile</label>
                  </NavLink> */}
                <div>
                  <div className='ap-nav-link-profile'>{this.props.auth.user_name[0]}</div>
                  <div className='ap-nav-link-profile-text tablet-gothic'>Profile</div>
                </div>
                <NavLink to="/auth/logout">
                  <img src={logoutIcon} alt="ap-nav-img" className="ap-nav-img" />
                  <label>Logout</label>
                </NavLink>
              </div>
            </ul>
          </nav>
        </form>
      </div >
    );
  }
}

LeftNav.propTypes = {
  classes: PropTypes.object.isRequired,
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app
});

export default connect(mapStateToProps, null)(withStyles(styles)(LeftNav));