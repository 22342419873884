import React from 'react';

let CheveronLeft = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 8.875 15.746">
        <g id="prefix__Group_2428" data-name="Group 2428" transform="translate(-55.703 -15.274)">
            <g id="prefix__Group_2110" data-name="Group 2110" transform="translate(56.703 16.689)">
                <path id="prefix__Path_1055" d="M-20033.723-13678.445l-6.461 6.459 6.461 6.459" data-name="Path 1055" transform="translate(20040.184 13678.445)" style={{fill:'none',stroke:'#8c8c8c',strokeLinecap:'round',strokeLinejoin:'round',strokeWidth:'2px'}} />
            </g>
        </g>
    </svg>
}

export default CheveronLeft
