import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/Input";
import {
  getPrepareModulesAttempt,
  getContinueConductModules,
  getModuleDetails,
  getTagsAttempt,
} from "../../containers/application/redux_actions";
import SearchIcon from "./assets/search";
// import moment from "moment";

import "./ConductForms.css";
import ModuleCardConduct from "./ModuleCard";
// import VideoPopup from "../Video_Popup";

import "./style.css";
// import no_modules from "./assets/no_modules.svg";

class Conduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chk: 0,
      showSearchBar: false,
      searchText: "",
      childOpen: true,
    };
    this.openChild = this.openChild.bind(this);
    this.hideChild = this.hideChild.bind(this);
  }

  openChild() {
    const currentStateChild = this.state.childOpen;
    this.setState(
      {
        childOpen: !currentStateChild,
      },
      () => {
        console.log("Child is opened " + this.state.childOpen);
      }
    );
  }

  hideChild() {
    this.setState({
      childOpen: false,
    });
  }

  async componentDidMount() {
    let userid = this.props.auth.user_uid;
    let token = this.props.auth.user_token;

    const {
      getPrepareModulesAttempt,
      // getContinueConductModules,
      getModuleDetails,
      getTagsAttempt,
      // units,
    } = this.props;

    await getPrepareModulesAttempt({ userid, token }).then((result) => {
      this.setState({ chk: 1 });
      let { availableModule } = this.props.app;
      let availableModules = availableModule;
      availableModules.forEach((module) => {
        getModuleDetails({ id: module.moduleId, token });
      });
    });

    await getTagsAttempt({ token }).then((result) => {
      this.setState({ chk: 1 });
      // let { availableTag } = this.props.app;
      //        let availableTags = availableTag;
    });

    // get continue sessions for the user;
    // await getContinueConductModules({
    //   userid,
    //   token,
    // });
  }

  render() {
    if (this.state.chk === 0) {
      return <div>Loading...</div>;
    } else {
      let { moduleDetails } = this.props.app;
      let { orgDetails } = this.props.auth;
      // const { continueConducts } = this.props.app;

      let availableModule = this.props.app.availableModule.filter((el) => {
        if (
          moduleDetails[el.moduleId] &&
          moduleDetails[el.moduleId].description
        ) {
          return new RegExp(this.state.searchText, "i").test(
            moduleDetails[el.moduleId].description
          );
        }
        return true;
      });
      let token = this.props.auth.user_token;
      let userid = this.props.auth.user_uid;

      // let { availableTags } = this.props.app;

      let certifiedModules = availableModule.filter((el) => {
        return (
          el.isCertified === "true" ||
          el.isCertified === true ||
          el.isCertified === null
        );
      });

      // Helper Functions
      function populateModules(topTitle, dataArray, config, variant, token, auth) {
        return dataArray.map((el) => {
          let { moduleId } = el;
          let module = moduleDetails[moduleId];
          let selfCertifyFlag = 0;

          if (module) {
            if (module.SelfCertify) {
              selfCertifyFlag = 1;
            }
            if (
              selfCertifyFlag === 0 ||
              (selfCertifyFlag === 1 &&
                (el.isCertified === "true" || el.isCertified === true))
            ) {
              let { title, description } = module;
              if (topTitle === title) {
                let { percentageCompleted } = el;
                return (
                  <ModuleCardConduct
                    key={moduleId}
                    title={title}
                    description={description}
                    percentageCompleted={parseInt(percentageCompleted || 0)}
                    config={config}
                    variant={variant}
                    moduleId={moduleId}
                    user_token={token}
                    coach_id={userid}
                    orgDetails={orgDetails}
                    selfCertifyFlag={selfCertifyFlag}
                    auth={auth}
                  />
                );
              }
            }
          }
          return null;
        });
      }

      let menuItems1 = [];
      let menuItems = [];
      // menuItems1.push({title: 'Contributor Basics Program'})
      function populateHeads(dataArray) {
        return dataArray.map((el) => {
          let { moduleId } = el;

          let module = moduleDetails[moduleId];
          if (module) {
            const found = menuItems1.some((el) => {
              if (module.tag && module.tag[0] && module.tag[0].tagName)
                return el.title === module.tag[0].tagName;
            });
            if (!found) {
              if (module.tag && module.tag[0] && module.tag[0].tagName) {
                menuItems1.push({ title: module.tag[0].tagName });
                menuItems.push({
                  title: module.tag[0].tagName,
                  dataSet: certifiedModules,
                  config: { progressBar: false },
                });
              }
            }
          }

          return null;
        });
      }

      // let menuItems = [
      //   // { title: "Continue Preparation", dataSet: continuePrep, config: { progressBar: true } },
      // // { title: "Ready to Self Certify", dataSet: underCertificationModules, config: { progressBar: false } },
      // // { title: "Available to Prepare", dataSet: availableToPrepareModules, config: { progressBar: false } },
      menuItems.push({
        title: "Self Certified",
        dataSet: certifiedModules,
        config: { progressBar: false },
      });

      return (
        <div>
          <div
            style={{
              display: "flex",
              marginBottom: "1.875rem",
              alignItems: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <div id="filter-container" className="filterStyles">
              <div className="subheading">Find Module</div>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  margin: "0rem 1.25rem",
                }}
                onClick={() => {
                  this.setState({ showSearchBar: !this.state.showSearchBar });
                }}
              >
                <SearchIcon
                  fill={this.state.showSearchBar ? "#e4001b" : "#8c8c8c"}
                />
              </div>
            </div>
          </div>

          <div className="App">
            <header className="App-header">
              <div>
                {this.state.showSearchBar ? (
                  <form className="search-bar" noValidate autoComplete="off">
                    <TextField
                      id="search-field"
                      value={this.state.searchText}
                      placeholder="Enter a module name to search"
                      classes={{ root: "search-text" }}
                      fullWidth={true}
                      onChange={(ev) => {
                        this.setState({ searchText: ev.target.value });
                      }}
                    />
                    <Button
                      onClick={(ev) => {
                        this.setState({ showSearchBar: false });
                        this.setState({ searchText: "" });
                      }}
                    >
                      Close
                    </Button>
                  </form>
                ) : null}
                {/* <button onClick={()=>{this.setState({showSearchBar:!this.state.showSearchBar})}}>{ this.state.showSearchBar? 'Hide' : 'Show'} Div</button> */}
              </div>
            </header>
          </div>

          <div className="dashboard-nav">
            <div className="subheading">Jump To</div>
            {menuItems.map((el, i) => {
              if (el.dataSet.length) {
                return (
                  <div key={i}>
                    {populateHeads(el.dataSet)}
                    {menuItems1.map((ell, ii) => {
                      if (ell) {
                        return <a key={ii}>{ell.title}</a>;
                      }
                    })}{" "}
                  </div>
                );
              }
              return;
            })}
          </div>

          {/* <h3 style={{ margin: "1.25rem 0rem" }}>Continue Conducting ({menuItems.length - 1 })</h3> */}


          {menuItems.map((el, i) => {
            if (el.dataSet.length) {
              if (el.title !== "Self Certified") {
                return (
                  <section key={`menu-${i}`} id={`menu-${i}`}>
                    <h3 id={el.title} style={{ margin: "1.25rem 0rem" }}>
                      {el.title}
                    </h3>
                    <div className="grid-wrapper">
                      {populateModules(
                        el.title,
                        el.dataSet,
                        el.config,
                        3,
                        token,
                        this.props.auth
                      )}
                    </div>
                  </section>
                );
              }
              return null;
            }
          })}

          {/* {this.state.childOpen && (
          <div className="overlay">
            <div className="overlay-opacity" onClick={this.hideChild} />
              <div className="module-card tooltip">
                <div className="-card-body">
      
                <h6 style={{ height: 40 }}>This is the Conduct Zone</h6>

                <div className="subtitle" style={{ marginBottom: 6 }}>In this space, you can conduct your coaching modules with youw
                participants. You can also take any prints of class tools, and coaching guides for your reference.</div>
                
              <div className="cFoot">
              <div className='button-text'>
                <Button onClick="this.setState({
                  childOpen: false
                });">Got It</Button>
            </div>
      </div>
    </div>
  </div>
          </div>
        )} */}
        </div>
      );
    }
  }
}

Conduct.propTypes = {
  getPrepareModulesAttempt: PropTypes.func.isRequired,
  getModuleDetails: PropTypes.func.isRequired,
  getTagsAttempt: PropTypes.func.isRequired,
  authMsg: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPrepareModulesAttempt,
      getContinueConductModules,
      getModuleDetails,
      getTagsAttempt,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Conduct);
