import React from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import {
    //  Typography, 
     Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import './style.css'
import { useDispatch
    // , useSelector 
} from 'react-redux';
import { loginAttempt, getOrganizationAttempt } from '../../auth/redux_actions';
import PegogLogo from './assets/home-left combined.svg'
import IllumineLogo from './assets/illumine-home.svg'
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
    filledRoot: {
        // '& > *': {
        //     margin: theme.spacing(1),
        // },
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        /* background-color: rgba(0, 0, 0, 0.09); */
        borderRadius: '17px',
        padding: '8px',
        /* border-top-right-radius: 4px; */
        border: '2px solid #E0E0E0',
        backgroundColor: 'transparent',
        width: '100%',
        fontFamily: "tablet-gothic, sans-serif",
        // '&$focused': {
        //     backgroundColor: '#fff',
        // }
        '&:hover': {
            backgroundColor: '#f5f5f5 !important'
        }
    },
    filledRootFocused: {
        backgroundColor: '#f5f5f5 !important'
    },
    formControlRoot: {
        display: 'block',
        fontFamily: "tablet-gothic, sans-serif"
    },
    filledEmailLabel: {
        transform: 'translate(20px, 30px) scale(1.15)',
        color: '#9D9D9D',
        fontFamily: "tablet-gothic, sans-serif"
    },
    filledEmailLabelShrink: {
        transform: "translate(15px, 13px) scale(0.85) !important",
        color: '#BA1F00 !important'
    },
    filledPasswordLabel: {
        transform: "translate(20px, 45px) scale(1.15)",
        color: '#9D9D9D',
        fontFamily: "tablet-gothic, sans-serif"
    },
    filledPasswordLabelShrink: {
        transform: "translate(17px, 25px) scale(0.85) !important",
        color: '#BA1F00 !important'
    },
    buttonLabel: {
        fontFamily: 'tablet-gothic !important'
    },
    buttonRoot: {
        '&:hover': {
            backgroundColor: '#e4001b !important',
            color: 'white',
        },
    }
}));


export default ({ app, auth }) => {

    const dispatch = useDispatch();
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
        error: ''
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleValidation = (eml, pwd) => {
        let errors = {};
        let formIsValid = true;

        //Email
        if (!eml) {
            formIsValid = false;
            errors["user-email"] = "Email cannot be empty";
            setValues({ ...values, error: 'Email cannot be empty' });
        }
        //Password
        if (!pwd) {
            formIsValid = false;
            errors["user-password"] = "Password cannot be empty";
            setValues({ ...values, error: "Password cannot be empty" });
        }

        if (typeof eml !== "undefined") {
            let lastAtPos = eml.lastIndexOf("@");
            let lastDotPos = eml.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    eml.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    eml.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["user-email"] = "Email is not valid";
                setValues({ ...values, error: "Email cannot be empty" });
            }
        }
        // this.setState({ errors: errors });
        return formIsValid;
    }
    const handleLogin = () => {
        let chkVal = handleValidation(values.email, values.password);
        let flag = 0;
        console.log(chkVal)
        if (chkVal) {
            dispatch(loginAttempt({
                email: values.email,
                password: values.password,
                flag,
                successCallBack: (usr) => {
                    if (usr.message) {
                        setValues({ ...values, error: usr.message });
                        console.log('yes')
                    }
                    let orgid = usr.user.organizationid;
                    console.log(usr.user)
                    let token = usr.tokens.access.token;
                    dispatch(getOrganizationAttempt({ orgid, token }))
                        .then((result) => {
                            // let conductflag = result.conductflag;
                        });
                },
                failureCallback: () => {
                    //alert('failed');;
                },
            }));
        } else {
            setValues({
                ...values, error:
                    "Either Email or Password is incorrect. Please try again.",
            });
        }
    }



    const classes = useStyles();
    return (
        <div className='d-flex w-100 h-100'>
            <div className='w-35'>
                <img src={PegogLogo} style={{ height: '100vh' }} alt='pegogLogo' />
            </div>
            {/* <div style={{ width: '33%' }}> */}
            <div className='ap-login-container'>
                <div className='ap-login-field-container box-shadow'>
                    <div className='ap-login-welcome-font-color tablet-gothic'>Welcome!</div>
                    {values.error && <div className='text-center tablet-gothic ap-error-msg'>
                        {values.error}
                    </div>}
                    <div style={{ marginTop: '25px' }}>
                        <TextField
                            id="filled-required"
                            label="Email"
                            variant="filled"
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    root: classes.filledRoot,
                                    focused: classes.filledRootFocused
                                }
                            }}
                            onChange={handleChange('email')}
                            style={{ margin: '15px 0' }}
                            classes={{
                                root: classes.formControlRoot
                            }}
                            InputLabelProps={{
                                classes: {
                                    filled: classes.filledEmailLabel,
                                    shrink: classes.filledEmailLabelShrink
                                }
                            }}
                        />
                        <FormControl variant="filled" classes={{
                            root: classes.formControlRoot
                        }}>
                            <InputLabel htmlFor="filled-adornment-password" classes={{
                                filled: classes.filledPasswordLabel,
                                shrink: classes.filledPasswordLabelShrink
                            }}>Password</InputLabel>
                            <FilledInput
                                id="filled-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                disableUnderline
                                classes={{
                                    root: classes.filledRoot,
                                    focused: classes.filledRootFocused
                                }}
                                style={{ margin: '15px 0' }}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='text-right'>
                        <Link to="../auth/forgotpassword" className='forgot-password-ap ap-gray-color-1'> Forgot Password?</Link>
                        {/* <Button className={classes.button}>
                    <Typography
                        className="errorMsg text-right"
                        variant="caption"
                        gutterBottom
                        color="primary"
                        component={toForgotPassword}
                    >
                        Forgot Password?
                    </Typography>
                </Button> */}
                    </div>
                    <div>
                        <Button className='login-button-ap' onClick={handleLogin} classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}>
                            Login
                        </Button>
                    </div>
                    <div className='text-center tablet-gothic'>
                        New? <Link to='/auth/register' className='tablet-gothic' style={{ color: '#BA1F00', textDecoration: 'underline', fontWeight: '600' }} >Register</Link>
                    </div>

                    {/* <div className="dashboard-cntr"> */}
                    {/* </div> */}
                    {/* </div> */}

                </div>
                <div className='ap-gray-color-1 tablet-gothic text-center' style={{ fontSize: '0.8rem', margin: '7px 0px' }}>
                    Copyright 2019-21; Illumine. All rights reserved.
                </div>
            </div>
            <div style={{ width: '30%', position: 'relative' }}>
                <img src={IllumineLogo} style={{ position: 'absolute', bottom: '8%', right: '5%' }} alt='IllumineLogo' />
            </div>
            {
                auth && auth.isLoggedIn &&
                <Redirect
                    to={{
                        pathname: "/application"
                    }}
                />
            }
        </div>
    )
}
