import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button
} from "@material-ui/core";
import TextField from '@material-ui/core/Input';
import { getPrepareModulesAttempt, getModuleDetails, getTagsAttempt, getUserAttempt, getRoadmapDetails, getLearningToolDetails, getOrganisationByParentId, getContinueConductModules } from "../../containers/application/redux_actions";
import { getOrganizationAttempt } from "../../containers/auth/redux_actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import ModuleCardExplore from "./ModuleCard";
import NoModuleAvailable from "../NoModuleAvailable";
// import WelcomePopup from "../welcomePopup";
import TooltipWindow from "./tooltip";
// import IntroScreen from "./introScreen";
import SearchIcon from "./assets/search";
// import VideoPopup from '../Video_Popup';
import "./ExploreForms.css";
import Add_CollegeName_Popup from "../Add_CollegeName_Popup/index";
import Add_CollegeName_Success from "../Add_CollegeName_Popup/success";
import APModuleDashboard from "../../containers/asianPaints/ModuleDashboard";
import Config from "../../config";
// import IntroScreen from "./introScreen";

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chk: 0, showSearchBar: false, searchText: '',
      childOpen: true,
      activeClass: "",
      flag: 0, chkflag: 0,
    };
  }

  async componentDidMount() {

    const { getPrepareModulesAttempt, getModuleDetails, getTagsAttempt, getUserAttempt, 
      // getLearningToolDetails, getRoadmapDetails, 
      getOrganizationAttempt } = this.props;

    let userid = this.props.auth.user_uid;
    let token = this.props.auth.user_token;
    let orgid = this.props.auth.user.organizationid

    if (Object.keys(this.props.auth.orgDetails).length === 0) {
      await getOrganizationAttempt({ orgid, token })
    }

    let parentOrgId = await this.props.auth.orgDetails.id

    if (this.props.auth.orgDetails && parentOrgId && Object.keys(this.props.auth.organisationList).length === 0) {

      await getOrganisationByParentId({ parentOrgId: parentOrgId, user_token: token })
    }

    this.setState({ flag: this.props.auth.prepareFlag });
    if (this.state.flag === "0" || this.state.flag === 0) {
      await getUserAttempt({ userid, token }).then(result => {
        this.setState({ flag: this.props.app.prepareFlag });
      })
    }

    getTagsAttempt({ token })
      .then(res1 => {
        this.setState({ chk: 1 });
        getPrepareModulesAttempt({ userid, token })
          .then(result => {
            //this.setState({ chk: 1 });
            let { availableModule } = this.props.app;
            let availableModules = availableModule;
            availableModules.forEach(module => {
              getModuleDetails({ id: module.moduleId, token })
              // .then(res => {
              //   if (res.payload) {
              //     let roadMaps = (res.payload.data.organiser && res.payload.data.organiser.roadmaps && Object.keys(res.payload.data.organiser.roadmaps).sort((a, b) => {
              //       return res.payload.data.organiser.roadmaps[a].order - res.payload.data.organiser.roadmaps[b].order
              //     })) || {};

              //     Object.keys(roadMaps).forEach(rdId => {
              //       getRoadmapDetails({ id: roadMaps[rdId], token: token })
              //         .then(ress => {
              //           if (ress.payload) {
              //             ress.payload.data.LearningTools && Object.keys(ress.payload.data.LearningTools).forEach(uId => {
              //               getLearningToolDetails({ id: uId, token: token })
              //             });
              //           }
              //         });
              //     });
              //   }
              // });
            })
          })
      })

    // getContinueConductModules({
    //   userid,
    //   token,
    // });
  }

  render() {
    // const { showSearchBar } = this.state;
    let { availableModule } = this.props.app;
    let availableModules = availableModule;
    let { user_uid, user_token } = this.props.auth;
    // let flg = "0";

    if (this.props.app.availableModule) {
      if (this.state.chk === 0) {
        // return <div>Loading...</div>
        return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress classes={{ root: 'circular-progress-color' }} size={70} />
        </div>
      }
      else {
        let { moduleDetails } = this.props.app;

        // let availableModule = this.props.app.availableModule.filter(el => {
        //   // if (moduleDetails[el.moduleId].code === 400)
        //   //   return false
        //   if (moduleDetails[el.moduleId] && moduleDetails[el.moduleId].description) {
        //     return (new RegExp(this.state.searchText, 'i')).test(moduleDetails[el.moduleId].description);
        //   }
        // })
        //   return true;
        let { availableTags } = this.props.app;
        // Helper Functions
        function populateModules(dataArray) {
          return dataArray.map((el, i) => {
            if (el) {
              let { title, description, id, mainFilePath, uniqueAboutIt } = el;
              return <ModuleCardExplore
                key={i}
                title={title}
                description={description}
                mainFilePath={mainFilePath}
                moduleId={id}
                uniqueAboutIt={uniqueAboutIt}
              />
            }
            return null;
          })
        }

        let menuItems1 = [];
        let menuItems = [];

        function populateHeads() {
          // {
            if (availableTags) {
              if (Array.isArray(availableTags)) {
                availableTags.map((el, i) => {
                  if (el) {
                    if (!menuItems1.includes(el.tagName)) {
                      menuItems1.push({ title: el.tagName, id: el.id });
                    }
                  }
                })
              }
            }
          // }

          // {
            menuItems1.map((tag, j) => {
              let ds = []
              availableModules.map((el, i) => {
                if (el) {
                  let md = moduleDetails[el.moduleId]
                  if (md) {
                    if (md.tag) {
                      md.tag.forEach(element => {
                        let tagchk = 0;
                        if (element._id === tag.id) {
                          tagchk = 1;
                        }
                        if (tagchk === 1) {
                          ds.push(md);
                        }
                      });
                    }
                  }
                }
              })
              menuItems.push({ title: tag.title, dataSet: ds })
            }
            )
          // }
          return null;
        }
        populateHeads();
        return (
          <div className='w-100 h-100'>{
            ((Config.AP_SHOW && window.location.host === Config.AP_HOST) || this.props.auth.orgDetails.organizationname === 'Asian Paints') ? <APModuleDashboard app={this.props.app} auth={this.props.auth} menuItems={menuItems} menuItems1={menuItems1} /> :
              <div className="with-left-nav wrapper prepare-dashboard" style={{ width: '100%' }} >
                {/* <WelcomePopup /> */}
                {/* comment below 4 lines are related to showing tooltip, if commented, tooltip will not be shown */}
                {
                  this.state.flag === 0 || this.state.flag === "0" ?
                    <TooltipWindow user_token={user_token} userId={user_uid} />
                    : null
                }


                {((!this.props.auth.orgDetails.parentorganization !== false) || this.props.auth.orgDetails.parentorganization === '0') && this.props.auth.user.organizationid !== '0' && this.props.auth.organisationList && this.props.auth.organisationList.organization && this.props.auth.organisationList.organization.length > 0 && <Add_CollegeName_Popup app={this.props.app} auth={this.props.auth} />}
                {this.props.auth.updatedUser === true && <Add_CollegeName_Success app={this.props.app} auth={this.props.auth} />}
                {/* <IntroScreen user_token={user_token} userId={user_uid} /> */}
                {/* <label>test</label> */}
                <div style={{ display: 'flex', marginBottom: '1.875rem' }}>

                  <div className="-headerTitle" style={{ width: '75%' }}>
                    <h6>Explore Zone</h6>
                    <h1>Explore the modules being offered to you</h1>
                    <p style={{ lineHeight: '25px', fontSize: '14px', marginTop: '0.5rem' }}>Explore and choose the outcomes you want to enable in the lives of the people you are coaching, through each module you can deliver. Do a quick exploration of each module, its value and what happens in it.</p>
                  </div>
                  <div id='filter-container' className="filterStyles" >
                    <div className="subheading">Find Module</div>
                    <div style={{ cursor: 'pointer', display: 'flex', margin: '0rem 1.25rem' }} onClick={() => { this.setState({ showSearchBar: !this.state.showSearchBar }) }}>
                      <SearchIcon fill={this.state.showSearchBar ? '#e4001b' : '#8c8c8c'} />
                    </div>
                  </div>
                </div>

                <div className="App">
                  <header className="App-header">
                    <div>
                      {
                        this.state.showSearchBar ?
                          <form className="search-bar" noValidate autoComplete="off">
                            <TextField id="search-field"
                              value={this.state.searchText}
                              placeholder="Enter a module name to search"
                              classes={{ root: "search-text" }}
                              fullWidth={true}
                              onChange={(ev) => { this.setState({ searchText: ev.target.value }) }}
                            />
                            <Button onClick={(ev) => { this.setState({ showSearchBar: false }); this.setState({ searchText: '' }) }} >Close</Button>
                          </form>
                          : null
                      }
                    </div>
                  </header>
                </div>
                {/* <div className="dashboard-nav"> */}
                {/* {populateHeads()} */}
                {/* </div> */}

                {menuItems.map((el, i) => {
                  if (el.dataSet.length > 0) {
                    return <section key={`menu-${i}`} id={`menu-${i}`}>
                      <h3>{el.title} ({el.dataSet.length})</h3>
                      <p style={{ lineHeight: '25px', fontSize: '14px', marginTop: '0.5rem', marginBottom: '2.25rem' }}>{el.dataSet[0].tag[0].description}</p>
                      <div key={i} className="grid-wrapper">
                        {populateModules(el.dataSet)}
                      </div>
                    </section>
                  }
                  return null
                })}
              </div>
          }</div>
        );
      }

    }
    else {
      return <NoModuleAvailable />
    }
  }
}
// {login_flag ? test : test1}
Explore.propTypes = {
  getPrepareModulesAttempt: PropTypes.func.isRequired,
  getModuleDetails: PropTypes.func.isRequired,
  getTagsAttempt: PropTypes.func.isRequired,
  getUserAttempt: PropTypes.func.isRequired,
  authMsg: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getPrepareModulesAttempt,
    getModuleDetails,
    getTagsAttempt,
    getUserAttempt,
    getRoadmapDetails,
    getLearningToolDetails,
    getOrganizationAttempt,
    getContinueConductModules
  },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(Explore);
