import React, { useState, 
    // useEffect 
} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { updateUserByUserId, createBatch, createOrganization } from "../../containers/application/redux_actions";
import CountryCode from './CountryCode.json'
import succes from "../../containers/auth/assets/Success.svg";
import { Link } from 'react-router-dom'
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

});

const StyledDialog = withStyles({
    // '@global': {
    //   '.MuiPaper-rounded': {
    //     borderRadius: '15px',
    //     padding: '30px'
    //   },
    //   '.MuiOutlinedInput-root': {
    //     borderRadius: '15px',
    //   },
    //   '.MuiDialog-paper': {
    //     width: '600px'
    //   },
    //   '.MuiTypography-body1': {
    //     lineHeight: '2'
    //   }
    // },
    paper: {
        borderRadius: '15px',
        padding: '20px',
        height: '100%'
    }
})(Dialog);



const DialogTitle = withStyles(styles)((props) => {

    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        height: '70%'
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: 'center'
    },
}))(MuiDialogActions);

const customButton_1 = {
    backgroundColor: 'rgb(255, 186, 54)',
    cursor: 'pointer',
    width: '35%'
}

// const customButton_2 = {
//     color: "#BA1F00",
//     borderColor: '#BA1F00'
// }

const customLink = {
    color: 'black',
    textDecoration: 'none',
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2% 0'
    },
    pinCodeMenuPaper: {
        maxHeight: 300
    },
    batchDetailsDropDown: {
        maxHeight: 150,
    }

}));

function AddCollegeNamePopup({ app, auth }) {
    let orgList = auth.organisationList;
    // const [orgList, setOrgList] = useState({})
    // useEffect(() => {
    //     (async () => {
    //         setOrgList(await getOrganisationByParentId({ parentOrgId: auth.orgDetails.id, user_token: auth.user_token }).then(res => res))
    //         console.log(orgList)
    //     })();
    // }, [])



    const [open, setOpen] = useState(true);
    const [_error, _setError] = useState(false);
    const classes = useStyles();
    const [pinCode, setPinCode] = useState('+91')
    const [userFilledData, setUserFilledData] = useState({
        phone: '',
        organization: {
            userFilled: '',
            selected: ''
        },
        numberOfBatches: '',
        batchDetails: [],
    })
    const [update, setUpdate] = useState(false)

    // const handleUserDataChange = (field, newValue) => {
    //     setUserFilledData({
    //         ...userFilledData,
    //         [field]: newValue
    //     })
    // }

    const handleBatchChange = (newValue) => {
        setUserFilledData({
            ...userFilledData,
            numberOfBatches: newValue,
            batchDetails: [...Array(newValue)].map((item, index) => {
                return {
                    course: '',
                    semester: '',
                    batchName: ''
                }
            })
        })
    }

    const handleBatchDetailsChange = (field, batchNum, newValue) => {
        let tempBatchDetails = userFilledData.batchDetails;
        tempBatchDetails[batchNum] = {
            ...tempBatchDetails[batchNum],
            [field]: newValue
        }

        // console.log(tempBatchDetails)
        setUserFilledData({
            ...userFilledData,
            batchDetails: tempBatchDetails
        })
    }

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePhoneNumber = (event) => {
        let regex = /^\d{0,10}$/;
        if (regex.test(event.target.value))
            setUserFilledData({
                ...userFilledData,
                phone: event.target.value
            })
    }

    console.log(orgList)
    const handleCollegeChange = (field, newValue) => {
        setUserFilledData({
            ...userFilledData,
            organization: {
                ...userFilledData.organization,
                [field]: newValue
            }
        })
    }


    const clickEvent = async () => {
        let flag = true;
        if (userFilledData.phone.length < 10 || !userFilledData.numberOfBatches || (!userFilledData.organization.selected && !userFilledData.organization.userFilled) || (userFilledData.organization.selected === null && !userFilledData.organization.userFilled)) {
            _setError(true)
            flag = false
            // console.log('yes1')
        }
        else if (flag && userFilledData.batchDetails.length > 0) {
            let tempFlag = true;
            userFilledData.batchDetails.map((batch) => {
                // console.log(batch)
                if (flag && (!batch.course || !batch.semester || !batch.batchName)) {
                    _setError(true)
                    flag = false
                    tempFlag = false;
                    // console.log('yes2')
                }
            })
            if (tempFlag) {
                flag = true;
                // console.log('no2')
            }
        }
        if (flag) {
            // console.log(flag)
            let createdOrg;
            if (!userFilledData.organization.selected)
                createdOrg = await createOrganization({ user_token: auth.user_token }, {
                    organizationname: userFilledData.organization.userFilled,
                    parentorganization: auth.orgDetails.id
                })
            // console.log(createdOrg)
            let userData = {
                phone: pinCode + userFilledData.phone,
                organizationid: userFilledData.organization.selected ? userFilledData.organization.selected.id : createdOrg.id,
                parentid: auth.orgDetails.id
            }
            // console.log(userData)

            userFilledData.batchDetails.map(async (batch) => {
                await createBatch({ user_token: auth.user_token, userId: auth.user_uid }, batch)
            })

            await updateUserByUserId({ user_token: auth.user_token, userId: auth.user_uid }, userData)
            _setError(false)
            setUpdate(true)
        }
    }
    return (
        <div>
            {/* <div className='button-text' onClick={handleClickOpen}>
        <div className='button-label'>
          {buttonText ? buttonText : "conduct"}
        </div>
      </div> */}
            {/* {console.log(userFilledData)} */}
            <StyledDialog onClose={handleClose} fullWidth={!update} maxWidth={!update ? 'xl' : 'xs'} aria-labelledby="customized-dialog-title" open={open}>
                {update === false ? <div style={{ height: '100%' }}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <Typography style={{ fontSize: '2rem' }}>Welcome to PEDGOG!</Typography>
                        <Typography>Tell us a more bit about you so that we can setup your account</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ width: '30%' }}>
                            <Typography>Mobile Number : </Typography>
                            <FormControl className={classes.formControl} variant="outlined">
                                <Select
                                    id="phone_pin"
                                    value={pinCode || ''}
                                    onChange={(event) => setPinCode(event.target.value)}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        classes: {
                                            paper: classes.pinCodeMenuPaper
                                        }
                                    }}
                                >
                                    {
                                        CountryCode.map((item) => <MenuItem key={item.code} value={item.dial_code}>{item.code} : {item.dial_code}</MenuItem>)
                                    }
                                </Select>
                                <TextField fullWidth style={{ marginLeft: '3%' }} label="Enter Mobile Number" variant="outlined" value={userFilledData.phone} onChange={handlePhoneNumber} />
                            </FormControl>
                            <Typography>College : </Typography>
                            <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                {/* <Select
                                    id="college"
                                    value={college || ''}
                                    onChange={(event) => setCollege(event.target.value)}
                                    fullWidth
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        },
                                        transformOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        classes: {
                                            paper: classes.pinCodeMenuPaper
                                        }
                                    }}
                                >   <MenuItem value="" disabled>
                                        <em>Select your College</em>
                                    </MenuItem>
                                    {
                                        orgList.length > 0 && orgList.map(item => <MenuItem key={item.id} value={item.organizationname}>{item.organizationname}</MenuItem>)
                                    }

                                </Select> */}
                                {/* {console.log(userFilledData)} */}
                                {Object.keys(orgList).length !== 0 &&
                                    <Autocomplete
                                        value={userFilledData.organization.selected}
                                        onChange={(event, newValue) => {
                                            handleCollegeChange('selected', newValue)
                                        }}
                                        openOnFocus
                                        freeSolo
                                        autoHighlight
                                        defaultValue={""}
                                        inputValue={userFilledData.organization.userFilled}
                                        onInputChange={(event, newInputValue) => {
                                            handleCollegeChange('userFilled', newInputValue)
                                        }}
                                        id="college-list"
                                        options={orgList.organization}
                                        getOptionLabel={(option) => {
                                            if (option.organizationname)
                                                return option.organizationname
                                            return ''
                                        }}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} label="Select/Type your college name"
                                            variant="outlined" />}
                                    />}

                                {/* <Autocomplete
                                    value={userFilledData.organizationName}
                                    onChange={(event, newValue) => {
                                        handleUserDataChange('organizationName', newValue)
                                    }}
                                    inputValue={userFilledData.organizationName}
                                    onInputChange={(event, newInputValue) => {
                                        handleUserDataChange('organizationName', newInputValue)
                                    }}
                                    freeSolo
                                    id="controllable-states-demo"
                                    options={dumlist}
                                    getOptionLabel={(option) => option}
                                    style={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Controllable" variant="outlined" />}
                                /> */}
                            </FormControl>

                            <Typography>Number of batches you are coaching :</Typography>
                            <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                <Select
                                    id="noOfBatch"
                                    value={userFilledData.numberOfBatches}
                                    onChange={(event) => handleBatchChange(event.target.value)}
                                    fullWidth
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        },
                                        transformOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        classes: {
                                            paper: classes.batchDetailsDropDown
                                        }
                                    }}
                                >   <MenuItem value="" disabled>
                                        Select number of batches
                                    </MenuItem>
                                    {
                                        [...Array(15)].map((item, index) => <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            {_error && <Typography color='error' >*Please Check all the Fields</Typography>}
                        </div>
                        {userFilledData.batchDetails.length > 0 && <div className='tct-scrollable-darkgray box-shadow' style={{ width: '65%', marginLeft: '5%', maxHeight: '100%', borderRadius: '10px', padding: '1%', overflowY: 'auto' }}>
                            <Typography>Enter your batch details</Typography>
                            {
                                userFilledData.batchDetails.map((item, batchNum) => <div key={'batchDetails-' + batchNum} className='d-flex align-items-center justify-content-space-between' style={{ margin: '3% 0' }}>
                                    <div style={{ width: '5%', fontSize: '22px' }}>{batchNum + 1}</div>
                                    <div style={{ width: '30%' }}>
                                        <Typography variant="caption">Course : </Typography>
                                        {/* <Autocomplete
                                            value={userFilledData.batchDetails[batchNum].course.selected}
                                            onChange={(event, newValue) => {
                                                handleBatchDetailsChange('selected', 'course', batchNum, newValue)
                                            }}
                                            freeSolo
                                            defaultValue={""}
                                            inputValue={userFilledData.batchDetails[batchNum].course.userFilled}
                                            onInputChange={(event, newInputValue) => {
                                                handleBatchDetailsChange('userFilled', 'course', batchNum, newInputValue)
                                            }}
                                            id="course-list"
                                            size='small'
                                            options={orgList}
                                            getOptionLabel={(option) => option.organizationname}
                                            fullWidth
                                            renderInput={(params) => <TextField size='small' {...params} label="Select/Type your course"
                                                variant="outlined" />}
                                        /> */}
                                        <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                            <Select
                                                value={userFilledData.batchDetails[batchNum].course}
                                                onChange={(event) => handleBatchDetailsChange('course', batchNum, event.target.value)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                MenuProps={{
                                                    classes: {
                                                        paper: classes.batchDetailsDropDown
                                                    }
                                                }}
                                                style={{ width: '100%' }}
                                            >   <MenuItem value="" disabled>
                                                    Select Course
                                                </MenuItem>
                                                {
                                                    orgList.course.map((item, index) => <MenuItem key={index + 1} value={item}>{item}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <Typography variant="caption">Semester : </Typography>
                                        {/* <Autocomplete
                                            value={userFilledData.organization.selected}
                                            onChange={(event, newValue) => {
                                                handleCollegeChange('selected', newValue)
                                            }}
                                            freeSolo
                                            defaultValue={""}
                                            inputValue={userFilledData.organization.userFilled}
                                            onInputChange={(event, newInputValue) => {
                                                handleCollegeChange('userFilled', newInputValue)
                                            }}
                                            id="semester-list"
                                            size="small"
                                            options={orgList}
                                            getOptionLabel={(option) => option.organizationname}
                                            fullWidth
                                            renderInput={(params) => <TextField size='small' {...params} label="Select/Type your semester" variant="outlined" />}
                                        /> */}
                                        <FormControl className={classes.formControl} fullWidth variant='outlined'>
                                            <Select
                                                value={userFilledData.batchDetails[batchNum].semester}
                                                onChange={(event) => handleBatchDetailsChange('semester', batchNum, event.target.value)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                MenuProps={{
                                                    classes: {
                                                        paper: classes.batchDetailsDropDown
                                                    }
                                                }}
                                                style={{ width: '100%' }}
                                            >   <MenuItem value="" disabled>
                                                    Select Semester
                                                </MenuItem>
                                                {
                                                    [...Array(8)].map((item, index) => <MenuItem key={index + 1} value={'Semester ' + (index + 1).toString()}>{'Semester ' + (index + 1).toString()}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ width: '30%' }}>
                                        <TextField
                                            label="Batch name"
                                            placeholder={"e.g. Mech Batch " + (batchNum + 1)}
                                            value={userFilledData.batchDetails[batchNum].batchName}
                                            onChange={(event) => handleBatchDetailsChange('batchName', batchNum, event.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>)
                            }
                        </div>}
                    </DialogContent>
                    <DialogActions >
                        <Button style={customButton_1} onClick={clickEvent}>
                            Update
                    </Button>
                    </DialogActions>
                </div>
                    : <div>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <Typography style={{ fontSize: '1.5rem' }}>Thanks for sharing the information</Typography>
                        </DialogTitle>
                        <DialogContent style={{ justifyContent: 'center', textAlign: 'center' }}>
                            <div className={"loginform-cntr"}>
                                <div className="errorMsg text-left flexBox flex-center">
                                    <img src={succes} alt="Illumine" /> <label className="text-success ml-3">Success</label>
                                </div>
                                <Typography gutterBottom>Your is profile updated successfully</Typography>
                                <Typography gutterBottom><Link style={customLink} to={`/application/conduct`} >Start Conducting</Link></Typography>
                                {/* <Button style={customButton_2} onClick={handleClose} variant="outlined" >
                                    ok
                                </Button> */}
                            </div>

                        </DialogContent>
                    </div>
                }
            </StyledDialog>
        </div>
    );
}


export default AddCollegeNamePopup;