import React, {
  //  useState, useEffect
   } from 'react';
import './style.css';
let ImageSlide = ({next, previous, imgUrl, classes, dimensions})=>{
  // let providedRatio = dimensions.slideWidth/dimensions.slideHeight;
   return <div className={`img-slide${classes&&classes.slide?` ${classes.slide}`:""}`}>
    <div style={{display: 'flex', maxHeight:'100%'}}>
      <img 
        // onLoad={(i)=>{console.log(i)}}
        src={ imgUrl }
        style={{
          height: dimensions.slideHeight, 
          width: dimensions.slideWidth
        }}
      />
    </div>
  </div>
}

export default ImageSlide;