import React from 'react';
import TakeAway_Button from '../../../Tools/TakeAway_Button';


export default ({ width, height, engagementData, manageToolData, isFullScreen, data, currentUnit }) => {
    const right = ['50.5%', '30%', '7.5%', '52%', '32.5%', '12.5%'];
    const top = ['24.5%', '14%', '17%', '58%', '71%', '70%'];

    return (
        <div style={{ width, height }}>
            <div style={{ backgroundImage: `url(${data.bgImg})`, width: '100%', height: '100%', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }}>
                {
                    data.textBoxId.map((textBoxId, i) => <textarea key={textBoxId} id={textBoxId} placeholder='Fill Here…'
                        className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll UPTC-Case-Study-Circle-textbox'}
                        value={engagementData[data.toolName] ? engagementData[data.toolName][textBoxId] ? engagementData[data.toolName][textBoxId] : '' : ''}
                        style={{ fontSize: isFullScreen ? '14px' : '12px', right: right[i], top: top[i] }}
                        onChange={(event) => manageToolData({
                            toolId: data.toolName, content: {
                                ...engagementData[data.toolName],
                                [textBoxId]: event.target.value
                            }
                        })}
                    />)
                }
                <textarea id={data.centerTextBoxId}
                    className={'transparent-textarea tct-scrollable-darkgray roboto flex-vertical-center textArea-OnHover-To-Scroll UPTC-Case-Study-Circle-textbox'}
                    value={engagementData[data.centerTextBoxId] || ''}
                    style={{ fontSize: isFullScreen ? '14px' : '12px', right: '26.5%', top: '51%', width: '12%', height: '10%' }}
                    onChange={(event) => manageToolData({ toolId: data.centerTextBoxId, content: event.target.value })}
                />
                {/* <div style={{
                    position: "absolute", width: '59%',
                    height: '15%', top: '83%'
                }}>
                    <TakeAway_Button currentUnit={currentUnit} data={data} engagementData={engagementData} />
                </div> */}
            </div>
        </div >
    );
}