import React from 'react';
import ThinkSolution_Eng_1F from "./ThinkSolutions/Eng-1F";
import ThinkSolution_Case_Study from "./ThinkSolutions/Case-Study";

import ValueThinking_Price_And_Value from "./ValueThinking/PriceAndValue";
import ValueThinking_Design_Challenge from "./ValueThinking/Design_Chanllenge";
import ValueThinking_User_Group_Value from "./ValueThinking/UserGroup_Value";
import ValueThinking_Selected_User_Group_Value from "./ValueThinking/Selected_UserGroup_Value";
import ValueThinking_Positive_Negative from "./ValueThinking/Positive_Negative";

import ValueThinking_CS_Canteen_Step_1 from "./ValueThinking/CS_Canteen_Step_1";
import ValueThinking_CS_Canteen_Step_2 from "./ValueThinking/CS_Canteen_Step_2";

import Creator_of_Destiny_Case_Study from "./CreatorOfDestiny/Case_Study";

import WIAC_difference_in_the_lives_of_people from "./WhoIsAContributor/Difference_in_the_lives_of_people";
import WIAC_any_role from "./WhoIsAContributor/Any_role";
import WIAC_characteristics_of_a_person from "./WhoIsAContributor/Characteristics_of_a_Person";

import Total_Compensation from "./Total_Compensation";

import ESI_Slide_9 from "./ESI/Slide_9";
import ESI_Slide_11 from "./ESI/Slide_11";
import ESI_Slide_21 from "./ESI/Slide_21";
import ESI_Slide_22 from "./ESI/Slide_22";
import ESI_Slide_26 from "./ESI/Slide_26";
import ESI_Slide_31 from "./ESI/Slide_31";
import ESI_Slide_32 from "./ESI/Slide_32";
import ESI_Slide_33 from "./ESI/Slide_33";
import ESI_Slide_45 from "./ESI/Slide_45";
import ESI_Slide_47 from "./ESI/Slide_47";
import ESI_Slide_52 from "./ESI/Slide_52";
import ESI_Slide_53 from "./ESI/Slide_53";


import ISE_Growing_Multiple_Directions from "./ISE/Growing_Multiple_Directions";
import ISE_Growing_Deep from "./ISE/Growing_Deep";
import ISE_Video_Clickins from "./ISE/Video_Clickins";


import COD_AB_Actions from "./CreatorOfDestiny/AB_Actions";
import COD_AB_Actions_Display from "./CreatorOfDestiny/AB_Actions_Display";
import COD_Creator_Thinking from "./CreatorOfDestiny/Creator_Thinking";
import COD_Five_Career_Scenario from "./CreatorOfDestiny/Five_Career_Scenario";
import COD_Slider from "./CreatorOfDestiny/Slider";


import SS_Case_Study_1 from "./Sustainable_Success/Case_Study_1";
import SS_Case_Study_2 from "./Sustainable_Success/Case_Study_2";
import SS_Case_Study_1_PopUp from "./Sustainable_Success/Case_Study_1_PopUp";
import SS_Case_Study_PopUp from "./Sustainable_Success/Case_Study_PopUp";
import SS_Drag_N_Drop from "./Sustainable_Success/DragNDrop";
import SS_P0_02_Case_A from "./Sustainable_Success/P0_02_Case_A";

import TrustSurplusTool from "./TrustSurplusTool";
import CollaborationTool from "./CollaborationTool";
import AspirationTool from "./AspirationTool";

import CD_Contribution_N_Possibilities from "./CareerDevelopment/Contribution_N_Possibilities";
import CD_Pathway from "./CareerDevelopment/Pathway";
import CD_Six_Profession from "./CareerDevelopment/Six_Profession";

import UPTC_Case_Study_Circle from "./UnleashingPowerToContribute/CaseStudy-Circle";
import UPTC_Case_Study_Square from "./UnleashingPowerToContribute/CaseStudy-Square";
import UPTC_Zone from "./UnleashingPowerToContribute/Zone";
import UPTC_Clickins from "./UnleashingPowerToContribute/Clickins";

import TOF_Single_Fruit from "./TreeOfFulFillment/Single_Fruit";
import TOF_All_Fruit from "./TreeOfFulFillment/All_Fruit";
import TOF_All_Fruit_Modal from "./TreeOfFulFillment/Fruit_Modal";

import ImgSym_Approach_1 from "./ImaginativeSympathy/Approach_1";
import ImgSym_Approach_2 from "./ImaginativeSympathy/Approach_2";
import ImgSym_Visualize_Situation_Ex_1 from "./ImaginativeSympathy/Visualize_Situation_Ex_1";
import ImgSym_Visualize_Situation_Ex_2 from "./ImaginativeSympathy/Visualize_Situation_Ex_2";
import ImgSym_Visualize_Situation_Ex_2_Display from "./ImaginativeSympathy/Visualize_Situation_Ex_2_Display";

import ThinkSolution_Step_1 from "./ThinkSolutions/Step_1";
import ThinkSolution_Step_2 from "./ThinkSolutions/Step_2";
import ThinkSolution_Step_3 from "./ThinkSolutions/Step_3";

import EngagingDeeply_Eng_with_Subject from "./EngagingDeeply/Eng_with_Subject";
import EngagingDeeply_Think_Yourself from "./EngagingDeeply/Think_Yourself";
import EngagingDeeply_Improve_Quality from "./EngagingDeeply/Improve_Quality";

import GrowthIdentity_Level from "./GrowthIdentity/GrowthIdentity_Level";
import GrowthIdentity_Pathway from "./GrowthIdentity/GrowthIdentity_Pathway";

import ESI_Multi_Stakeholder_Map from './ESI/Multi_Stakeholder_Map'

import TrustConduct_Build_Trust_Academic from './TrustConduct/Build_Trust_Academic'
import TrustConduct_Build_Trust_Workplace from './TrustConduct/Build_Trust_Workplace'

import ImgSym_Maze_Of_Consequences from './ImaginativeSympathy/Maze_Of_Consequences'

import Context_Drawer from "./Context_Drawer";

import ImgSym_Ladder_Of_Active_Sympathy from "./ImaginativeSympathy/Ladder";

import SliderD3 from "./SliderD3/index";

import COD_4_WOP_Step_1 from "./CreatorOfDestiny/4_WOP_Step_1";
import COD_4_WOP_Step_2 from "./CreatorOfDestiny/4_WOP_Step_2";
import COD_4_WOP_Step_3 from "./CreatorOfDestiny/4_WOP_Step_3";
import COD_Coping_and_Creator_Response from "./CreatorOfDestiny/Coping_&_Creator_Response";

import Trust_Conduct_In_Action from "./TrustConduct/Trust_Conduct_In_Action";

import Sustainable_Success_Define_SS from "./Sustainable_Success/Define_SS";
import Sustainable_Success_Win_Vs_Lose from "./Sustainable_Success/Win_Vs_Lose";
import Sustainable_Success_Takeaway from "./Sustainable_Success/TakeAway";

import Value_Thinking_CheckList from "./ValueThinking/Value_CheckList";
import Value_Thinking_Multi_Stakeholder_Value_Map_Step_1 from "./ValueThinking/Multi_Stakeholder_Value_Map_Step_1";
import Value_Thinking_Multi_Stakeholder_Value_Map_Step_2 from "./ValueThinking/Multi_Stakeholder_Value_Map_Step_2";
import Value_Thinking_Ladder_of_Contribution from "./ValueThinking/Ladder_of_Contribution";

import LCI_Practice_1 from "./LeveragingCollectiveIntelligence/Practice_1";
import LCI_Collective_Intelligence from "./LeveragingCollectiveIntelligence/Collective_Intelligence";

import Star_Maker_Associate_Making_Positive_Difference from "./Star_Maker_Associate/Making_Positive_Difference";
import Importance_of_Star_Maker_Associate from "./Star_Maker_Associate/Importance";
import Habit_of_Star_Maker_Associate from "./Star_Maker_Associate/Habit";

import TOF_V2 from "./TreeOfFullfillmentV2/index";
import TOF_V2_Display from "./TreeOfFullfillmentV2/display";
import TOF_V2_Fillin from "./TreeOfFullfillmentV2/fillin";

import SMA_Design_Exerices_Situation from './Star_Maker_Associate/Design_Exercise_Situation'
import SMA_Design_Exerices_Situation_V2 from './Star_Maker_Associate/Design_Exercise_Situation_V2'
import SMA_Star_Drop from './Star_Maker_Associate/Star_Drop'

import SMA_Thinking_Strategy from './Star_Maker_Associate/Thinking_Strategy'
import SMA_Thinking_Strategy_Fillin from './Star_Maker_Associate/Thinking_Strategy_Fillin'
import SMA_Way_of_Thinking from './Star_Maker_Associate/Way_of_Thinking'
import SMA_Identifying_Activities from './Star_Maker_Associate/Identifying_Activities/index'
import SMA_Identifying_Activities_Display from './Star_Maker_Associate/Identifying_Activities/display'
import SMA_Best_Strategy from './Star_Maker_Associate/Best_Strategy/newIndex'
import SMA_Best_Strategy_Review from './Star_Maker_Associate/Best_Strategy/review'
import SMA_Identified_Practices from './Star_Maker_Associate/Identified_Practices';
import SMA_Must_Do_Practices from './Star_Maker_Associate/Best_Strategy/mustDo'
import SMA_Handling_FP_Consequences from "./Star_Maker_Associate/Handling_FlashPoints/Consequences";
import SMA_Handling_FP_Design_Response from "./Star_Maker_Associate/Handling_FlashPoints/DesignResponse/index.js";
import SMA_Handling_FP_Didnt_Solve from "./Star_Maker_Associate/Handling_FlashPoints/Didn'tSolve";
import SMA_Handling_FP_Alert_to_Solve from "./Star_Maker_Associate/Handling_FlashPoints/AlertToSolve";
import SMA_Handling_FP_Applying_Design_Response from "./Star_Maker_Associate/Handling_FlashPoints/ApplyingDesignResponse";
import SMA_Handling_FP_Win_Win_Solution from "./Star_Maker_Associate/Handling_FlashPoints/WinWinSolution";
import SMA_Handling_FP_APRI from "./Star_Maker_Associate/Handling_FlashPoints/APRI";

export const Tools = {
    ThinkSolution_Eng_1F,
    ThinkSolution_Case_Study,
    ValueThinking_Price_And_Value,
    ValueThinking_Design_Challenge,
    ValueThinking_User_Group_Value,
    ValueThinking_Selected_User_Group_Value,
    ValueThinking_Positive_Negative,
    Creator_of_Destiny_Case_Study,
    WIAC_difference_in_the_lives_of_people,
    WIAC_any_role,
    WIAC_characteristics_of_a_person,
    Total_Compensation,
    ESI_Slide_11,
    ESI_Slide_31,
    ESI_Slide_32,
    ESI_Slide_33,
    ESI_Slide_9,
    ESI_Slide_21,
    ESI_Slide_22,
    ESI_Slide_26,
    ESI_Slide_52,
    ESI_Slide_53,
    ESI_Slide_45,
    ESI_Slide_47,
    ISE_Growing_Multiple_Directions,
    ISE_Growing_Deep,
    ISE_Video_Clickins,
    COD_AB_Actions,
    COD_AB_Actions_Display,
    COD_Creator_Thinking,
    COD_Five_Career_Scenario,
    COD_Slider,
    SS_Case_Study_1,
    SS_Case_Study_2,
    SS_Case_Study_1_PopUp,
    SS_Case_Study_PopUp,
    SS_Drag_N_Drop,
    SS_P0_02_Case_A,
    TrustSurplusTool,
    CollaborationTool,
    AspirationTool,
    CD_Contribution_N_Possibilities,
    CD_Pathway,
    CD_Six_Profession,
    UPTC_Case_Study_Circle,
    UPTC_Case_Study_Square,
    UPTC_Zone,
    TOF_Single_Fruit,
    TOF_All_Fruit,
    UPTC_Clickins,
    ImgSym_Approach_1,
    ImgSym_Approach_2,
    TOF_All_Fruit_Modal,
    ImgSym_Visualize_Situation_Ex_1,
    ImgSym_Visualize_Situation_Ex_2,
    ImgSym_Visualize_Situation_Ex_2_Display,
    ThinkSolution_Step_1,
    ThinkSolution_Step_2,
    ThinkSolution_Step_3,
    ValueThinking_CS_Canteen_Step_1,
    ValueThinking_CS_Canteen_Step_2,
    EngagingDeeply_Eng_with_Subject,
    EngagingDeeply_Think_Yourself,
    Context_Drawer,
    GrowthIdentity_Level,
    GrowthIdentity_Pathway,
    EngagingDeeply_Improve_Quality,
    ESI_Multi_Stakeholder_Map,
    TrustConduct_Build_Trust_Academic,
    TrustConduct_Build_Trust_Workplace,
    ImgSym_Maze_Of_Consequences,
    ImgSym_Ladder_Of_Active_Sympathy,
    SliderD3,
    COD_4_WOP_Step_1,
    COD_4_WOP_Step_2,
    COD_4_WOP_Step_3,
    COD_Coping_and_Creator_Response,
    Trust_Conduct_In_Action,
    Sustainable_Success_Define_SS,
    Sustainable_Success_Win_Vs_Lose,
    Value_Thinking_CheckList,
    Value_Thinking_Multi_Stakeholder_Value_Map_Step_1,
    Value_Thinking_Multi_Stakeholder_Value_Map_Step_2,
    Value_Thinking_Ladder_of_Contribution,
    LCI_Practice_1,
    LCI_Collective_Intelligence,
    Sustainable_Success_Takeaway,
    Star_Maker_Associate_Making_Positive_Difference,
    Importance_of_Star_Maker_Associate,
    Habit_of_Star_Maker_Associate,
    TOF_V2,
    TOF_V2_Display,
    TOF_V2_Fillin,
    SMA_Design_Exerices_Situation,
    SMA_Design_Exerices_Situation_V2,
    SMA_Star_Drop,
    SMA_Thinking_Strategy,
    SMA_Thinking_Strategy_Fillin,
    SMA_Way_of_Thinking,
    SMA_Identifying_Activities,
    SMA_Identifying_Activities_Display,
    SMA_Best_Strategy,
    SMA_Best_Strategy_Review,
    SMA_Identified_Practices,
    SMA_Must_Do_Practices,
    SMA_Handling_FP_Consequences,
    SMA_Handling_FP_Design_Response,
    SMA_Handling_FP_Didnt_Solve,
    SMA_Handling_FP_Alert_to_Solve,
    SMA_Handling_FP_Applying_Design_Response,
    SMA_Handling_FP_Win_Win_Solution,
    SMA_Handling_FP_APRI
}


export default ({ width, height, engagementData, currentUnit, manageToolData, isFullScreen, data, app, auth }) => {
    const Tool = Tools[data.toolName];
    return (
        <Tool width={width} height={height}
            engagementData={engagementData}
            manageToolData={manageToolData}
            data={data}
            currentUnit={currentUnit}
            isFullScreen={isFullScreen}
            app={app}
            auth={auth}
        />
    )
}
